var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { DatePicker } from "antd";
import moment from "moment";
export var MyDatePicker = function (_a) {
    var value = _a.value, onChange = _a.onChange, props = __rest(_a, ["value", "onChange"]);
    var initValue = function (value) {
        if (value && value !== "") {
            return moment(value);
        }
        return undefined;
    };
    var initProps = function () {
        var result = __assign(__assign({}, props), { getPopupContainer: function (triggerNode) { return triggerNode.parentNode; } });
        if (value)
            result.value = initValue(value);
        if (onChange)
            result.onChange = function (dates, dateString) { return onChange(dateString); };
        return result;
    };
    return (<DatePicker {...initProps()}/>);
};

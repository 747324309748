import { allPost, post } from "@helpers/axiosHelper";
export var getAccountInfo = function () {
    return post("/groupme/common/account/getAccountInfo", null);
};
export var getHeadBarApi = function (url) {
    return post("/groupme/common/menu/getHeaderBar", { url: url });
};
export var getSideBarApi = function (url) {
    return post("/groupme/common/menu/getSideBar", { url: url });
};
export var getMenuList = function (url) {
    return allPost([getHeadBarApi(url), getSideBarApi(url)]);
};
export var getPopMenuList = function () {
    return post("/groupme/common/menu/getPopMenu", null);
};
export var getReleaseData = function () {
    return post("/groupme/administration/release/getReleaseData", null);
};
export var setReleaseRead = function (data) {
    return post("/groupme/administration/release/setReleaseRead", data);
};
export var setAllReleaseRead = function () {
    return post("/groupme/administration/release/setAllReleaseRead", null);
};
export var logoutApi = function () {
    return post("/groupme/common/account/logout", null);
};
export var getHasTips = function (url) {
    return post("/groupme/common/system/getHasTips", { url: url });
};
export var setHasTips = function (data) {
    return post("/groupme/common/system/setHasTips", data);
};
export var getOpcoMenu = function (data) {
    return post("/groupme/common/menu/getSpecialOpcoMenu", data);
};
export var getLinkGid = function (accountId) {
    return post("/groupme/common/wechat/getLinkGid", { accountId: accountId });
};
export var getDemoMenu = function () {
    return post("/groupme/caas/getMenu");
};
export var getChatList = function () {
    return post("/groupme/sage/getChatList");
};
export var addChat = function (data) {
    return post("/groupme/sage/saveChat", data);
};
export var getKnowledgeOptions = function () {
    return post("/groupme/sage/getKnowledgeOptions");
};

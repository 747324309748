import { post } from "@helpers/axiosHelper";
export var getHasPagePermission = function (url) {
    return post("/groupme/common/account/getHasPagePermission", { url: url });
};
export var getPageDomPermission = function (url) {
    return post("/groupme/common/account/getDOMPermission", { url: url });
};
export var saveHistoryUrl = function (url) {
    return post("/groupme/common/workplace/setHistoryUrl", { url: url });
};
export var getUpdating = function (pathname) {
    return post("/groupme/common/system/getUpdatingStatus", { pathname: pathname });
};

// export const particlesJson = {
//   "particles": {
//     "number": {
//       "value": 40,
//       "density": {
//         "enable": true,
//         "value_area": 800
//       }
//     },
//     "color": {
//       "value": "#fefefe"
//     },
//     "shape": {
//       "type": "circle",
//       "stroke": {
//         "width": 0,
//         "color": "#000000"
//       },
//       "polygon": {
//         "nb_sides": 5
//       },
//       "image": {
//         "src": "img/github.svg",
//         "width": 100,
//         "height": 100
//       }
//     },
//     "opacity": {
//       "value": 0.5,
//       "random": false,
//       "anim": {
//         "enable": false,
//         "speed": 1,
//         "opacity_min": 0.1,
//         "sync": false
//       }
//     },
//     "size": {
//       "value": 6,
//       "random": true,
//       "anim": {
//         "enable": false,
//         "speed": 40,
//         "size_min": 0.1,
//         "sync": false
//       }
//     },
//     "line_linked": {
//       "enable": true,
//       "distance": 300,
//       "color": "#fefefe",
//       "opacity": 0.4,
//       "width": 2
//     },
//     "move": {
//       "enable": true,
//       "speed": 12,
//       "direction": "none",
//       "random": false,
//       "straight": false,
//       "out_mode": "out",
//       "bounce": false,
//       "attract": {
//         "enable": false,
//         "rotateX": 600,
//         "rotateY": 1200
//       }
//     }
//   },
//   "interactivity": {
//     "detect_on": "canvas",
//     "events": {
//       "onhover": {
//         "enable": false,
//         "mode": "repulse"
//       },
//       "onclick": {
//         "enable": true,
//         "mode": "push"
//       },
//       "resize": true
//     },
//     "modes": {
//       "grab": {
//         "distance": 800,
//         "line_linked": {
//           "opacity": 1
//         }
//       },
//       "bubble": {
//         "distance": 800,
//         "size": 80,
//         "duration": 2,
//         "opacity": 0.8,
//         "speed": 3
//       },
//       "repulse": {
//         "distance": 400,
//         "duration": 0.4
//       },
//       "push": {
//         "particles_nb": 4
//       },
//       "remove": {
//         "particles_nb": 2
//       }
//     }
//   },
//   "retina_detect": true
// }
export var WS_MAP = {
    "production": "sageapi",
    "dev": "sageapi-uat",
};
export var ChatType_Enum;
(function (ChatType_Enum) {
    ChatType_Enum["CHAT"] = "chat";
    ChatType_Enum["KNOWLEDGE"] = "knowledge";
    ChatType_Enum["SEARCH"] = "search";
})(ChatType_Enum || (ChatType_Enum = {}));
export var WS_TYPE_ENUM;
(function (WS_TYPE_ENUM) {
    WS_TYPE_ENUM["CONNECT"] = "connect";
    WS_TYPE_ENUM["CHAT"] = "chat";
    WS_TYPE_ENUM["CREATE_KNOWLEDGE"] = "knowledge";
})(WS_TYPE_ENUM || (WS_TYPE_ENUM = {}));

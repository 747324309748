var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { wechatCodeUrl } from "@pages/account/helpers/initData";
import styled from "styled-components";
export var WechatQrcodeEnter = function (_a) {
    var visible = _a.visible, setVisible = _a.setVisible, _b = _a.opco, opco = _b === void 0 ? "groupm" : _b, directPath = _a.directPath;
    return (<QrWrapper visible={visible}>
    <CloseBtn onClick={function () { return setVisible(false); }}>x</CloseBtn>
    <iframe width={"100%"} height={"100%"} frameBorder={0} src={wechatCodeUrl(opco, directPath)}/>
  </QrWrapper>);
};
var QrWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  display: ", ";\n  overflow: hidden;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background: #fff;\n  box-shadow: 0 0 6px #aaa;\n  border-radius: 8px;\n"], ["\n  position: fixed;\n  display: ", ";\n  overflow: hidden;\n  top: 40%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  height: 400px;\n  background: #fff;\n  box-shadow: 0 0 6px #aaa;\n  border-radius: 8px;\n"])), function (props) { return props.visible ? "block" : "none"; });
var CloseBtn = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: 12px;\n  top: 8px;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  right: 12px;\n  top: 8px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { ceil, map } from "lodash";
import { ChartItemWrapper } from "@pages/profiling/components/chartsRender/chartItemWrapper";
import { MAIN_COLOR } from "@/common/styled";
import { CHART_TYPE } from "@pages/profiling/components/chartsRender/IChartRender";
import numeral from "numeral";
import { AxisChart } from "@components/charts/axisChart/axisChart";
import { TgiPointChart } from "@pages/profiling/components/charts/tgiPointChart";
import { SPECIAL_TYPE_ENUM, TgiBarLineChart } from "@pages/profiling/components/charts/tgiBarLineChart";
import { PanelChart } from "@components/charts/panelChart/panelChart";
import { TgiPieChart } from "@pages/profiling/components/charts/tgiPieChart";
import { TgiRadarChart } from "@pages/profiling/components/charts/tgiRadarChart";
import { TgiAreaChart } from "@pages/profiling/components/charts/tgiAreaChart";
import { MigrationBarChart } from "@pages/profiling/components/charts/migrationBarChart";
import { Empty } from "antd";
import RouteChinaMap from "@components/map/routeChinaMap";
import DiffTgiMap from "@components/map/diffTgiMap";
import { l } from "@helpers/multiLangaugeHelper";
import { MyTable } from "@components/myTable/myTable";
import { TgiCloudChart } from "@pages/profiling/components/charts/tgiCloudChart";
import { RingChart } from "@pages/profiling/components/charts/ringChart";
import { NightingaleChart } from "@pages/profiling/components/charts/nightingaleChart";
var HEIGHT = 400;
export var ChartsRender = function (_a) {
    var data = _a.data;
    var renderChart = function (item) {
        var DEFAULT_HEIGHT = item.row && item.row > 0 ? HEIGHT * item.row : HEIGHT;
        switch (item.type) {
            case CHART_TYPE.PIE:
                return (<TgiPieChart key={item.id + item.type} height={DEFAULT_HEIGHT} data={item.data}/>);
            case CHART_TYPE.NIGHTINGALE:
                return (<NightingaleChart key={item.id + item.type} height={DEFAULT_HEIGHT} data={item.data}/>);
            case CHART_TYPE.RING:
                return (<RingChart key={item.id + item.type} height={DEFAULT_HEIGHT} data={item.data}/>);
            case CHART_TYPE.BAR:
                return (<AxisChart key={item.id + item.type} height={DEFAULT_HEIGHT} data={item.data} yScale={{ min: 0, max: 1, formatter: function (v) { return numeral(v * 100).format("0.0") + "%"; } }} bar={{ xAxis: "xAxis", yAxis: "yAxis", color: "type" }}/>);
            case CHART_TYPE.POINT:
                return (<TgiPointChart key={item.id + item.type} data={item.data} height={DEFAULT_HEIGHT} point={{
                    xAxis: "xAxis",
                    yAxis: "yAxis",
                    size: { key: "yAxis2", format: function (v) { return numeral(v).format("0,0"); } },
                }}/>);
            case CHART_TYPE.BAR_LINE:
                return (<TgiBarLineChart height={DEFAULT_HEIGHT} key={item.id + item.type} data={item.data} bar={{ xAxis: "xAxis", yAxis: "yAxis" }} line={{ xAxis: "xAxis", yAxis: "yAxis2" }}/>);
            case CHART_TYPE.PANEL:
                return (<PanelChart height={DEFAULT_HEIGHT} value={item.data && item.data.value ? item.data.value : 0} max={item.data && item.data.max ? ceil(item.data.max, 1) : 100} format={function (v) { return numeral(v * 100).format("0.0") + "%"; }}/>);
            case CHART_TYPE.RADAR:
                return (<TgiRadarChart key={item.id + item.type} height={DEFAULT_HEIGHT} data={item.data}/>);
            case CHART_TYPE.AREA:
                return (<TgiAreaChart key={item.id + item.type} height={DEFAULT_HEIGHT} data={item.data}/>);
            case CHART_TYPE.MIGRATION_BAR:
                return (<MigrationBarChart key={item.id + item.type} height={DEFAULT_HEIGHT} data={item.data}/>);
            case CHART_TYPE.ROUTE_MAP:
                return <RouteChinaMap key={item.id + item.type} data={item.data}/>;
            case CHART_TYPE.DIFF_MAP:
                return <DiffTgiMap key={item.id + item.type} data={item.data}/>;
            case CHART_TYPE.COVERAGE_RATE:
                return <TgiBarLineChart specialType={SPECIAL_TYPE_ENUM.COVERAGE_RATE} height={DEFAULT_HEIGHT} key={item.id + item.type} data={item.data} bar={{ xAxis: "xAxis", yAxis: "yAxis" }} line={{ xAxis: "xAxis", yAxis: "yAxis2" }}/>;
            case CHART_TYPE.TABLE:
                var tableProps = {
                    data: item.data || [],
                    columns: item.option && item.option.columns ? item.option.columns : [{ key: "", title: "" }],
                    page: false
                };
                return <TableWrapper>
          <MyTable key={item.id + item.type} scroll={{ y: DEFAULT_HEIGHT - 40 }} {...tableProps}/>
        </TableWrapper>;
            case CHART_TYPE.CLOUD:
                return <TgiCloudChart height={DEFAULT_HEIGHT} key={item.id + item.type} data={item.data}/>;
            case CHART_TYPE.Line:
            default:
                return <div>{l("mprofile_charts_no_charts_tips", "暂无该类型图表")}</div>;
        }
    };
    var renderCharts = function (data) {
        return map(data, function (item) {
            return (<ChartItemWrapper key={item.id + "-" + item.title + "-" + item.col} col={item.col} row={item.row}>
        <ChartItem>
          <Title><span>{item.title}</span></Title>
          <ChartWrapper>
            {item.data && (item.data.length > 0 || item.type === CHART_TYPE.PANEL) ? renderChart(item) : <Empty />}
          </ChartWrapper>
        </ChartItem>
      </ChartItemWrapper>);
        });
    };
    return (<ChartsWrapper>
      {data && data.length > 0 ? renderCharts(data) : <Empty />}
    </ChartsWrapper>);
};
var ChartsWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: auto;\n  padding:0 10px;\n  overflow: hidden;\n"], ["\n  margin: auto;\n  padding:0 10px;\n  overflow: hidden;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 8px 0;\n  border-bottom: 1px solid #ddd;\n  > span {\n    padding-left: 10px;\n    border-left: 4px solid ", ";\n    font-weight: bolder;\n  }\n"], ["\n  padding: 8px 0;\n  border-bottom: 1px solid #ddd;\n  > span {\n    padding-left: 10px;\n    border-left: 4px solid ", ";\n    font-weight: bolder;\n  }\n"])), MAIN_COLOR);
var ChartItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  box-shadow: 1px 2px 4px #ddd;\n"], ["\n  height: 100%;\n  width: 100%;\n  box-shadow: 1px 2px 4px #ddd;\n"])));
var ChartWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: calc(100% - 40px);\n"], ["\n  padding: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: calc(100% - 40px);\n"])));
var TableWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: block;\n  height: 100%;\n"], ["\n  display: block;\n  height: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

import * as React from "react";
import { useEffect } from "react";
import queryString from "query-string";
import { externalEnter } from "@/helpers/async";
import { msgError } from "@helpers/axiosHelper";
import { useHistory } from "react-router-dom";
export var ExternalEnter = function () {
    var history = useHistory();
    var _a = queryString.parse(location.search), app = _a.app, token = _a.token, directPath = _a.directPath;
    useEffect(function () {
        var data = {
            key: token,
            externalApp: app
        };
        externalEnter(data)
            .then(function (res) {
            history.push(directPath);
        })
            .catch(function (err) {
            history.push("/enter");
            msgError(err);
        });
    }, []);
    return (<>
  </>);
};

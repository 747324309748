var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Cascader } from "antd";
import { getOptions } from "@helpers/async";
import { msgError } from "@helpers/axiosHelper";
import styled from "styled-components";
export var MyCascader = function (_a) {
    var url = _a.url, params = _a.params, allowClear = _a.allowClear, style = _a.style, value = _a.value, defaultOptions = _a.defaultOptions, disabled = _a.disabled, props = __rest(_a, ["url", "params", "allowClear", "style", "value", "defaultOptions", "disabled"]);
    var _b = useState(defaultOptions ? defaultOptions : []), options = _b[0], setOptions = _b[1];
    useEffect(function () {
        if (!url)
            return;
        if (value) {
            getOptions(url, __assign(__assign({}, params), { value: value }))
                .then(function (res) {
                setOptions(res.data);
            })
                .catch(msgError);
        }
    }, [JSON.stringify(value)]);
    useEffect(function () {
        if (defaultOptions) {
            setOptions(defaultOptions);
            return;
        }
        if (url) {
            getOptions(url, __assign(__assign({}, params), { value: value }))
                .then(function (res) {
                setOptions(res.data);
            })
                .catch(msgError);
        }
    }, [url, JSON.stringify(params), JSON.stringify(defaultOptions)]);
    return (<MCascader disabled={disabled} style={__assign({ width: "120px" }, style)} options={options} allowClear={typeof allowClear === "boolean" ? allowClear : true} value={value} {...props}/>);
};
var MCascader = styled(Cascader)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var templateObject_1;

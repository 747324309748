var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Transfer, Tree } from "antd";
import styled from "styled-components";
import { findIndex } from "lodash";
import { MySpin } from "@components/baseComponents/mySpin";
export var MyTreeTransfer = function (_a) {
    var _b = _a.targetKeys, targetKeys = _b === void 0 ? [] : _b, _c = _a.dataSource, dataSource = _c === void 0 ? [] : _c, rowKey = _a.rowKey, readonly = _a.readonly, loading = _a.loading, _d = _a.showSelectAll, showSelectAll = _d === void 0 ? false : _d, restProps = __rest(_a, ["targetKeys", "dataSource", "rowKey", "readonly", "loading", "showSelectAll"]);
    var _e = useState([]), transferDataSource = _e[0], setTransferDataSource = _e[1];
    var _f = useState(""), leftSearchValue = _f[0], setLeftSearchValue = _f[1];
    useEffect(function () {
        var transferDataSource = [];
        var flatten = function (list, parentTitle) {
            if (list === void 0) { list = []; }
            list.forEach(function (item) {
                var key = typeof rowKey === "function" ? rowKey(item) : item[rowKey];
                item.key = item.key ? item.key : key;
                item.parentTitle = parentTitle;
                transferDataSource.push(item);
                flatten(item.children, parentTitle ? parentTitle + "-" + item.label : item.label);
            });
        };
        flatten(dataSource);
        setTransferDataSource(transferDataSource);
    }, [dataSource]);
    var isChecked = function (selectedKeys, eventKey) { return selectedKeys.indexOf(eventKey) !== -1; };
    var generateTree = function (treeNodes, checkedKeys) {
        if (treeNodes === void 0) { treeNodes = []; }
        if (checkedKeys === void 0) { checkedKeys = []; }
        return treeNodes.map(function (item) {
            var key = typeof rowKey === "function" ? rowKey(item) : item[rowKey];
            return __assign(__assign({}, item), { key: key, title: item.label, disabled: checkedKeys.includes(key), children: generateTree(item.children, checkedKeys), expanded: true });
        });
    };
    var filterOption = function (direction, value) {
        if (direction === "left") {
            setLeftSearchValue(value);
        }
    };
    var initFilterData = function (data) {
        if (!leftSearchValue || leftSearchValue === "") {
            return data;
        }
        var filterList = [];
        var getFilterNode = function (data, parent) {
            data.map(function (item) {
                if (item.label.toLowerCase().indexOf(leftSearchValue.toLowerCase()) > -1) {
                    var newItem = item;
                    if (parent) {
                        var filterParentIdx = findIndex(filterList, function (item) { return item.key === parent.key; });
                        if (filterParentIdx > -1) {
                            filterList[filterParentIdx].children.push(newItem);
                        }
                        else {
                            newItem = __assign(__assign({}, parent), { children: [item] });
                            filterList.push(newItem);
                        }
                    }
                    else {
                        filterList.push(newItem);
                    }
                    return;
                }
                if (item.children && item.children.length > 0) {
                    getFilterNode(item.children, item);
                }
            });
        };
        getFilterNode(data);
        return filterList;
    };
    return (<MTransfer {...restProps} targetKeys={targetKeys} dataSource={transferDataSource} showSearch render={function (item) { return "" + (item.parentTitle ? item.parentTitle + "-" : "") + item.label; }} onSearch={filterOption} disabled={readonly} showSelectAll={showSelectAll}>
    {function (_a) {
        var direction = _a.direction, onItemSelect = _a.onItemSelect, selectedKeys = _a.selectedKeys;
        if (direction === "left") {
            var checkedKeys_1 = __spreadArrays(selectedKeys, targetKeys);
            return (<MySpin spinning={loading}>
            <div style={{ minHeight: "200px" }}>
              <Tree blockNode checkable checkStrictly 
            // defaultExpandAll
            // defaultExpandParent
            // autoExpandParent
            checkedKeys={checkedKeys_1} treeData={generateTree(initFilterData(dataSource), targetKeys)} onCheck={function (_, _a) {
                var key = _a.node.key;
                onItemSelect(key, !isChecked(checkedKeys_1, key));
            }} onSelect={function (_, _a) {
                var key = _a.node.key;
                onItemSelect(key, !isChecked(checkedKeys_1, key));
            }}/>
            </div>
          </MySpin>);
        }
    }}
  </MTransfer>);
};
var MTransfer = styled(Transfer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-transfer-list-body-customize-wrapper {\n    overflow: auto;\n  }\n"], ["\n  .ant-transfer-list-body-customize-wrapper {\n    overflow: auto;\n  }\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Area, Axis, Chart, Legend, Line, Tooltip } from "bizcharts";
import { CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
import { map } from "lodash";
import numeral from "numeral";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
export var TgiAreaChart = function (_a) {
    var data = _a.data, _b = _a.height, height = _b === void 0 ? 360 : _b;
    var legendMap = {};
    var chartIns;
    var renderTooltip = function (items, title) {
        return <TooltipWrapper>
      <Title>{title}</Title>
      {map(items, function (item, idx) {
            return (<TooltipContent key={item.title + "-" + item.name + "-" + item.value}>
              <div>
                <ItemTooltipsCircle color={item.color}/>
                {item.name === "yAxis" ? l("dashboard_percent", "Percentage") : "TGI"}:
                {item.name === "yAxis" ? numeral(item.value).format("0.0%") : numeral(item.value).format("0,0")}
              </div>
            </TooltipContent>);
        })}
    </TooltipWrapper>;
    };
    var initLegend = function () {
        var result = [];
        result[0] = {
            value: "yAxis",
            name: l("dashboard_percent", "Percentage"),
            marker: {
                symbol: "square",
                style: { fill: CHARTS_DEFAULT_COLOR_LIST[0], r: 5 },
            }
        };
        result[1] = {
            value: "yAxis2",
            name: "TGI",
            marker: {
                symbol: "square",
                style: { fill: CHARTS_DEFAULT_COLOR_LIST[1], r: 5 },
            }
        };
        return result;
    };
    var scale = {
        xAxis: {
            range: [0, 1]
        },
        yAxis: {
            min: 0,
            tickCount: 4,
            nice: true,
        },
        yAxis2: {
            min: 0,
            tickCount: 4,
            nice: true,
        }
    };
    return (<Chart height={height} padding={"auto"} appendPadding={[10, 10, -6, 10]} data={data} autoFit scale={scale} interactions={["legend-highlight"]} onGetG2Instance={function (c) { return chartIns = c; }}>
      <Tooltip shared={true}>
        {function (title, items) {
        return renderTooltip(items, title);
    }}
      </Tooltip>
      <Line position="xAxis*yAxis" color={CHARTS_DEFAULT_COLOR_LIST[0]} size="2"/>
      <Area position="xAxis*yAxis" color={CHARTS_DEFAULT_COLOR_LIST[0]}/>
      <Line position="xAxis*yAxis2" color={CHARTS_DEFAULT_COLOR_LIST[1]} size="2"/>
      <Area position="xAxis*yAxis2" color={CHARTS_DEFAULT_COLOR_LIST[1]}/>
      <Legend position={"top"} custom={true} items={initLegend()} onChange={function (ev) {
        var item = ev.item;
        var value = item.value;
        var checked = !item.unchecked;
        var geoms = chartIns.geometries;
        // 设置图例项状态
        legendMap[value] = checked;
        for (var _i = 0, geoms_1 = geoms; _i < geoms_1.length; _i++) {
            var geom = geoms_1[_i];
            if (geom.getYScale().field === value) {
                if (checked) {
                    geom.show();
                }
                else {
                    geom.hide();
                }
            }
        }
        chartIns.render(true);
    }}/>
      <Axis name={"yAxis"} label={{
        formatter: function (text, item, index) {
            return "" + numeral(text).format("0.0%");
        }
    }}/>
    </Chart>);
};
var TooltipWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px 0;\n"], ["\n  padding: 10px 0;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var ItemTooltipsCircle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"], ["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"])), function (props) { return props.color; });
var TooltipContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"], ["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

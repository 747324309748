var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store/storeContext";
import { l } from "@helpers/multiLangaugeHelper";
import { filterOption } from "@helpers/const";
import { CONDITION_ENUM, getConditionTypeList } from "../../helpers/initData";
import { MyTableTransfer } from "@components/myTransfer/myTableTransfer";
import { merge, set } from "lodash";
import { MyModal } from "@components/baseComponents/myModal";
export var CustomAudienceCondition = function (_a) {
    var readonly = _a.readonly;
    var _b = useContext(StoreContext), data = _b.data, modalOption = _b.modalOption, setModalOption = _b.setModalOption, modalData = _b.modalData, setModalData = _b.setModalData, saveModalData = _b.saveModalData;
    var _c = useState([]), transferSelectedKeys = _c[0], setTransferSelectedKeys = _c[1];
    useEffect(function () {
        setTransferSelectedKeys([]);
    }, [modalOption.type]);
    var onFormDataChange = function (value, pathKey) {
        var newData = merge({}, modalData);
        set(newData, pathKey, value);
        setModalData(newData);
    };
    var save = function () {
        saveModalData(modalData, modalOption);
        setModalOption({});
    };
    var saveCanCheck = function () {
        return modalData.itemList && modalData.itemList.length > 0;
    };
    return (<MyModal width={1000} title={getConditionTypeList()[CONDITION_ENUM.CUSTOM_AUDIENCE].text} visible={modalOption.type === CONDITION_ENUM.CUSTOM_AUDIENCE} onCancel={function () { return setModalOption({}); }} maskClosable={false} onOk={save} okButtonProps={{ disabled: !saveCanCheck() }}>
      <StoreContext.Consumer>
        {function (_a) {
        var modalOption = _a.modalOption;
        return modalOption.type === CONDITION_ENUM.CUSTOM_AUDIENCE ? (<>
          <MyTableTransfer rowKey={function (item) { return item.value; }} showSearch={true} disabled={readonly} url={"/groupme/audex/audience/standardAudience_v2/customAudience/getAudienceOptions"} params={{ advertiserId: data.advertiserId }} columns={[
            {
                key: "label",
                title: l("common_name", "Name"),
            },
        ]} listStyle={{
            width: 460
        }} titles={[l("common_wait_for_select_list", "待选列表"),
            l("common_selected_list", "已选列表")]} targetKeys={modalData.itemList} selectedKeys={transferSelectedKeys} filterOption={filterOption} onSelectChange={function (sourceSelectedKeys, targetSelectedKeys) {
            return setTransferSelectedKeys(__spreadArrays(sourceSelectedKeys, targetSelectedKeys));
        }} onChange={function (v) { return onFormDataChange(v, "itemList"); }}/>
        </>) : null;
    }}
      </StoreContext.Consumer>
    </MyModal>);
};

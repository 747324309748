var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Empty, Tree } from "antd";
import { merge, uniq } from "lodash";
import { DownOutlined } from "@/utils/antdIcon";
import { MyInput } from "@components/baseComponents/myInput";
import { l } from "@helpers/multiLangaugeHelper";
import styled from "styled-components";
export var MyTree = function (_a) {
    var data = _a.data, renderNode = _a.renderNode, props = __rest(_a, ["data", "renderNode"]);
    var _b = useState(""), searchValue = _b[0], setSearchValue = _b[1];
    var _c = useState([]), expendedKeys = _c[0], setExpendedKeys = _c[1];
    var _d = useState(true), autoExpandParent = _d[0], setAutoExpandParent = _d[1];
    var _e = useState([]), showData = _e[0], setShowData = _e[1];
    useEffect(function () {
        setShowData(data);
    }, [JSON.stringify(data)]);
    var onExpand = function (expandedKeys) {
        setExpendedKeys(expandedKeys);
        setAutoExpandParent(false);
    };
    var getFilterKeyData = function (data, keyList) {
        if (keyList === void 0) { keyList = []; }
        return data.map(function (item) {
            var newKeyList = merge([], keyList);
            newKeyList.push(item.value);
            if (item.children) {
                return __assign(__assign({}, item), { children: getFilterKeyData(item.children, newKeyList), keyList: newKeyList });
            }
            return __assign(__assign({}, item), { keyList: newKeyList });
        });
    };
    var getParentExpandData = function (data, keyList, parentKey) {
        if (keyList === void 0) { keyList = []; }
        return data.map(function (item) {
            var newKeyList = merge([], keyList);
            parentKey ? newKeyList.push(parentKey) : null;
            if (item.children) {
                return __assign(__assign({}, item), { children: getParentExpandData(item.children, newKeyList, item.value), keyList: newKeyList });
            }
            return __assign(__assign({}, item), { keyList: newKeyList });
        });
    };
    var getExpandKeys = function (value) {
        var treeData = getParentExpandData(data);
        var expandKeysList = [];
        var getExpandNode = function (data) {
            data.map(function (item) {
                if (item.label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    expandKeysList.push.apply(expandKeysList, item.keyList);
                }
                if (item.children && item.children.length > 0) {
                    getExpandNode(item.children);
                }
            });
        };
        getExpandNode(treeData);
        return uniq(expandKeysList);
    };
    var getFilterKeys = function (value) {
        var treeData = getFilterKeyData(data);
        var filterKeysList = [];
        var pushAllFilterKeys = function (data) {
            data.map(function (item) {
                filterKeysList.push.apply(filterKeysList, item.keyList);
                if (item.children && item.children.length > 0) {
                    pushAllFilterKeys(item.children);
                }
            });
        };
        var getFilterNode = function (data) {
            data.map(function (item) {
                if (item.label.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    filterKeysList.push.apply(filterKeysList, item.keyList);
                    if (item.children && item.children.length > 0) {
                        pushAllFilterKeys(item.children);
                    }
                }
                if (item.children && item.children.length > 0) {
                    getFilterNode(item.children);
                }
            });
        };
        getFilterNode(treeData);
        return uniq(filterKeysList);
    };
    var onChange = function (e) {
        var value = e.target.value;
        if (!value || value === "") {
            setSearchValue(value);
            setAutoExpandParent(true);
            setExpendedKeys(null);
            setShowData(data);
            return;
        }
        var expandKeys = getExpandKeys(value);
        var filterKeys = getFilterKeys(value);
        var showData = initFilterData(filterKeys, data);
        setSearchValue(value);
        setAutoExpandParent(true);
        setExpendedKeys(expandKeys);
        setShowData(showData);
    };
    var initFilterData = function (filterKeys, data) {
        var result = [];
        var filterData = function (data) {
            data.map(function (item) {
                if (filterKeys.includes(item.value)) {
                    result.push(item);
                }
                else if (item.children && item.children.length > 0) {
                    filterData(item.children);
                }
            });
        };
        filterData(data);
        return result;
    };
    var loop = function (data) {
        var result = data.map(function (item) {
            var index = item.label.indexOf(searchValue);
            var beforeStr = item.label.substr(0, index);
            var afterStr = item.label.substr(index + searchValue.length);
            var label = index > -1 ? (<span>
              {beforeStr}
            <span style={{ color: "#f20" }}>{searchValue}</span>
            {afterStr}
            </span>) : (<span>{item.label}</span>);
            if (item.children) {
                return __assign(__assign({}, item), { title: label, key: item.value, children: loop(item.children) });
            }
            return __assign(__assign({}, item), { title: label, key: item.value });
        });
        return result;
    };
    return (<Wrapper {...props}>
    <MyInput style={{ marginBottom: "10px", height: "40px", width: "100%" }} placeholder={l("common_search", "Search") + "..."} onChange={onChange}/>
    <div className={"treeContent"}>
      {showData && showData.length > 0 ?
        <Tree selectable={false} onExpand={onExpand} expandedKeys={expendedKeys} autoExpandParent={autoExpandParent} switcherIcon={<DownOutlined />} treeData={loop(showData)} titleRender={renderNode}/> :
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </div>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  .treeContent {\n    flex: 1;\n    overflow: auto;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  .treeContent {\n    flex: 1;\n    overflow: auto;\n  }\n"])));
var templateObject_1;

export var SORT_ORDER_ENUM;
(function (SORT_ORDER_ENUM) {
    SORT_ORDER_ENUM["ASCEND"] = "ascend";
    SORT_ORDER_ENUM["DESCEND"] = "descend";
})(SORT_ORDER_ENUM || (SORT_ORDER_ENUM = {}));
export var TABLE_RENDER_TYPE_ENUM;
(function (TABLE_RENDER_TYPE_ENUM) {
    TABLE_RENDER_TYPE_ENUM["MODAL"] = "$modal";
    TABLE_RENDER_TYPE_ENUM["POP_CONFIRM"] = "$popConfirm";
    TABLE_RENDER_TYPE_ENUM["NEW_PAGE"] = "$newPage";
    TABLE_RENDER_TYPE_ENUM["NEW_TAB"] = "$newTab";
    TABLE_RENDER_TYPE_ENUM["APPLY"] = "$apply";
    TABLE_RENDER_TYPE_ENUM["FORMAT"] = "$format";
    TABLE_RENDER_TYPE_ENUM["TAG"] = "$tag";
    TABLE_RENDER_TYPE_ENUM["LIST"] = "$list";
    TABLE_RENDER_TYPE_ENUM["TABLE_LIST"] = "$tableList";
    TABLE_RENDER_TYPE_ENUM["STATUS"] = "$status";
    TABLE_RENDER_TYPE_ENUM["MORE"] = "$more";
    TABLE_RENDER_TYPE_ENUM["STATIC_STRING"] = "$staticString";
    TABLE_RENDER_TYPE_ENUM["CHECK"] = "$check";
})(TABLE_RENDER_TYPE_ENUM || (TABLE_RENDER_TYPE_ENUM = {}));

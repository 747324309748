var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { SourceItem } from "../components/sourceItem";
import { TargetBox } from "../components/targetBox";
import { StoreContext } from "../store/storeContext";
import { map, merge, set } from "lodash";
import { CALC_TYPE, getCalcText } from "../helpers/initData";
import { CloseOutlined, DeleteRowOutlined, EditOutlined, IconFont } from "@/utils/antdIcon";
import { MAIN_COLOR, WARING_COLOR } from "@/common/styled";
import { Modal, Popconfirm, Tooltip } from "antd";
import { l } from "@helpers/multiLangaugeHelper";
import { SOURCE_ID_KU_YUN } from "@pages/customAudience/standardAudience/edit/edit";
var DEFAULT_ITEM_HEIGHT = 106;
export var CalcContainer = function () {
    var _a = useContext(StoreContext), masterData = _a.data, calcConditions = _a.calcConditions, setCalcConditions = _a.setCalcConditions, setModalData = _a.setModalData, setModalOption = _a.setModalOption, readonly = _a.readonly;
    var Line = function (_a) {
        var top = _a.top, reserve = _a.reserve, height = _a.height;
        return (<TdLine reserve={reserve} top={top}>
      <TrLine reserve={reserve} height={reserve ? height + "px" : "90px"}/>
    </TdLine>);
    };
    var changeCalcType = function (idx, type) {
        var newCalcConditions = merge([], calcConditions);
        set(newCalcConditions, [idx, "type"], type === CALC_TYPE.AND ? CALC_TYPE.EXCLUDE : CALC_TYPE.AND);
        setCalcConditions(newCalcConditions);
    };
    var renderCalcItem = function (data) {
        if (!data || data.length === 0)
            return;
        var result = renderTargetContent(data[0], 0);
        var CALC_TEXT = getCalcText();
        var getTypeColor = function (type) {
            switch (type) {
                case (CALC_TYPE.AND):
                    return MAIN_COLOR;
                case (CALC_TYPE.EXCLUDE):
                    return WARING_COLOR;
                default:
                    return "#fff";
            }
        };
        if (data.length > 1) {
            map(data, function (item, idx) {
                if (idx === 0)
                    return null;
                result = (<CalcItem>
            <CalcTitleWrapper>
              <Line top={idx * 50}/>
              <CalcTitle bg={getTypeColor(item.type)} onClick={function () { return readonly || masterData.sourceId === SOURCE_ID_KU_YUN ? null : changeCalcType(idx, item.type); }}>
                <CalcIcon type={item.type === CALC_TYPE.AND ? "icon-jiedianjiaoji" : "icon-jiedianpaichu"}/>
                {item && item.type ? CALC_TEXT[item.type] : CALC_TEXT[CALC_TYPE.AND]}
              </CalcTitle>
              <Line top={40} reserve={true} height={52 * idx}/>
            </CalcTitleWrapper>
            <CalContent>
              {result}
              {renderTargetContent(data[idx], idx)}
            </CalContent>
          </CalcItem>);
            });
        }
        return result;
    };
    var changeItemCalc = function (item, dropRes) {
        var newCalcConditions = merge([], calcConditions);
        if (newCalcConditions[dropRes.params.idx].conditions.length === 10) {
            Modal.info({ content: l("custom_audience_cal_item_max_10_tips", "最多添加10个运算条件") });
            return;
        }
        newCalcConditions[item.calcIdx].conditions.splice(item.idx, 1);
        newCalcConditions[dropRes.params.idx].conditions.push(__assign({ type: item.type, data: item.data }, item));
        setCalcConditions(newCalcConditions);
    };
    var delCondition = function (e, calcIdx, idx) {
        e.stopPropagation();
        var newCalcConditions = merge([], calcConditions);
        newCalcConditions[calcIdx].conditions.splice(idx, 1);
        setCalcConditions(newCalcConditions);
    };
    var onCalcSourceClick = function (item, calcIdx, idx) {
        setModalData(calcConditions[calcIdx].conditions[idx].data);
        setModalOption({
            idx: idx,
            calcIdx: calcIdx,
            type: item.type,
            title: item.title,
            tagId: item.tagId,
            params: item.params,
        });
    };
    var renderTargetSource = function (list, calcIdx) {
        return map(list, function (item, idx) {
            return (<CalcSourceWrapper>
          {idx !== 0 ? <OrIcon type="icon-binghang"/> : null}
          <CalcSource key={calcIdx + "-" + item.type + "-" + idx} name={item.type} type={"condition"} onEnd={function (e, dropRes) { return changeItemCalc(__assign(__assign({}, item), { idx: idx, calcIdx: calcIdx }), dropRes); }} onClick={function () { return onCalcSourceClick(item, calcIdx, idx); }}>
            <SourceContent>
              <CalcSourceTitle>
                <Tooltip title={item.title}>
                  {item.title}
                </Tooltip>
                {readonly ? null : <Popconfirm title={l("common_del_confirm", "确认删除") + "?"} onConfirm={function (e) { return delCondition(e, calcIdx, idx); }} onCancel={function (e) {
                e.stopPropagation();
            }}>
                  <DelCondition onClick={function (e) { return e.stopPropagation(); }}>
                    <CloseOutlined />
                  </DelCondition>
                </Popconfirm>}
              </CalcSourceTitle>
              <CalcSourceContent>
                <Tooltip title={item.data.desc}>
                  <div className={"desc"}>{item.data.desc}</div>
                </Tooltip>
              </CalcSourceContent>
            </SourceContent>
            <EditIcon />
          </CalcSource>
        </CalcSourceWrapper>);
        });
    };
    var renderTargetContent = function (calcItem, idx) {
        return (<TargetWrapper name={calcItem.type} type={"condition"} params={{ type: calcItem.type, idx: idx }} readonly={readonly}>
        <TargetContentWrapper>
          <TargetContent>
            {renderTargetSource(calcItem.conditions, idx)}
          </TargetContent>
        </TargetContentWrapper>
        {idx === 0 || readonly ? null : <Popconfirm title={l("common_del_confirm", "确认删除") + "?"} onConfirm={function () { return delCalc(idx); }}>
          <DelCalc><DeleteRowOutlined /></DelCalc>
        </Popconfirm>}
      </TargetWrapper>);
    };
    var delCalc = function (idx) {
        var newCalcConditions = merge([], calcConditions);
        newCalcConditions.splice(idx, 1);
        setCalcConditions(newCalcConditions);
    };
    return (<Wrapper>
      <CalcWrapper name={"wrapper"} type={"condition"}>
        {renderCalcItem(calcConditions)}
      </CalcWrapper>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  height: calc(100% - 92px);\n"], ["\n  overflow: hidden;\n  height: calc(100% - 92px);\n"])));
var TargetWrapper = styled(TargetBox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 14px;\n  padding-right:", ";\n  position: relative;\n  overflow: hidden;\n  display: flex;\n  height: ", "px;\n  width: auto;\n  border-style: dashed;\n  &:nth-child(1){\n    padding-right: 0;\n  }\n  &:nth-child(2){\n    margin-bottom: 0;\n  }\n"], ["\n  margin-bottom: 14px;\n  padding-right:", ";\n  position: relative;\n  overflow: hidden;\n  display: flex;\n  height: ", "px;\n  width: auto;\n  border-style: dashed;\n  &:nth-child(1){\n    padding-right: 0;\n  }\n  &:nth-child(2){\n    margin-bottom: 0;\n  }\n"])), function (props) { return props.readonly ? 0 : "24px"; }, DEFAULT_ITEM_HEIGHT);
var TargetContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow-x: auto;\n  overflow-y: hidden;\n  width: 100%;\n  height: 100%;\n"], ["\n  overflow-x: auto;\n  overflow-y: hidden;\n  width: 100%;\n  height: 100%;\n"])));
var DelCalc = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 24px;\n  cursor: pointer;\n  font-size: 16px;\n  background: #ff664d;\n  color: #fff;\n"], ["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 24px;\n  cursor: pointer;\n  font-size: 16px;\n  background: #ff664d;\n  color: #fff;\n"])));
var SourceContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  overflow: hidden;\n  display: block;\n  height: 100%;\n"], ["\n  overflow: hidden;\n  display: block;\n  height: 100%;\n"])));
var DelCondition = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n"], ["\n"])));
var TargetContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n"])));
var CalcSourceWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  float: left;\n  height: 100%;\n  &:nth-child(1){\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  float: left;\n  height: 100%;\n  &:nth-child(1){\n    margin-left: 10px;\n  }\n"])));
var CalcSource = styled(SourceItem)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width:160px;\n  height: ", "px;\n  background: #fff;\n  border: 1px solid #EDF4FA;\n  box-shadow: 0 0 2px #ddd;\n  cursor: pointer;\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width:160px;\n  height: ", "px;\n  background: #fff;\n  border: 1px solid #EDF4FA;\n  box-shadow: 0 0 2px #ddd;\n  cursor: pointer;\n"])), DEFAULT_ITEM_HEIGHT - 12);
var CalcIcon = styled(IconFont)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: block;\n  font-size: 12px;\n"], ["\n  display: block;\n  font-size: 12px;\n"])));
var CalcSourceTitle = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 2px 6px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #EDF4FA;\n  width: 100%;\n  > span {\n    flex: 1;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    color: #95aac9;\n  }\n"], ["\n  padding: 2px 6px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #EDF4FA;\n  width: 100%;\n  > span {\n    flex: 1;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    color: #95aac9;\n  }\n"])));
var CalcSourceContent = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: 6px;\n  flex: 1;\n  color: #555;\n  > .desc {\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  }\n"], ["\n  padding: 6px;\n  flex: 1;\n  color: #555;\n  > .desc {\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n  }\n"])));
var EditIcon = styled(EditOutlined)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 2px;\n  right: 4px;\n  color: #95aac9;\n"], ["\n  position: absolute;\n  bottom: 2px;\n  right: 4px;\n  color: #95aac9;\n"])));
var CalcWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  overflow: auto;\n  padding: 14px;\n  width: 100%;\n  height: 100%;\n  border: none;\n"], ["\n  overflow: auto;\n  padding: 14px;\n  width: 100%;\n  height: 100%;\n  border: none;\n"])));
var CalcTitleWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: relative;\n  width: 50px;\n"], ["\n  position: relative;\n  width: 50px;\n"])));
var CalcTitle = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  z-index: 1;\n  top: 50%;\n  left: 50%;\n  padding: 4px;\n  height: 70px;\n  width: 26px;\n  transform: translate(-50%,-50%);\n  text-align: center;\n  color: #fff;\n  background: ", ";\n  cursor: pointer;\n  font-size: 12px;\n  border-radius: 4px;\n  user-select:none;\n"], ["\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  z-index: 1;\n  top: 50%;\n  left: 50%;\n  padding: 4px;\n  height: 70px;\n  width: 26px;\n  transform: translate(-50%,-50%);\n  text-align: center;\n  color: #fff;\n  background: ", ";\n  cursor: pointer;\n  font-size: 12px;\n  border-radius: 4px;\n  user-select:none;\n"])), function (props) { return props.bg; });
var CalcItem = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  display: flex;\n"], ["\n  margin-bottom: 10px;\n  display: flex;\n"])));
var CalContent = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  width: calc(100% - 50px);\n"], ["\n  width: calc(100% - 50px);\n"])));
var OrIcon = styled(IconFont)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  display: block;\n  width: 30px;\n  font-size: 16px;\n  color: ", ";\n"], ["\n  display: block;\n  width: 30px;\n  font-size: 16px;\n  color: ", ";\n"])), MAIN_COLOR);
var TrLine = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  z-index: 0;\n  position: absolute;\n  top: ", ";\n  bottom: ", ";\n  width: 1px;\n  height: ", ";\n  background: #aaa;\n"], ["\n  z-index: 0;\n  position: absolute;\n  top: ", ";\n  bottom: ", ";\n  width: 1px;\n  height: ", ";\n  background: #aaa;\n"])), function (props) { return props.reserve ? undefined : 0; }, function (props) { return props.reserve ? 0 : undefined; }, function (props) { return props.height; });
var TdLine = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 0;\n  top:", ";\n  bottom:", ";\n  left: 24px;\n  width: 40px;\n  height: 1px;\n  background: #aaa;\n"], ["\n  position: absolute;\n  z-index: 0;\n  top:", ";\n  bottom:", ";\n  left: 24px;\n  width: 40px;\n  height: 1px;\n  background: #aaa;\n"])), function (props) { return props.reserve ? undefined : props.top + "px"; }, function (props) { return props.reserve ? props.top + "px" : undefined; });
var ItemImg = styled.img(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  display: block;\n  margin:10px auto 0 auto;\n  width: 64px;\n  height: 64px;\n"], ["\n  display: block;\n  margin:10px auto 0 auto;\n  width: 64px;\n  height: 64px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { TechHubRoute } from "@router/techhubRoute";
import ContentWrapper from "@components/contentWrapper/contentWrapper";
import { getAccountInfo } from "@components/contentWrapper/helpers/async";
import { msgError } from "@helpers/axiosHelper";
import { MySpin } from "@components/baseComponents/mySpin";
import styled from "styled-components";
import { SpecialOpcoWrapper } from "@components/contentWrapper/specialOpcoWrapper";
import { getOpcoTitle } from "@pages/account/helpers/async";
import { SkinWrapper } from "@components/contentWrapper/skinWrapper";
import { SkinContext } from "@components/contentWrapper/store/storeContext";
import { MAIN_COLOR } from "@/common/styled";
export var MenuSelector = function () {
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), isSpecialOpco = _b[0], setIsSpecialOpco = _b[1];
    var _c = useState(false), opco = _c[0], setOpco = _c[1];
    var _d = useState({ mainColor: MAIN_COLOR }), skin = _d[0], setSkin = _d[1];
    window.skin = skin;
    useEffect(function () {
        getAccountInfo()
            .then(function (res) {
            checkEnv(res.data.env);
            setIsSpecialOpco(res.data.isSpecialOpco);
            setOpco(res.data.opco);
            window.user = res.data;
            getOpcoTitle(res.data.opco)
                .then(function (res) {
                if (res.data.icoUrl) {
                    var ico = document.getElementById("ico");
                    var icoUrl = "/objects/groupm/img/favicon/" + res.data.icoUrl + ".ico";
                    ico.href = icoUrl;
                }
                if (res.data.skin) {
                    window.skin = res.data.skin;
                    setSkin(res.data.skin);
                }
                setLoading(false);
            });
        })
            .catch(msgError);
    }, []);
    var checkEnv = function (DBEnv) {
        var DB_ENV_LIST_MAP = {
            "dev": "develop",
            "itg": "integration",
            "uat": "uat",
            "pre": "preproduct",
            "www": "product"
        };
        if (DBEnv !== DB_ENV_LIST_MAP[window.env] && location.host.indexOf("localhost") === -1) {
            location.href = "/envErr/30-DB-" + DBEnv + "-" + window.env;
            return;
        }
    };
    if (loading) {
        return <MySpin spinning={loading}><Block /></MySpin>;
    }
    return (<SkinContext.Provider value={{ skin: skin, setSkin: setSkin }}>
      <MySpin spinning={loading}>
        <SkinWrapper skin={skin}>
          {isSpecialOpco || opco === "caas" ? <SpecialOpcoWrapper>
              <TechHubRoute />
            </SpecialOpcoWrapper> :
        <ContentWrapper>
              <TechHubRoute />
            </ContentWrapper>}
        </SkinWrapper>
      </MySpin>
    </SkinContext.Provider>);
};
var Block = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 800px;\n"], ["\n  height: 800px;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, InputNumber, Modal } from "antd";
import { StoreContext } from "../../store/storeContext";
import { l } from "@helpers/multiLangaugeHelper";
import { getItemLayout } from "@helpers/const";
import { campaignColumns, CONDITION_ENUM, getConditionTypeList } from "../../helpers/initData";
import { findIndex, merge, remove, set } from "lodash";
import { MySelect } from "@components/baseComponents/mySelect";
import { MyRangePicker } from "@components/baseComponents/myRangePicker";
import moment from "moment";
import { MyTable } from "@components/myTable/myTable";
import { msgError } from "@helpers/axiosHelper";
import styled from "styled-components";
import { MySearch } from "@components/baseComponents/mySearch";
import { getCampaignTrackingList } from "../../helpers/async";
import { MyModal } from "@components/baseComponents/myModal";
var FormItem = Form.Item;
var itemLayout = getItemLayout(3, 12);
export var CampaignCondition = function (_a) {
    var readonly = _a.readonly;
    var _b = useContext(StoreContext), data = _b.data, modalOption = _b.modalOption, setModalOption = _b.setModalOption, modalData = _b.modalData, setModalData = _b.setModalData, saveModalData = _b.saveModalData;
    var _c = useState([]), list = _c[0], setList = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState({
        pageSize: 10,
        current: 1,
    }), page = _e[0], setPage = _e[1];
    var _f = useState(""), keyword = _f[0], setKeyword = _f[1];
    useEffect(function () {
        setKeyword("");
        resetTable();
        setLoading(false);
        if (modalData.campaignId) {
            onSearch(modalData, merge({}, page, { current: 1 }));
        }
    }, [JSON.stringify(modalOption.type === CONDITION_ENUM.CAMPAIGN)]);
    var getDisabledDate = function (current) {
        return current &&
            (current > moment().endOf("day") ||
                current < moment().add(-1, "y").startOf("month"));
    };
    var resetTable = function () {
        var newPage = merge({}, page);
        newPage.total = null;
        newPage.current = 1;
        setList([]);
        setPage(newPage);
    };
    var onFormDataChange = function (value, pathKey) {
        var newData = merge({}, modalData);
        if (modalData.listenerIdList && modalData.listenerIdList.length > 0) {
            if (pathKey === "platformId" || pathKey === "activityId" || pathKey === "campaignId") {
                Modal.confirm({
                    title: l("common_warn_tips", "温馨提示"),
                    content: l("audience_campaign_change_tips", "继续操作将清空已选监测代码，是否继续？"),
                    onOk: function () {
                        set(newData, pathKey, value);
                        if (pathKey === "platformId") {
                            set(newData, "activityId", null);
                            set(newData, "campaignId", null);
                            set(newData, "listenerIdList", []);
                            resetTable();
                        }
                        if (pathKey === "activityId") {
                            set(newData, "campaignId", null);
                            set(newData, "listenerIdList", []);
                            resetTable();
                        }
                        if (pathKey === "campaignId") {
                            set(newData, "listenerIdList", []);
                            if (newData.campaignId) {
                                onSearch(merge({}, newData, { keyword: keyword }), page);
                            }
                            else {
                                resetTable();
                            }
                        }
                        setModalData(newData);
                    }
                });
                return;
            }
        }
        set(newData, pathKey, value);
        if (pathKey === "platformId") {
            set(newData, "activityId", null);
            set(newData, "campaignId", null);
            resetTable();
        }
        if (pathKey === "activityId") {
            set(newData, "campaignId", null);
            resetTable();
        }
        if (pathKey === "campaignId") {
            if (newData.campaignId) {
                onSearch(merge({}, newData, { keyword: keyword }), page);
            }
            else {
                resetTable();
            }
        }
        setModalData(newData);
    };
    var onSearch = function (conditions, page) {
        var data = __assign({ conditions: conditions }, page);
        setLoading(true);
        getCampaignTrackingList(data)
            .then(function (res) {
            setList(res.data.list);
            setPage(merge({}, page, { total: res.data.total }));
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var onFilterSearch = function (keyword) {
        if (modalData.platformId && modalData.activityId && modalData.campaignId) {
            onSearch(merge({}, modalData, { keyword: keyword }), merge({}, page, { current: 1 }));
        }
        setKeyword(keyword);
    };
    var onRowSelect = function (currentSelectedRowKeys, currentSelectedRows) {
        var newData = merge({}, modalData);
        var newSelectedRowKeys = newData.listenerIdList || [];
        remove(newSelectedRowKeys, function (objItem) {
            return (findIndex(list, function (item) { return objItem === item.id; }) > -1);
        });
        newSelectedRowKeys.push.apply(newSelectedRowKeys, currentSelectedRowKeys);
        set(newData, "listenerIdList", newSelectedRowKeys);
        setModalData(newData);
    };
    var tableProps = {
        rowKey: "id",
        data: list,
        columns: campaignColumns(),
        page: page,
        loading: loading,
        onChange: function (page) { return onSearch(merge({}, modalData, { keyword: keyword }), page); },
        rowSelection: {
            selectedRowKeys: modalData.listenerIdList || [],
            onChange: onRowSelect,
            getCheckboxProps: function (record) { return ({
                disabled: false
            }); }
        }
    };
    var save = function () {
        saveModalData(modalData, modalOption);
        setModalOption({});
    };
    var saveCanCheck = function () {
        if (!(modalData.platformId && modalData.activityId))
            return false;
        if (!modalData.campaignId)
            return false;
        if (!(modalData.listenerIdList && modalData.listenerIdList.length > 0))
            return false;
        if (!modalData.dateRange || !modalData.dateRange[0])
            return false;
        if (!modalData.minFrequency)
            return false;
        return true;
    };
    return (<MyModal width={1000} title={getConditionTypeList()[CONDITION_ENUM.CAMPAIGN].text} visible={modalOption.type === CONDITION_ENUM.CAMPAIGN} onCancel={function () { return setModalOption({}); }} maskClosable={false} onOk={save} okButtonProps={{ disabled: !saveCanCheck() }}>
      <StoreContext.Consumer>
        {function (_a) {
        var modalOption = _a.modalOption;
        return modalOption.type === CONDITION_ENUM.CAMPAIGN ? (<>
          <FilterWrapper>
            <p>{l("custom_audience_filter_audience", "筛选人群")}</p>
            <MySelect allowClear={false} disabled={readonly} style={{ marginRight: "10px", width: "160px" }} placeholder={l("common_platform_select_placeholder", "Select Platform")} url={"/groupme/audex/audience/standardAudience_v2/campaign/getPlatformOptions"} params={{ advertiserId: data.advertiserId }} value={modalData.platformId} onChange={function (v) { return onFormDataChange(v, "platformId"); }}/>
            <MySelect allowClear={false} disabled={readonly} style={{ marginRight: "10px", width: "160px" }} placeholder={l("common_behavior_select_placeholder", "Select Behavior")} url={"/groupme/audex/audience/standardAudience_v2/campaign/getActivityOptions"} params={{
            platformId: modalData.platformId,
            advertiserId: data.advertiserId
        }} value={modalData.activityId} onChange={function (v) { return onFormDataChange(v, "activityId"); }}/>
            <MySelect allowClear={false} disabled={readonly} style={{ marginRight: "10px", width: "360px" }} placeholder={l("common_campaign_select_placeholder", "Select Campaign")} url={"/groupme/audex/audience/standardAudience_v2/campaign/getCampaginOptions"} params={{
            platformId: modalData.platformId,
            activityId: modalData.activityId,
            advertiserId: data.advertiserId
        }} value={modalData.campaignId} onChange={function (v) { return onFormDataChange(v, "campaignId"); }}/>
            <MySearch style={{ width: "210px" }} allowClear={true} placeholder={l("common_keyword_placeholder", "please input keyword")} onSearch={onFilterSearch}/>
          </FilterWrapper>
          <MyTable {...tableProps}/>
          <br />
          <FormItem required {...itemLayout} label={l("common_frequency", "频次")}>
            <InputNumber min={1} style={{ width: "60px" }} disabled={readonly} value={modalData.minFrequency} onChange={function (v) { return onFormDataChange(v, "minFrequency"); }}/>
            <span style={{ margin: "0 4px" }}>
              ～
            </span>
            <InputNumber min={modalData.minFrequency || 1} style={{ width: "60px" }} disabled={readonly} value={modalData.maxFrequency} onChange={function (v) { return onFormDataChange(v, "maxFrequency"); }}/>
            <span style={{ margin: "0 8px" }}>{l("common_time_s", "time(s)")}</span>
          </FormItem>
          <FormItem required {...itemLayout} label={l("common_date_range", "日期范围")}>
            <MyRangePicker disabled={[readonly, readonly]} disabledDate={function (current) { return getDisabledDate(current); }} value={modalData.dateRange} onChange={function (v) { return onFormDataChange(v, "dateRange"); }}/>
          </FormItem>
        </>) : null;
    }}
      </StoreContext.Consumer>
    </MyModal>);
};
var FilterWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 24px;\n"], ["\n  margin-bottom: 24px;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useState } from "react";
import { Button, message } from "antd";
import { batchExportApi } from "@components/batchExport/async";
import { msgError } from "@helpers/axiosHelper";
import { l } from "@helpers/multiLangaugeHelper";
import styled from "styled-components";
import { MAIN_COLOR } from "@/common/styled";
export var EXPORT_TYPE_LIST;
(function (EXPORT_TYPE_LIST) {
    EXPORT_TYPE_LIST["PROFILING_SEGMENT_EXPORT"] = "/groupme/audex/profiling/profile/segmentReportDownload";
    EXPORT_TYPE_LIST["PROFILING_COMPARISON_EXPORT"] = "/groupme/audex/profiling/profile/comparisonReportDownload";
    EXPORT_TYPE_LIST["TRACKING_ADVERTISING_TRACKING_REPORT_EXPORT"] = "/groupme/audex/tracking/advertisingTracking/reportDownload";
    EXPORT_TYPE_LIST["CORRELATION_ANALYSIS_EXPORT"] = "/groupme/audex/modeling/correlationAnalysis/download";
    EXPORT_TYPE_LIST["DOOH_PLANNING_LIST_EXPORT"] = "/groupme/audex/doohplanning/reportDownload";
    EXPORT_TYPE_LIST["ACTIVATION_ACTIVATE_MANAGE_SECRET_KEY_EXPORT"] = "/groupme/audex/activation/activateManage/secretKeyDownload";
})(EXPORT_TYPE_LIST || (EXPORT_TYPE_LIST = {}));
export var BatchSyncExport = function (_a) {
    var btnText = _a.btnText, type = _a.type, _b = _a.url, url = _b === void 0 ? "/groupme/common/download" : _b, disabled = _a.disabled, style = _a.style, params = _a.params, filename = _a.filename, fileType = _a.fileType, _c = _a.btnType, btnType = _c === void 0 ? "button" : _c, icon = _a.icon, callback = _a.callback;
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var onExport = function () {
        var data = {
            type: type,
            params: params
        };
        setLoading(true);
        batchExportApi(url, data)
            .then(function (res) {
            var blobData = res.data;
            if (res.data && !res.data.size) {
                message.info("no data source");
                setLoading(false);
                return;
            }
            var reader = new FileReader();
            reader.readAsDataURL(blobData); // 转换为base64，可以直接放入a标签href
            reader.onload = function (e) {
                // 转换完成，创建一个a标签用于下载
                var a = document.createElement("a");
                a.style.display = "none";
                var contentDisposition = res.headers["content-disposition"];
                if (!contentDisposition)
                    return message.info("no data source");
                contentDisposition = contentDisposition.match(/filename=(.*);/)[0];
                contentDisposition = decodeURI(contentDisposition.substring(9, contentDisposition.length - 1));
                a.download = filename && fileType ? filename + "." + fileType : contentDisposition;
                a.href = e.target.result;
                var body = document.body;
                body.appendChild(a); // 修复firefox中无法触发click
                a.click();
                body.removeChild(a);
            };
            setLoading(false);
            // window.open(res.url)
            if (callback) {
                callback();
            }
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    if (btnType === "icon") {
        var Component = icon;
        return (<Component style={style} disabled={loading || disabled} onClick={loading || disabled ? null : onExport}/>);
    }
    if (btnType === "href") {
        return (<HrefA style={style} disabled={loading || disabled} onClick={loading || disabled ? null : onExport}>
        {btnText ? btnText : l("common_export", "导出")}
      </HrefA>);
    }
    return (<Button type="primary" style={style} loading={loading} disabled={disabled} onClick={onExport}>
      {btnText ? btnText : l("common_export", "导出")}
    </Button>);
};
var HrefA = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  color: ", ";\n"], ["\n  display: inline-block;\n  color: ", ";\n"])), function (props) { return props.disabled ? "#ddd" : MAIN_COLOR; });
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@/utils/antdIcon";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
var timmer;
export var MyTableSearch = function (_a) {
    var onChange = _a.onChange, onSearch = _a.onSearch, suffix = _a.suffix, GAActionKey = _a.GAActionKey, placeholder = _a.placeholder, props = __rest(_a, ["onChange", "onSearch", "suffix", "GAActionKey", "placeholder"]);
    var onSearchChange = function (v) {
        onChange ? onChange(v) : null;
        var value = v.target.value;
        if (timmer) {
            clearTimeout(timmer);
            timmer = null;
        }
        timmer = setTimeout(function () {
            onSearch(value);
        }, 500);
    };
    return (<Wrapper>
      <MSearch {...props} placeholder={placeholder || (l("common_search", "Search") + "...")} allowClear={false} prefix={<SearchOutlined style={{ fontSize: "18px", color: "#95aac9" }}/>} onChange={onSearchChange} onPressEnter={null}/>
      <SuffixWrapper>
        {suffix}
      </SuffixWrapper>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n"])));
var SuffixWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 14px;\n"], ["\n  padding: 0 14px;\n"])));
var MSearch = styled(Input)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 60px;\n  flex: 1;\n  border: none !important;\n  box-shadow: none !important;\n  text-indent: 10px;\n  .ant-input-prefix {\n    margin-right: 10px;\n  }\n"], ["\n  height: 60px;\n  flex: 1;\n  border: none !important;\n  box-shadow: none !important;\n  text-indent: 10px;\n  .ant-input-prefix {\n    margin-right: 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;

import * as React from "react";
import { Chart, Area, Line, Tooltip, Point, View } from "bizcharts";
import DataSet from "@antv/data-set";
export var AreaChart = function (_a) {
    var type = _a.type, _b = _a.height, height = _b === void 0 ? 360 : _b, padding = _a.padding, appendPadding = _a.appendPadding, data = _a.data, animate = _a.animate, // Chart
    visible = _a.visible, showTitle = _a.showTitle, title = _a.title, showMarkers = _a.showMarkers, showContent = _a.showContent, toolTipPosition = _a.toolTipPosition, follow = _a.follow, offset = _a.offset, enterable = _a.enterable, _c = _a.shared, shared = _c === void 0 ? true : _c, // Tooltip
    position = _a.position, areaColor = _a.areaColor, areaLabel = _a.areaLabel, areaAdjust = _a.areaAdjust, areaShape = _a.areaShape, areaSize = _a.areaSize, areaStyle = _a.areaStyle, // Area
    lineColor = _a.lineColor, lineLabel = _a.lineLabel, lineAdjust = _a.lineAdjust, lineShape = _a.lineShape, lineSize = _a.lineSize, lineStyle = _a.lineStyle, tooltip = _a.tooltip, // Line
    averages = _a.averages // 数据区间中值
    ;
    var initScale = function (type) {
        var resolveScale = null;
        if (type === "interactive") {
            resolveScale = {
                value: {
                    // min: 10000,
                    nice: true,
                },
                time: {
                    range: [0, 1]
                }
            };
        }
        if (type === "basic") {
            resolveScale = {
                value: {
                    min: 10000,
                    nice: true,
                },
                year: {
                    range: [0, 1]
                }
            };
        }
        if (type === "pile") {
            resolveScale = {
                value: {
                    nice: true,
                },
                year: {
                    type: "linear",
                    tickInterval: 50,
                }
            };
        }
        if (type === "negative") {
            resolveScale = {
                value: {
                    nice: true,
                },
                year: {
                    range: [0, 1],
                },
            };
        }
        if (type === "zero") {
            resolveScale = {
                value: {
                    alias: "The Share Price in Dollars",
                    formatter: function (val) {
                        return "$" + val;
                    },
                    nice: true,
                },
                year: {
                    range: [0, 1],
                },
            };
        }
        if (type === "percent") {
            resolveScale = {
                percent: {
                    formatter: function (value) {
                        value = +value || 0;
                        value = +value * 100;
                        return value.toFixed(2) + "%";
                    },
                    alias: "percent(%)",
                },
                year: {
                    type: "linear",
                    tickInterval: 50,
                },
            };
        }
        if (type === "interval") {
            resolveScale = {
                temperature: {
                    sync: true,
                    nice: true,
                },
                time: {
                    type: "time",
                    mask: "MM-DD",
                    nice: true,
                    tickInterval: 24 * 3600 * 1000 * 2,
                },
            };
        }
        return resolveScale;
    };
    var initData = function (data, type) {
        var dv = new DataSet.DataView().source(data);
        if (type === "negative") {
            // 数据需要加工成 {year: '1996', type: 'north', value: 50} 的模式
            dv.transform({
                type: "fold",
                fields: ["north", "south"],
                key: "type",
                value: "value",
            });
        }
        else if (type === "zero") {
            dv.transform({
                type: "fold",
                fields: ["ACME", "Compitor"],
                key: "type",
                value: "value",
            });
        }
        else if (type === "percent") {
            // 计算百分比
            dv.transform({
                type: "percent",
                field: "value",
                dimension: "country",
                groupBy: ["year"],
                as: "percent",
            });
        }
        return dv.rows;
    };
    var scale = initScale(type);
    var resolveData = initData(data, type);
    var renderData = function () {
        if (type === "basic" || type === "pile" || type === "interval")
            return data;
        else
            return resolveData;
    };
    return (<Chart width={"100%"} height={height} scale={scale} data={renderData()} autoFit animate={animate} padding={padding} appendPadding={appendPadding}>
      <Tooltip shared={shared} visible={visible} showTitle={showTitle} title={title} showMarkers={showMarkers} showContent={showContent} position={"top"} follow={follow} offset={offset} enterable={enterable}/>
      {type === "interval" ?
        <View data={renderData()} scale={{ temperature: { alias: "温度区间" } }}>
          <Area position={position} color={areaColor} label={areaLabel} adjust={areaAdjust} shape={areaShape} size={areaSize} style={areaStyle}/>
        </View> :
        <Area position={position} color={areaColor} label={areaLabel} adjust={areaAdjust} shape={areaShape} size={areaSize} style={areaStyle}/>}
      {type === "interval" ?
        <View data={averages} scale={{ temperature: { alias: "平均温度" } }}>
          <Line position={position} color={lineColor} label={lineLabel} adjust={lineAdjust} shape={lineShape} size={lineSize} style={lineStyle}/>
          <Point position="time*temperature" size={3} shape="circle"/>
        </View> :
        <Line position={position} color={lineColor} label={lineLabel} adjust={lineAdjust} shape={lineShape} size={lineSize} style={lineStyle}/>}
    </Chart>);
};

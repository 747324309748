var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled from "styled-components";
export var FORM_ITEM_WIDTH = "340px";
export var FORM_MODAL_WIDTH = 760;
export var MyFormItem = function (_a) {
    var label = _a.label, labelItem = _a.labelItem, required = _a.required, oneLine = _a.oneLine, children = _a.children, props = __rest(_a, ["label", "labelItem", "required", "oneLine", "children"]);
    return (<Wrapper {...props}>
    <Title><span>{required ? <RequiredIcon>*</RequiredIcon> : null}{label}：</span><span>{labelItem}</span></Title>
    <Content oneLine={oneLine}>{children}</Content>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 16px;\n"], ["\n  margin-bottom: 16px;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 8px;\n  display: flex;\n  overflow: hidden;\n  font-size: 14px;\n  color: #646464;\n\n  > span:nth-child(2) {\n    margin-left: 8px;\n  }\n"], ["\n  margin-bottom: 8px;\n  display: flex;\n  overflow: hidden;\n  font-size: 14px;\n  color: #646464;\n\n  > span:nth-child(2) {\n    margin-left: 8px;\n  }\n"])));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: ", ";\n  align-items: baseline;\n"], ["\n  display: ", ";\n  align-items: baseline;\n"])), function (props) { return props.oneLine ? "flex" : "block"; });
var RequiredIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: hidden;\n  display: inline-block;\n  height: 14px;\n  width: 16px;\n  text-align: center;\n  font-size: 18px;\n  font-weight: lighter;\n  color: #f20;\n"], ["\n  overflow: hidden;\n  display: inline-block;\n  height: 14px;\n  width: 16px;\n  text-align: center;\n  font-size: 18px;\n  font-weight: lighter;\n  color: #f20;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { getOptions } from "@helpers/async";
import { l } from "@helpers/multiLangaugeHelper";
import { Result } from "antd";
import styled from "styled-components";
export var CommonApiLink = function () {
    var params = queryString.parse(location.search);
    var url = params.url, redirect = params.redirect;
    var _a = useState(), msg = _a[0], setMsg = _a[1];
    var _b = useState({}), data = _b[0], setData = _b[1];
    useEffect(function () {
        if (url) {
            getOptions(url, params)
                .then(function (res) {
                setMsg(res.msg);
                setData(res.data);
                if (redirect) {
                    location.href = redirect;
                }
            })
                .catch(function (err) {
                setMsg(err.response.data.msg);
            });
        }
    }, []);
    if (!url) {
        return (<Wrapper>
      <Result status={"error"} title={l("commonApi_link_tips_err", "请求异常，请确认链接是否正确?")}/>
    </Wrapper>);
    }
    if (!msg) {
        return null;
    }
    return (<Wrapper>
      <Result status={data.status || "success"} title={msg} subTitle={data.desc}/>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  >div {\n    padding: 68px 32px;\n    width: 90%;\n    border: 1px solid #ddd;\n    box-shadow: 0 0 4px #ddd;\n  }\n"], ["\n  overflow: hidden;\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  >div {\n    padding: 68px 32px;\n    width: 90%;\n    border: 1px solid #ddd;\n    box-shadow: 0 0 4px #ddd;\n  }\n"])));
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Area, Axis, Chart, Coordinate, Interval, Legend, Line, Point, Tooltip } from "bizcharts";
import { CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
import { ceil, map, maxBy } from "lodash";
export var AxisChart = function (_a) {
    var _b = _a.height, height = _b === void 0 ? 360 : _b, padding = _a.padding, appendPadding = _a.appendPadding, data = _a.data, transpose = _a.transpose, bar = _a.bar, line = _a.line, area = _a.area, point = _a.point, tooltip = _a.tooltip, xScale = _a.xScale, yScale = _a.yScale, colorList = _a.colorList, legend = _a.legend, xAxis = _a.xAxis, yAxis = _a.yAxis, isPolar = _a.isPolar, yAxisTitle = _a.yAxisTitle, anotherAxis = _a.anotherAxis, props = __rest(_a, ["height", "padding", "appendPadding", "data", "transpose", "bar", "line", "area", "point", "tooltip", "xScale", "yScale", "colorList", "legend", "xAxis", "yAxis", "isPolar", "yAxisTitle", "anotherAxis"]);
    var defaultColorList = colorList ? colorList.concat(CHARTS_DEFAULT_COLOR_LIST) : CHARTS_DEFAULT_COLOR_LIST;
    var defaultAxisLabel = {
        autoHide: false,
        autoEllipsis: false
    };
    var initScale = function () {
        var _a;
        var newXAxis = bar ? bar.xAxis : line ? line.xAxis : point ? point.xAxis : area ? area.xAxis : "xAxis";
        var newYAxis = bar ? bar.yAxis : line ? line.yAxis : point ? point.yAxis : area ? area.yAxis : "yAxis";
        var result = (_a = {},
            _a[newXAxis] = __assign({}, xScale),
            _a[newYAxis] = __assign({ nice: true }, yScale),
            _a);
        if (anotherAxis) {
            var maxDataItem = maxBy(data, function (obj) { return obj[newYAxis]; }) || {};
            var maxYAxisData = Math.ceil(maxDataItem[newYAxis]) || 10;
            var yAxisIntFlag = maxDataItem[newYAxis] > 1;
            var yAxisBit = yAxisIntFlag ? -(maxYAxisData.toString().length - 1)
                : (1 - maxDataItem[newYAxis] > 0.99 ? 2 : 1);
            var yAxisMax = yScale && yScale.max ? yScale.max :
                ceil(yAxisIntFlag ? maxYAxisData : maxDataItem[newYAxis], yAxisBit);
            var maxAnotherDataItem = maxBy(data, function (obj) { return obj[anotherAxis.name]; }) || {};
            var anotherIntFlag = maxAnotherDataItem[anotherAxis.name] > 1;
            var maxAnotherAxisData = Math.ceil(maxAnotherDataItem[anotherAxis.name]) || 10;
            var anotherAxisBit = anotherIntFlag ? -(maxAnotherAxisData.toString().length - 1)
                : 1;
            var anotherAxisMax = anotherAxis.max ? anotherAxis.max :
                ceil(anotherIntFlag ? maxAnotherAxisData : maxAnotherDataItem[anotherAxis.name], anotherAxisBit);
            result[newYAxis] = __assign(__assign({}, result[newYAxis]), { min: yScale && yScale.min ? yScale.min : 0, max: yAxisMax, tickInterval: yAxisMax / 4 });
            result[anotherAxis.name] = {
                nice: true,
                min: anotherAxis.min ? anotherAxis.min : 0,
                max: anotherAxisMax,
                tickInterval: anotherAxisMax / 4
            };
        }
        return result;
    };
    var scale = initScale();
    var initData = function (data) {
        if (yAxisTitle && yAxisTitle !== "") {
            return map(data, function (item) {
                if (yAxisTitle && yAxisTitle !== "") {
                    item[yAxisTitle] = item.yAxis;
                }
                return item;
            });
        }
        return data;
    };
    var initColor = function (color, colorIdx) {
        if (colorIdx === void 0) { colorIdx = 0; }
        if (color) {
            if (typeof color === "string") {
                return [color, defaultColorList];
            }
            else {
                return color;
            }
        }
        return defaultColorList[colorIdx];
    };
    var chartData = initData(data || []);
    return (<Chart {...props} padding={padding ? padding : "auto"} appendPadding={appendPadding ? appendPadding : [10, 10, -6, 10]} height={height} autoFit data={chartData} scale={scale}>
      {transpose ? <Coordinate transpose/> : null}
      {isPolar ? <Coordinate type="polar" radius={0.8}/> : null}
      {bar ? <Interval position={bar.xAxis + "*" + (yAxisTitle && yAxisTitle !== "" ? yAxisTitle : bar.yAxis)} color={initColor(bar.color)} size={bar.size} adjust={bar.adjust ? bar.adjust : [{ type: "dodge", dodgeBy: bar.color, marginRatio: 0 }]} label={[bar.yAxis, function (xValue) {
            if (bar.label && bar.label.hideLabel) {
                return null;
            }
            return {
                content: yScale && yScale.formatter ? yScale.formatter(xValue) : xValue,
                style: {
                    fill: bar.label && bar.label.color ? bar.label.color : "#fff"
                },
                position: bar.label && bar.label.position ? bar.label.position : "middle"
            };
        }]}/> : null}
      {line ? <Line {...line} shape={line.shape ? line.shape : "line"} position={line.xAxis + "*" + (yAxisTitle && yAxisTitle !== "" ? yAxisTitle : line.yAxis)} color={initColor(line.color, 1)}/> : null}
      {point ? <Point {...point} position={point.xAxis + "*" + (yAxisTitle && yAxisTitle !== "" ? yAxisTitle : point.yAxis)} color={initColor(point.color, 1)} shape={point.shape ? point.shape : "circle"} opacity={0.7} size={point.size ? [point.size.key, [point.size.min || 8, point.size.max || 16]] : 4}/> : null}
      {area ? <Area {...area} position={area.xAxis + "*" + (yAxisTitle && yAxisTitle !== "" ? yAxisTitle : area.yAxis)} color={initColor(area.color)} label={[area.yAxis, function (xValue) {
            if (!area.label) {
                return null;
            }
            return {
                content: yScale && yScale.formatter ? yScale.formatter(xValue) : xValue,
                style: {
                    fill: area.label && area.label.color ? bar.label.color : "#fff"
                },
                position: area.label && area.label.position ? area.label.position : "middle"
            };
        }]}/> : null}
      {xAxis ?
        <Axis visible={!xAxis.hideAxis} name={xAxis.name} label={xAxis.label ? xAxis.label : defaultAxisLabel}/> : null}
      {yAxis ?
        <Axis {...yAxis} visible={!yAxis.hideAxis} name={yAxis.name}/> : null}
      {anotherAxis ?
        <Axis position={"right"} {...anotherAxis} visible={!anotherAxis.hideAxis} name={anotherAxis.name}/> : null}
      {tooltip ? <Tooltip shared={true}>
        {function (title, items) {
        return tooltip(items, title);
    }}
      </Tooltip> : <Tooltip shared={true}/>}
      {legend ?
        <Legend {...legend} visible={!legend.hideLegend} custom={legend.custom} position={"top"}/> : null}
    </Chart>);
};

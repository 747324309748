var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
import numeral from "numeral";
import { diffMapData } from "@components/map/helpers/initData";
import { filter, find, map, maxBy, meanBy } from "lodash";
var DiffTgiMap = /** @class */ (function (_super) {
    __extends(DiffTgiMap, _super);
    function DiffTgiMap(props) {
        var _this = _super.call(this, props) || this;
        _this.updateInfo = function (name, itemList) {
            _this.setState({
                info: {
                    title: name,
                    list: itemList
                }
            });
        };
        _this.initAMapLoader = function () {
            var data = _this.props ? _this.props.data : null;
            AMapLoader.load({
                "key": "1d7dd286b48f59732bdb724d2c02c186",
                "version": "2.0",
            }).then(function (AMap) {
                var disCountry = new AMap.DistrictLayer.Country({
                    zIndex: 10,
                    depth: 1,
                    styles: {
                        "nation-stroke": "#ff0000",
                        "coastline-stroke": "#0088ff",
                        "province-stroke": "grey",
                        "city-stroke": "rgb(246,166,104)",
                        "fill": function (props) {
                            var colorPList = ["#fdf8f8", "#ffd6d6", "#ff9292", "#ff6767", "#f53838"];
                            var colorPList2 = [
                                "#fdf8f8", "#fde4e4", "#fccbcb", "#fcb0b0",
                                "#fc9393", "#f57a7a", "#f35d5d", "#f64747",
                                "#ff2e2e", "#ff0000",
                            ];
                            var findItem = find(data || diffMapData, function (item) { return item.xAxis === props.NAME_CHN; });
                            var localItemList = filter(data || diffMapData, function (item) { return item.xAxis === props.NAME_CHN; });
                            if (localItemList.length > 1) {
                                var averagePercent = meanBy(localItemList, function (item) { return item.yAxis; });
                                var totalPowPercent = 0;
                                for (var _i = 0, localItemList_1 = localItemList; _i < localItemList_1.length; _i++) {
                                    var item = localItemList_1[_i];
                                    totalPowPercent += Math.pow(item.yAxis - averagePercent, 2);
                                }
                                var std = Math.sqrt(totalPowPercent / localItemList.length);
                                var dft = std / averagePercent;
                                var pColorIdx = Math.min(4, Math.round(dft * 100 / 20));
                                return colorPList[pColorIdx];
                            }
                            else if (findItem && findItem.yAxis) {
                                var maxItem = maxBy(data || diffMapData, function (item) { return item.yAxis; });
                                var pColorIdx = Math.round(findItem.yAxis / maxItem.yAxis * 10) - 1;
                                return colorPList2[pColorIdx];
                            }
                            return "fefefe";
                        }
                    }
                });
                _this.map = new AMap.Map("container1", {
                    zoom: 3.2,
                    zooms: [3.2, 3.2],
                    center: [104.4976, 35.7697],
                    showIndoorMap: false,
                    viewMode: "2D",
                    features: ["bg", "road"],
                    layers: [
                        disCountry
                    ],
                });
                _this.map.on("mousemove", function (ev) {
                    var px = ev.pixel;
                    // 拾取所在位置的行政区
                    var props = disCountry.getDistrictByContainerPos(px);
                    if (props) {
                        var name_1 = props.NAME_CHN;
                        if (name_1) {
                            var localItemList = filter(data || diffMapData, function (item) { return item.xAxis === name_1; });
                            _this.updateInfo(name_1, localItemList);
                            // 重置行政区样式
                            // disCountry.setStyles({
                            //   "fill": (props: any) => {
                            //     return props.NAME_CHN === name ? "F6A668" : "eee";
                            //   }
                            // })
                        }
                    }
                });
            }).catch(function (e) { return null; });
        };
        _this.state = {
            info: null
        };
        return _this;
    }
    DiffTgiMap.prototype.componentDidMount = function () {
        this.initAMapLoader();
    };
    DiffTgiMap.prototype.render = function () {
        var info = this.state.info;
        return (<MapWrapper>
      <Wrapper id="container1"/>
      {info ? <InfoWrapper>
        <InfoTitle>{info.title}</InfoTitle>
        {map(info.list, function (item) { return <div>
          <InfoItemTitle>{item.type}</InfoItemTitle>
          <InfoItem>{l("dashboard_percent", "Percentage")}：<span>
          {numeral(item.yAxis).format("0.0%")}</span>
          </InfoItem>
          <InfoItem>TGI：<span>{numeral(item.yAxis2).format("0,0")}</span></InfoItem>
        </div>; })}
      </InfoWrapper> : null}
    </MapWrapper>);
    };
    return DiffTgiMap;
}(React.Component));
export default DiffTgiMap;
var MapWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  position: relative;\n"], ["\n  width: 100%;\n  height: 100%;\n  position: relative;\n"])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
var InfoWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 10;\n  top: 10px;\n  right: 10px;\n  padding: 10px;\n  border-radius: 4px;\n  background-color: #fff;\n  border: 1px solid #97ddff;\n  box-shadow: 0 0 4px #aaa;\n"], ["\n  position: absolute;\n  z-index: 10;\n  top: 10px;\n  right: 10px;\n  padding: 10px;\n  border-radius: 4px;\n  background-color: #fff;\n  border: 1px solid #97ddff;\n  box-shadow: 0 0 4px #aaa;\n"])));
var InfoTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #555;\n"], ["\n  font-size: 14px;\n  color: #555;\n"])));
var InfoItemTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 10px;\n  font-size: 14px;\n  color: #41beef;\n"], ["\n  margin-top: 10px;\n  font-size: 14px;\n  color: #41beef;\n"])));
var InfoItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 12px;\n  > span {\n    color: #338ab5;\n  }\n"], ["\n  font-size: 12px;\n  > span {\n    color: #338ab5;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

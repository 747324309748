var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import styled from "styled-components";
import * as plane from "@/assets/img/images/plane.png";
import { filter, find, findIndex, map, orderBy, uniqBy } from "lodash";
import { getAreaDataFilter, mapRateData } from "@components/map/helpers/initData";
import numeral from "numeral";
import { l } from "@helpers/multiLangaugeHelper";
import { getMapDataJson } from "@components/map/helpers/async";
var RouteChinaMap = /** @class */ (function (_super) {
    __extends(RouteChinaMap, _super);
    function RouteChinaMap(props) {
        var _this = _super.call(this, props) || this;
        _this.getAreaData = function () { return __awaiter(_this, void 0, void 0, function () {
            var areaData, filterData, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getMapDataJson()];
                    case 1:
                        areaData = _a.sent();
                        filterData = getAreaDataFilter(areaData.data, 2, true);
                        result = map(filterData, function (item) {
                            return {
                                name: item.name,
                                position: [Number(item.longitude), Number(item.latitude)],
                                zooms: [4, 6],
                                opacity: 1,
                                zIndex: 5,
                                text: {
                                    content: item.name,
                                    direction: "right",
                                    style: {
                                        fontSize: 10,
                                        fillColor: "#777",
                                        strokeWidth: 1,
                                        fold: true,
                                        padding: "0, 0",
                                    }
                                }
                            };
                        });
                        this.setState({ LabelsData: result });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.initAMapLoader = function () {
            var data = _this.props ? _this.props.data : null;
            AMapLoader.load({
                "key": "1d7dd286b48f59732bdb724d2c02c186",
                "version": "2.0",
                "AMapUI": {
                    "version": "1.1",
                    "plugins": ["misc/PathSimplifier"],
                },
            }).then(function (AMap) {
                var disCountry = new AMap.DistrictLayer.Province({
                    zIndex: 10,
                    depth: 0,
                    styles: {
                        "nation-stroke": "#ff0000",
                        "coastline-stroke": "#0088ff",
                        "province-stroke": "grey",
                        "city-stroke": "rgb(246,166,104)",
                        "fill": function (props) {
                            return "eee";
                        }
                    }
                });
                var map = new AMap.Map("container", {
                    zooms: [4, 6],
                    zoom: 3.5,
                    center: [106.122082, 33.719192],
                    showIndoorMap: false,
                    // isHotspot:false,
                    viewMode: "3D",
                    layers: [
                        disCountry
                    ],
                });
                AMapUI.load(["ui/misc/PathSimplifier"], function (PathSimplifier) {
                    if (!PathSimplifier.supportCanvas) {
                        alert("当前环境不支持 Canvas！");
                        return;
                    }
                    var pathSimplifierIns = new PathSimplifier({
                        zIndex: 10,
                        map: map,
                        getPath: function (pathData, pathIndex) {
                            return pathData.path;
                        },
                        getHoverTitle: function (pathData, pathIndex, pointIndex) {
                            return pathData.from + " \u2014\u2014> " + pathData.to + " \u8FC1\u5F99\u7387: " + numeral(pathData.toPercent).format("0.0%");
                        },
                        renderOptions: {
                            renderAllPointsIfNumberBelow: -1,
                            // pathLineSelectedStyle:{
                            //   lineWidth: 1,
                            //   borderWidth:1,
                            // },
                            pathLineHoverStyle: {
                                lineWidth: 2,
                                borderWidth: 1,
                            },
                            pathLineStyle: {
                                lineWidth: 1,
                                borderWidth: 1,
                            }
                        }
                    });
                    var pathData = _this.getPathData(data || mapRateData);
                    // 设置数据
                    pathSimplifierIns.setData(pathData);
                    for (var i = 0; i < pathData.length; i++) {
                        var item = pathData[i];
                        var path = item.path;
                        var lineLength = Math.sqrt(Math.pow((path[0][0] - path[path.length - 1][0]), 2) +
                            Math.pow((path[0][1] - path[path.length - 1][1]), 2));
                        // 对第一条线路（即索引 0）创建一个巡航器
                        var navg1 = pathSimplifierIns.createPathNavigator(i, {
                            loop: true,
                            speed: lineLength < 1.5 ? 100000 : 1000000,
                            pathNavigatorStyle: {
                                width: 20,
                                height: 20,
                                lineWidth: 1,
                                // 使用图片
                                content: PathSimplifier.Render.Canvas.getImageContent(plane, onload, onerror),
                                strokeStyle: null,
                                fillStyle: null,
                                // 经过路径的样式
                                pathLinePassedStyle: {
                                    lineWidth: 1,
                                    strokeStyle: "#00a5ff",
                                    dirArrowStyle: {
                                        stepSpace: 30,
                                        strokeStyle: "red"
                                    }
                                }
                            }
                        });
                        navg1.start();
                    }
                });
                var layer = new AMap.LabelsLayer({
                    zooms: [4, 6],
                    zIndex: 100,
                    collision: false,
                    animation: true,
                });
                var markers = [];
                var _loop_1 = function (item) {
                    var idx = findIndex(data || mapRateData, function (mapItem) { return mapItem.from === item.name; });
                    if (idx > -1) {
                        var labelsMarker = new AMap.LabelMarker(item);
                        labelsMarker.on("mousemove", function (ev) {
                            _this.updateInfo(ev.data.data.name);
                        });
                        markers.push(labelsMarker);
                    }
                };
                for (var _i = 0, _a = _this.state.LabelsData; _i < _a.length; _i++) {
                    var item = _a[_i];
                    _loop_1(item);
                }
                layer.add(markers);
                map.add(layer);
            }).catch(function (e) { return null; });
        };
        _this.getPointData = function (areaName) {
            var obj = find(_this.state.LabelsData, function (item) { return item.name === areaName; });
            return obj ? obj.position : null;
        };
        _this.getInitPointData = function (pointA, pointB) {
            if (!pointA || !pointB)
                return null;
            var distanceX = Math.abs(pointA[0] - pointB[0]);
            var distanceY = Math.abs(pointA[1] - pointB[1]);
            var directUp = pointA[1] < pointB[1];
            var directRight = pointA[0] < pointB[0];
            var xAdd = distanceX / 4;
            var yAdd = distanceY / 4;
            var pointList = [];
            for (var i = 0; i < 4; i++) {
                var rate = i === 1 || i === 3 ? 3 : 2.5;
                var x = directRight ? (pointA[0] + (i * xAdd)) : (pointA[0] - (i * xAdd));
                var y = directUp ? (pointA[1] + (i * yAdd) + (i === 0 ? 0 : (yAdd / rate))) :
                    (pointA[1] - (i * yAdd) - (i === 0 ? 0 : (yAdd / rate)));
                pointList.push([x, y]);
            }
            pointList.push(pointB);
            return pointList;
        };
        _this.getPathData = function (data) {
            return map(data, function (item) {
                var pathData = _this.getInitPointData(_this.getPointData(item.from), _this.getPointData(item.to));
                return __assign(__assign({}, item), { path: pathData });
            });
        };
        _this.updateInfo = function (name) {
            var data = _this.props ? _this.props.data : null;
            var uniqueList = uniqBy(data || mapRateData, "from");
            var range = findIndex(orderBy(uniqueList, ["fromPercent"], ["desc"]), function (item) { return item.from === name; });
            var infoList = filter(data || mapRateData, function (item) { return item.from === name; });
            var info = {
                from: name,
                fromPercent: infoList && infoList.length > 0 ? infoList[0].fromPercent : "",
                fromTgi: infoList && infoList.length > 0 ? infoList[0].fromTgi : "",
                range: range + 1,
                toList: infoList && infoList.length > 0 ? infoList : []
            };
            _this.setState({ info: info });
        };
        _this.state = {
            LabelsData: {},
            info: {}
        };
        return _this;
    }
    RouteChinaMap.prototype.componentDidMount = function () {
        var _this = this;
        this.getAreaData()
            .then(function (res) {
            _this.initAMapLoader();
        });
    };
    RouteChinaMap.prototype.render = function () {
        var info = this.state.info;
        return (<MapWrapper>
      <Wrapper id="container"/>
      <InfoWrapper>
        <InfoItem>{info.from}</InfoItem>
        <InfoItem>{l("map_migration_rate", "迁出率")}：<span>{numeral(info.fromPercent).format("0.0%")}</span></InfoItem>
        <InfoItem>TGI：<span>{numeral(info.fromTgi).format("0,0")}</span></InfoItem>
        <InfoItem>{l("map_migration_range", "排序")}：<span>{info.range}</span></InfoItem>
        <InfoItem>{l("map_migration_top_3", "前三位目的地")}</InfoItem>
        {map(info.toList, function (item) {
            return <InfoItem>{item.to}：
            <span>{numeral(item.toPercent).format("0.0%")}</span> -- TGI：
            {numeral(item.toTgi).format("0,0")}
          </InfoItem>;
        })}
      </InfoWrapper>
    </MapWrapper>);
    };
    return RouteChinaMap;
}(React.Component));
export default RouteChinaMap;
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
var MapWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  position: relative;\n"], ["\n  width: 100%;\n  height: 100%;\n  position: relative;\n"])));
var InfoWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 10;\n  top: 10px;\n  right: 10px;\n  padding: 10px;\n  border-radius: 4px;\n  background-color: #fff;\n  border: 1px solid #97ddff;\n  box-shadow: 0 0 4px #aaa;\n"], ["\n  position: absolute;\n  z-index: 10;\n  top: 10px;\n  right: 10px;\n  padding: 10px;\n  border-radius: 4px;\n  background-color: #fff;\n  border: 1px solid #97ddff;\n  box-shadow: 0 0 4px #aaa;\n"])));
var InfoItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 12px;\n  > span {\n    color: #338ab5;\n  }\n"], ["\n  font-size: 12px;\n  > span {\n    color: #338ab5;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

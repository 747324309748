var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { SourceItem } from "../components/sourceItem";
import { StoreContext } from "../store/storeContext";
import { Modal, Tabs, Tooltip } from "antd";
import { l } from "@helpers/multiLangaugeHelper";
import { BorderVerticleOutlined } from "@/utils/antdIcon";
import { MyTree } from "@components/baseComponents/myTree";
var TabPane = Tabs.TabPane;
export var ConditionList = function (_a) {
    var isTrackingAudience = _a.isTrackingAudience;
    var _b = useContext(StoreContext), tagData = _b.tagData, calcConditions = _b.calcConditions, setModalOption = _b.setModalOption, setModalData = _b.setModalData, readonly = _b.readonly;
    var openCondition = function (e, dropRes) {
        if (!dropRes) {
            return;
        }
        if (calcConditions[dropRes.params.idx].conditions.length === 10) {
            Modal.info({ content: l("custom_audience_cal_item_max_10_tips", "最多添加10个运算条件") });
            return;
        }
        setModalData({});
        setModalOption({
            calcIdx: dropRes.params.idx,
            type: e.name,
            params: e.params,
            title: e.params.label,
            tagId: e.params.value
        });
    };
    var renderItem = function (nodeData) {
        if (nodeData.children && nodeData.children.length > 0) {
            return <div>{nodeData.title}</div>;
        }
        return (<ChildItem>
        <Tooltip title={nodeData.label}>
          <ConditionsSource disabled={readonly} key={nodeData.value} name={nodeData.type} type={"condition"} params={nodeData} onEnd={function (e, dropRes) { return openCondition(e, dropRes); }}>
            <ChildContent>{nodeData.title}</ChildContent>
          </ConditionsSource>
        </Tooltip>
        <DragIcon />
      </ChildItem>);
    };
    return (<Wrapper>
      <TabsWrapper defaultActiveKey="1" centered>
        <TabPane tab={l("common_tag", "标签")} key="1">
          <MyTree style={{ height: "462px" }} data={tagData.tagList || []} renderNode={renderItem}/>
        </TabPane>
        <TabPane tab={isTrackingAudience ? l("translator_audience_audience", "监测人群") : l("common_segment_audience", "人群")} key="2">
          <MyTree style={{ height: "462px" }} data={tagData.segmentList || []} renderNode={renderItem}/>
        </TabPane>
      </TabsWrapper>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  overflow: auto;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  overflow: auto;\n"])));
var TabsWrapper = styled(Tabs)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  .ant-tabs-nav-list {\n    width: 100%;\n  }\n  .ant-tabs-tab {\n    padding: 4px 0;\n    display: inline-block;\n    width: 50%;\n    text-align: center;\n  }\n  .ant-tabs-tab + .ant-tabs-tab {\n    margin: 0;\n  }\n  .ant-tree-indent {\n    display: none;\n  }\n  .ant-tree-switcher.ant-tree-switcher-noop {\n    display: none;\n  }\n"], ["\n  flex: 1;\n  .ant-tabs-nav-list {\n    width: 100%;\n  }\n  .ant-tabs-tab {\n    padding: 4px 0;\n    display: inline-block;\n    width: 50%;\n    text-align: center;\n  }\n  .ant-tabs-tab + .ant-tabs-tab {\n    margin: 0;\n  }\n  .ant-tree-indent {\n    display: none;\n  }\n  .ant-tree-switcher.ant-tree-switcher-noop {\n    display: none;\n  }\n"])));
var ConditionsSource = styled(SourceItem)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 24px;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  width: auto;\n  height: auto;\n  padding: 2px 0;\n  background: transparent;\n  border: none;\n  cursor: ", ";;\n  filter:", ";\n  color: #95aac9;\n"], ["\n  margin-left: 24px;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  width: auto;\n  height: auto;\n  padding: 2px 0;\n  background: transparent;\n  border: none;\n  cursor: ", ";;\n  filter:", ";\n  color: #95aac9;\n"])), function (props) { return props.disabled ? "not-allowed" : "grab"; }, function (props) { return props.disabled ? "grayscale(100%)" : "none"; });
var ChildItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 190px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 190px;\n"])));
var ChildContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding:2px 10px;\n  width: auto;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"], ["\n  padding:2px 10px;\n  width: auto;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])));
var DragIcon = styled(BorderVerticleOutlined)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #95aac9;\n"], ["\n  color: #95aac9;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

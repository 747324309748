var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Spin } from "antd";
import { getImgUrl } from "@helpers/const";
import styled from "styled-components";
export var MySpin = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var antIcon = <MySpinImg alt="" src={getImgUrl("loading_icon.gif")}/>;
    return (<Spin {...props} indicator={antIcon}>
    {children}
  </Spin>);
};
var MySpinImg = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: auto;\n  transform: translateX(-50%);\n  display: block;\n  opacity: 0.3;\n  -webkit-user-drag: none;\n"], ["\n  margin: auto;\n  transform: translateX(-50%);\n  display: block;\n  opacity: 0.3;\n  -webkit-user-drag: none;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useRef } from "react";
import { PointChart } from "@components/charts/pointChart/pointChart";
import { CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
import { uniqBy } from "lodash";
import numeral from "numeral";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
var renderCount = 0;
export var TgiPointChart = function (_a) {
    var data = _a.data, height = _a.height, point = _a.point;
    var charRef = useRef(null);
    var getHasType = function () {
        var typeLength = uniqBy(data, "type").length;
        return typeLength > 1;
    };
    var hasType = getHasType();
    var color = [point.xAxis + "*" + point.yAxis + "*" + point.size.key, function (xAxis, yAxis, size) {
            return yAxis > 100 ? CHARTS_DEFAULT_COLOR_LIST[1] : CHARTS_DEFAULT_COLOR_LIST[0];
        }];
    return (<Wrapper ref={charRef}>
      <PointChart height={height} data={data} appendPadding={[10, 10, 30, 10]} onGetG2Instance={function (c) {
        // console.log(c)
        renderCount++;
        c.render(true);
        c.on("afterrender", function () {
            if (renderCount < 2) {
                renderCount++;
                setTimeout(function () { return c.render(true); }, 100);
            }
        });
    }} xAxis={{ name: point.xAxis, hideAxis: true, label: { style: { fonsSize: 0 } } }} line={__assign(__assign({}, point), { size: 0 })} point={__assign(__assign({}, point), { yAxisTitle: "TGI", format: function (v) { return "" + numeral(v).format("0,0"); }, size: {
            key: point.size.key,
            title: l("dashboard_percent", "Percentage"),
            format: function (v) { return numeral(v * 100).format("0.0") + "%"; }
        }, color: hasType ? "type" : color, label: [point.xAxis, { offsetY: 36, style: { fontSize: 10, fill: "#aaa" } }] })} legend={{ custom: true, hideLegend: true, position: "top" }}/>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { MyEcharts } from "@components/echarts/myEcharts";
import { getImgUrl } from "@helpers/const";
import numeral from "numeral";
import { MAIN_COLOR } from "@/common/styled";
export var RingChart = function (_a) {
    var data = _a.data;
    if (!(data && data[0])) {
        return null;
    }
    var initOption = {
        series: [
            {
                type: "pie",
                radius: ["60%", "70%"],
                label: {
                    show: false,
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: data[0].yAxis, itemStyle: { color: MAIN_COLOR } },
                    { value: 1 - data[0].yAxis, itemStyle: { color: "#ddd" } }
                ]
            }
        ]
    };
    return (<Wrapper>
    <div className={"charts"}>
      <MyEcharts option={initOption}/>
      <img alt={""} src={getImgUrl("profile/" + data[0].type + ".png")}/>
    </div>
    <div className={"text"}>
      <div>{data[0].xAxis}</div>
      <NumberText>{numeral(data[0].yAxis).format("0.00%")}</NumberText>
      <div>TGI:{numeral(data[0].yAxis2).format("0,0")}</div>
    </div>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  > div {\n    width: 50%;\n    &:nth-child(1){\n      margin-right: 20px;\n    }\n  }\n  .text {\n    flex: 1;\n    padding: 14px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    > div {\n      color: #999;\n      font-size: 18px;\n    }\n  }\n  .charts {\n    position: relative;\n    > img {\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%,-50%);\n      width: 40%;\n    }\n  }\n"], ["\n  overflow: hidden;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  > div {\n    width: 50%;\n    &:nth-child(1){\n      margin-right: 20px;\n    }\n  }\n  .text {\n    flex: 1;\n    padding: 14px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    > div {\n      color: #999;\n      font-size: 18px;\n    }\n  }\n  .charts {\n    position: relative;\n    > img {\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%,-50%);\n      width: 40%;\n    }\n  }\n"])));
var NumberText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 24px 0;\n  font-size: 24px !important;\n  color: #000 !important;\n"], ["\n  margin: 24px 0;\n  font-size: 24px !important;\n  color: #000 !important;\n"])));
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled from "styled-components";
import { MyEcharts } from "@components/echarts/myEcharts";
import { CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
import numeral from "numeral";
import { l } from "@helpers/multiLangaugeHelper";
export var NightingaleChart = function (_a) {
    var data = _a.data, height = _a.height, props = __rest(_a, ["data", "height"]);
    var getOptions = function (data) {
        var initData = function (data) { return (data.map(function (item) { return ({
            name: item.xAxis,
            value: item.yAxis,
            tgi: item.yAxis2
        }); })); };
        // const sum = sumBy(data, (item: any) => item.yAxis)
        var result = {
            legend: {
                top: "bottom"
            },
            color: CHARTS_DEFAULT_COLOR_LIST,
            tooltip: {
                trigger: "item",
                textStyle: {
                    fontSize: 12,
                },
                formatter: function (params) {
                    return "<div>\n            <div>" + params.name + "</div>\n            <div>" + l("dashboard_percent", "Percentage") + ": " + numeral(params.value).format("0.00%") + "</div>\n            <div>TGI: " + numeral(params.data.tgi).format("0,0") + "</div>\n          </div>\n          ";
                }
            },
            series: [
                {
                    type: "pie",
                    radius: [30, 100],
                    center: ["50%", "45%"],
                    roseType: "area",
                    itemStyle: {
                        borderRadius: 8
                    },
                    label: {
                        formatter: "{name|{b}}\n{percent|{d}%}",
                        rich: {
                            name: {
                                fontSize: 12,
                            },
                            percent: {
                                fontSize: 10,
                                color: "#999"
                            }
                        }
                    },
                    labelLine: {
                        length: 10,
                        length2: 10,
                    },
                    data: initData(data)
                }
            ]
        };
        return result;
    };
    return (<Wrapper>
    <MyEcharts style={{ height: height }} option={getOptions(data)}/>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n"], ["\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n"])));
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Table, Transfer } from "antd";
import { difference, map } from "lodash";
import { getOptions } from "@helpers/async";
import { msgError } from "@helpers/axiosHelper";
export var MyTableTransfer = function (_a) {
    var columns = _a.columns, rowKey = _a.rowKey, targetKeys = _a.targetKeys, url = _a.url, params = _a.params, dataSource = _a.dataSource, restProps = __rest(_a, ["columns", "rowKey", "targetKeys", "url", "params", "dataSource"]);
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    useEffect(function () {
        if (dataSource) {
            setOptions(dataSource);
        }
    }, [JSON.stringify(dataSource)]);
    useEffect(function () {
        if (url) {
            getDataSource();
        }
    }, [JSON.stringify(params)]);
    var initColumns = function (columns) {
        return map(columns, function (item) {
            item.dataIndex = item.key;
            return item;
        });
    };
    var initSourceData = function (options) {
        return map(options, function (item) {
            var key = typeof rowKey === "function" ? rowKey(item) : item[rowKey];
            item.key = key;
            return item;
        });
    };
    var getDataSource = function () {
        if (url) {
            getOptions(url, __assign(__assign({}, params), { value: targetKeys }))
                .then(function (res) {
                setOptions(res.data);
            })
                .catch(msgError);
        }
    };
    return (<Transfer targetKeys={targetKeys} {...restProps} dataSource={initSourceData(options)} showSelectAll={false}>
    {function (_a) {
        var direction = _a.direction, filteredItems = _a.filteredItems, onItemSelectAll = _a.onItemSelectAll, onItemSelect = _a.onItemSelect, listSelectedKeys = _a.selectedKeys, listDisabled = _a.disabled;
        var rowSelection = {
            getCheckboxProps: function (item) { return ({ disabled: listDisabled || item.disabled }); },
            onSelectAll: function (selected, selectedRows) {
                var treeSelectedKeys = selectedRows
                    .filter(function (item) { return !item.disabled; })
                    .map(function (_a) {
                    var key = _a.key;
                    return key;
                });
                var diffKeys = selected
                    ? difference(treeSelectedKeys, listSelectedKeys)
                    : difference(listSelectedKeys, treeSelectedKeys);
                onItemSelectAll(diffKeys, selected);
            },
            onSelect: function (_a, selected) {
                var key = _a.key;
                // const key = typeof rowKey === "function" ? rowKey(item) : item[rowKey]
                onItemSelect(key, selected);
            },
            selectedRowKeys: listSelectedKeys,
        };
        return (<Table rowSelection={rowSelection} columns={initColumns(columns)} dataSource={filteredItems} size="small" 
        // style={{pointerEvents: listDisabled ? "none" : null}}
        onRow={function (_a) {
            var key = _a.key, itemDisabled = _a.disabled;
            return ({
                onClick: function () {
                    if (itemDisabled || listDisabled)
                        return;
                    onItemSelect(key, !listSelectedKeys.includes(key));
                },
            });
        }}/>);
    }}
  </Transfer>);
};

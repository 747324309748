var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Axis, Chart, Interval, Legend, Tooltip } from "bizcharts";
import { map } from "lodash";
import numeral from "numeral";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
var DEFAULT_COLOR = "#cbcbcb";
export var MigrationBarChart = function (_a) {
    var data = _a.data, _b = _a.height, height = _b === void 0 ? 360 : _b;
    var renderTooltip = function (items, title) {
        return <TooltipWrapper>
      <Title>{numeral(items[0].value).format("0.0%")}</Title>
      <Title>{l("migrationBarChart_tooltip_title", "top3 目的地")}</Title>
      {map(items, function (item, idx) {
            if (idx === 0) {
                return null;
            }
            return (<TooltipContent key={item.title + "-" + item.name + "-" + item.value}>
              <div>
                <ItemTooltipsCircle color={item.color}/>
                {item.name}:
                {numeral(item.data.yAxis2).format("0.0%")}
              </div>
            </TooltipContent>);
        })}
    </TooltipWrapper>;
    };
    var scale = {
        yAxis: {
            min: 0,
            max: 1,
            sync: "value",
            tickInterval: 0.25
        },
        showYAxis2: {
            min: 0,
            max: 1,
            sync: "value",
            tickInterval: 0.25
        },
    };
    var initData = function (data) {
        return map(data, function (item) {
            item.showYAxis2 = Number((item.yAxis * item.yAxis2).toFixed(2));
            return item;
        });
    };
    return (<Chart height={height} padding="auto" appendPadding={[10, 10, -6, 10]} data={initData(data)} autoFit scale={scale}>
      <Interval color={DEFAULT_COLOR} position="xAxis*yAxis" size={20}/>
      <Interval adjust={[
        {
            type: "stack",
        },
    ]} color="type" size={20} position="xAxis*showYAxis2"/>
      <Axis name="yAxis" label={{
        formatter: function (text, item, index) {
            return "" + numeral(text).format("0.0%");
        }
    }}/>
      <Axis name="showYAxis2" visible={false}/>
      <Tooltip shared={true}>
        {function (title, items) {
        return renderTooltip(items, title);
    }}
      </Tooltip>
      <Legend position={"top"}/>
    </Chart>);
};
var TooltipWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px 0;\n"], ["\n  padding: 10px 0;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 10px 0;\n"], ["\n  margin: 10px 0;\n"])));
var ItemTooltipsCircle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"], ["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"])), function (props) { return props.color; });
var TooltipContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"], ["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

export var STATUS_ID_ENUM;
(function (STATUS_ID_ENUM) {
    STATUS_ID_ENUM[STATUS_ID_ENUM["CANCELED"] = -2] = "CANCELED";
    STATUS_ID_ENUM[STATUS_ID_ENUM["FAILED"] = -1] = "FAILED";
    STATUS_ID_ENUM[STATUS_ID_ENUM["DRAFT"] = 0] = "DRAFT";
    STATUS_ID_ENUM[STATUS_ID_ENUM["COMPLETED"] = 1] = "COMPLETED";
    STATUS_ID_ENUM[STATUS_ID_ENUM["PROCESSING"] = 2] = "PROCESSING";
    STATUS_ID_ENUM[STATUS_ID_ENUM["SUBMITTED"] = 3] = "SUBMITTED";
    STATUS_ID_ENUM[STATUS_ID_ENUM["REJECT"] = 4] = "REJECT";
    STATUS_ID_ENUM[STATUS_ID_ENUM["QUEUING"] = 5] = "QUEUING";
})(STATUS_ID_ENUM || (STATUS_ID_ENUM = {}));
export var ACTION_CODE_ENUM;
(function (ACTION_CODE_ENUM) {
    ACTION_CODE_ENUM["SUBMIT"] = "actionSubmit";
    ACTION_CODE_ENUM["APPROVE"] = "actionApprove";
    ACTION_CODE_ENUM["REJECT"] = "actionReject";
    ACTION_CODE_ENUM["CANCEL"] = "actionCancel";
    ACTION_CODE_ENUM["RECEIVE"] = "actionReceive";
    ACTION_CODE_ENUM["RECEIVE_APPROVE"] = "actionReceiveApprove";
    ACTION_CODE_ENUM["UPLOAD_RESULT"] = "actionUploadResult";
    ACTION_CODE_ENUM["FINISH"] = "actionFinish";
    ACTION_CODE_ENUM["EDIT"] = "actionEdit";
    ACTION_CODE_ENUM["WITHDRAW"] = "actionWithDraw";
    ACTION_CODE_ENUM["RERUN"] = "actionRerun";
})(ACTION_CODE_ENUM || (ACTION_CODE_ENUM = {}));
export var ACTION_APPLY_ENUM;
(function (ACTION_APPLY_ENUM) {
    ACTION_APPLY_ENUM["MPROFILE"] = "actionMprofile";
    ACTION_APPLY_ENUM["MHPROFILE"] = "actionMhprofile";
    ACTION_APPLY_ENUM["SPROFILE"] = "actionSprofile";
    ACTION_APPLY_ENUM["ACTIVATION"] = "actionActivation";
    ACTION_APPLY_ENUM["CLUSTERING"] = "actionClustering";
    ACTION_APPLY_ENUM["LOOKALIKE"] = "actionLal";
    ACTION_APPLY_ENUM["CORRELATION"] = "actionCorrelation";
})(ACTION_APPLY_ENUM || (ACTION_APPLY_ENUM = {}));
export var COMMON_OPTIONS_TYPE = {
    ACTIVE_STATUS: "common_request.active_status",
    ACCOUNT_EMAIL_DOMAIN: "account_request.email_domain",
    ACCOUNT_USER_ROLE: "account_request.user_role",
    ACCOUNT_REGISTER_AGENCY: "account_request.YourAgency",
    ACCOUNT_DEPARTMENT: "account.Department",
    ACCOUNT_FUNCTION: "account.Function",
    MODELING_CUSTOM_MODELING: "modeling.custom_modeling",
    PROFILING_STATUS: "profiling.status",
    AUDIENCE_FREQUENCY_TYPE: "audience.frequencyType",
    AUDIENCE_FREQUENCY_BASE: "audience.frequencyBase",
    AUDIENCE_LOCATION_RADIUS: "audience.location_radius",
    AUDIENCE_PERIOD: "audience.period",
    AUDIENCE_PERIOD_V2: "audience.period.v2",
    AUDIENCE_LOCATION_GROUP: "audience.group",
    AUDIENCE_LOCATION_GROUP_V2: "audience.group.v2",
    MODELING_LOOKALIKE_INCLUDE_TYPE: "lal.Seed_Flag",
    MARKET_OVERVIEW_LOCATION_SELECT_TYPE: "marketOverview.location_type",
    MODELING_CLUSTERING_FEATURE_TAGS_TYPE: "clustering.tag_feature",
    INDUSTRY_COMMON_INDUSTRY: "industry.industry_options",
    AUDIENCE_PERIOD_TYPE: "audience.periodType",
    ACTIVATION_CAMPAIGN_STATUS_TYPE: "activation.campaign_status_options",
    PLATFORM_ACCOUNT_MAPPING_STATUS_TYPE: "platformAccount.mapping_status_options"
};
export var STATUS_OPTIONS_TYPE;
(function (STATUS_OPTIONS_TYPE) {
    STATUS_OPTIONS_TYPE["MODELING_CLUSTER"] = "modeling.clustering";
    STATUS_OPTIONS_TYPE["MODELING_CUSTOM_MODELING"] = "modeling.custom_modeling";
    STATUS_OPTIONS_TYPE["MODELING_LOOKALIKE"] = "modeling.lookalike";
    STATUS_OPTIONS_TYPE["AUDIENCE_STANDARD_AUDIENCE"] = "audience.standard_audience";
    STATUS_OPTIONS_TYPE["AUDIENCE_APP_AUDIENCE"] = "audience.app_audience";
    STATUS_OPTIONS_TYPE["AUDIENCE_OTV_AUDIENCE"] = "audience.otv_audience";
    STATUS_OPTIONS_TYPE["AUDIENCE_LOCATION_AUDIENCE"] = "audience.location_audience";
    STATUS_OPTIONS_TYPE["AUDIENCE_TRACKING_AUDIENCE"] = "audience.tracking_audience";
    STATUS_OPTIONS_TYPE["AUDIENCE_TRANSLATOR_AUDIENCE"] = "audience.translator_audience";
    STATUS_OPTIONS_TYPE["PROFILING_M_PROFILE"] = "profiling.m_profile";
    STATUS_OPTIONS_TYPE["PROFILING_MH_PROFILE"] = "profiling.mh_profile";
    STATUS_OPTIONS_TYPE["PROFILING_S_PROFILE"] = "profiling.s_profile";
    STATUS_OPTIONS_TYPE["ACTIVATION"] = "activation";
    STATUS_OPTIONS_TYPE["TRACKING_ADVERTISING_TRACKING"] = "tracking.code";
    STATUS_OPTIONS_TYPE["CORRELATION_ANALYSIS"] = "modeling.correlation";
    STATUS_OPTIONS_TYPE["ONE_PD_UPLOAD"] = "1pd-upload";
    STATUS_OPTIONS_TYPE["DOOH_PLANNING"] = "dooh_planning";
    STATUS_OPTIONS_TYPE["DTREE"] = "dtree";
    STATUS_OPTIONS_TYPE["COPILOT_PRE_CAMPAIGN_TASK"] = "copilot.pre_campaign";
    STATUS_OPTIONS_TYPE["COPILOT_IN_FLIGHT_TASK"] = "copilot.in_flight";
})(STATUS_OPTIONS_TYPE || (STATUS_OPTIONS_TYPE = {}));
export var DEFAULT_MAX_DOWNLOAD_SIZE = 1000000;

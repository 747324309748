var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store/storeContext";
import { l } from "@helpers/multiLangaugeHelper";
import { CONDITION_ENUM } from "../../helpers/initData";
import { merge, set } from "lodash";
import { MyInput } from "@components/baseComponents/myInput";
import { msgError } from "@helpers/axiosHelper";
import { getDefaultTagOptions } from "../../helpers/async";
import { MyTreeTransfer } from "@components/myTransfer/myTreeTransfer";
import styled from "styled-components";
import { WARING_COLOR } from "@/common/styled";
import { MyModal } from "@components/baseComponents/myModal";
export var DefaultCondition = function (_a) {
    var readonly = _a.readonly;
    var _b = useContext(StoreContext), data = _b.data, modalOption = _b.modalOption, setModalOption = _b.setModalOption, modalData = _b.modalData, setModalData = _b.setModalData, saveModalData = _b.saveModalData;
    var _c = useState([]), transferSourceData = _c[0], setTransferSourceData = _c[1];
    var _d = useState(""), defaultDesc = _d[0], setDefaultDesc = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    useEffect(function () {
        if (modalOption.tagId) {
            setTransferSourceData([]);
            setDefaultDesc("");
            setLoading(true);
            var postData = {
                advertiserId: data.advertiserId,
                sourceId: data.sourceId,
                tagId: modalOption.tagId,
                taskId: data.taskId
            };
            getDefaultTagOptions(postData)
                .then(function (res) {
                setTransferSourceData(res.data || []);
                setLoading(false);
            })
                .catch(msgError);
        }
    }, [modalOption.type]);
    var onFormDataChange = function (value, pathKey) {
        var newData = merge({}, modalData);
        set(newData, pathKey, value);
        if (pathKey === "itemList") {
            var desc = getDescLabel(value);
            setDefaultDesc(desc);
        }
        if (pathKey === "desc" && value === "") {
            if (newData.itemList && newData.itemList.length > 0) {
                var desc = getDescLabel(newData.itemList);
                setDefaultDesc(desc);
            }
        }
        setModalData(newData);
    };
    var save = function () {
        var newData = merge({}, modalData);
        if (!newData.desc || newData.desc === "") {
            var desc = getDescLabel(modalData.itemList);
            set(newData, "desc", desc);
        }
        saveModalData(newData, modalOption);
        setModalOption({});
    };
    var getDescLabel = function (itemList) {
        var showTagSize = 4;
        if (!itemList && itemList.length === 0) {
            return "";
        }
        var result = "";
        var labels = [];
        var getListLabel = function (data, value, parentTitle) {
            data.forEach((function (item) {
                if (item.value === value) {
                    labels.push(parentTitle ? parentTitle + "-" + item.label : item.label);
                }
                else if (item.children && item.children.length > 0) {
                    getListLabel(item.children, value, item.label);
                }
            }));
        };
        itemList.forEach(function (item) {
            getListLabel(transferSourceData, item);
        });
        var labelLength = labels.length;
        labels.splice(showTagSize, labelLength - 1);
        labels.forEach(function (label, idx) {
            result += "" + (idx === 0 ? "" : ", ") + label;
        });
        if (labelLength > showTagSize) {
            result += " " + l("custom_audience_condition_desc_and_so_on", "等") +
                ("" + labelLength + l("common_unit_a", "个")) +
                ("" + l("common_tag", "标签"));
        }
        return result;
    };
    var saveCanCheck = function () {
        return modalData.itemList && modalData.itemList.length > 0 && !readonly;
    };
    return (<MyModal width={1000} title={modalOption.title} visible={modalOption.type === CONDITION_ENUM.DEFAULT || modalOption.type === "audience"} onCancel={function () { return setModalOption({}); }} maskClosable={false} onOk={save} okButtonProps={{ disabled: !saveCanCheck() }}>
      <StoreContext.Consumer>
        {function (_a) {
        var modalOption = _a.modalOption;
        return modalOption.type === CONDITION_ENUM.DEFAULT || modalOption.type === "audience" ? (<>
          <MyInput style={{ marginBottom: "10px", width: "100%" }} placeholder={l("custom_audience_condition_desc", "内容描述（没有填写时默认按下列文字生成）")} value={modalData.desc} onChange={function (v) { return onFormDataChange(v.target.value, "desc"); }} disabled={readonly}/>
          {modalData.desc ? null : <Tip>{l("custom_audience_condition_default_desc", "默认内容描述")}:
            <span>{defaultDesc}</span>
          </Tip>}
          <MyTreeTransfer showSelectAll={true} loading={loading} readonly={readonly} rowKey={function (item) { return item.value; }} listStyle={{
            width: 460,
            height: 300,
        }} dataSource={transferSourceData} targetKeys={modalData.itemList || []} onChange={function (v) { return onFormDataChange(v, "itemList"); }}/>
        </>) : null;
    }}
      </StoreContext.Consumer>
    </MyModal>);
};
var Tip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  font-size: 12px;\n  color: #95aac9;\n  > span {\n    margin-left: 6px;\n    color: ", ";\n  }\n"], ["\n  margin-bottom: 10px;\n  font-size: 12px;\n  color: #95aac9;\n  > span {\n    margin-left: 6px;\n    color: ", ";\n  }\n"])), WARING_COLOR);
var templateObject_1;

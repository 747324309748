var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import styled from "styled-components";
import { find, findIndex, map, merge, set } from "lodash";
import { Checkbox, Form, Slider } from "antd";
import { msgError } from "@helpers/axiosHelper";
import { getMapAddress, getPointMarkersData } from "@pages/doohPlaninng/helpers/async";
import { getImgUrl, getItemLayout, getRelativeImgUrl } from "@helpers/const";
import { l } from "@helpers/multiLangaugeHelper";
import { MySelect } from "@components/baseComponents/mySelect";
import { MapRadar } from "@pages/doohPlaninng/components/mapRadar";
import numeral from "numeral";
var FormItem = Form.Item;
var itemLayout = getItemLayout(5, 18);
var slideTimer;
var circle;
var layer = {};
var boardLayer = {};
var shopMarkers = {};
var boardMarkers = {};
var MapComponent = /** @class */ (function (_super) {
    __extends(MapComponent, _super);
    function MapComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.initConfig = function () {
            var config = _this.props.config;
            var markerList = config.markerList;
            var newControlData = {};
            map(markerList, function (item) {
                newControlData[item.value] = true;
                layer[item.value] = null;
                shopMarkers[item.value] = [];
                boardLayer[item.value] = null;
                boardMarkers[item.value] = [];
            });
            _this.setState({ controlData: newControlData }, function () { return _this.initAMapLoader(); });
        };
        _this.getAddress = function (cityId) {
            var config = _this.props.config;
            var data = {
                id: config.id,
                cityId: cityId
            };
            getMapAddress(data)
                .then(function (res) {
                _this.setState({ addressList: res.data });
                _this.renderMarker(res.data);
            })
                .catch(msgError);
        };
        _this.initAMapLoader = function () {
            var config = _this.props.config;
            AMapLoader.load({
                "key": "1d7dd286b48f59732bdb724d2c02c186",
                "version": "1.4.15",
                plugins: ["AMap.ControlBar"],
                "Loca": {
                    "version": "1.3.2" // Loca 版本，缺省 1.3.2
                },
            }).then(function (AMap) {
                // @ts-ignore
                _this.Loca = Loca;
                _this.AMap = AMap;
                _this.map = new AMap.Map("demoMap", {
                    zooms: [10, 20],
                    center: [116.469881, 39.993599],
                    showIndoorMap: false,
                    viewMode: "3D",
                    rotation: -15,
                    pitch: 55,
                    lang: window.LANG || "cn",
                    buildingAnimation: true,
                    expandZoomRange: true,
                    resizeEnable: true,
                    rotateEnable: true,
                    pitchEnable: true,
                });
                map(layer, function (value, key) {
                    layer[key] = new _this.AMap.LabelsLayer({
                        zooms: [3, 20],
                        zIndex: 999,
                        cursor: "pointer",
                        collision: true,
                        animation: true,
                    });
                    _this.map.add(layer[key]);
                });
                map(boardLayer, function (value, key) {
                    boardLayer[key] = new _this.AMap.LabelsLayer({
                        zooms: [12, 20],
                        zIndex: 997,
                        cursor: "pointer",
                        collision: true,
                        animation: true,
                    });
                    _this.map.add(boardLayer[key]);
                });
                var heaterMapLayer = new _this.Loca.HeatmapLayer({
                    map: _this.map,
                    zIndex: 996
                });
                heaterMapLayer.setOptions({
                    style: {
                        radius: 20,
                        color: {
                            0.5: "#2c7bb6",
                            0.65: "#abd9e9",
                            0.7: "#ffffbf",
                            0.9: "#fde468",
                            1.0: "#d7191c"
                        }
                    }
                });
                _this.heaterMapLayer = heaterMapLayer;
                var controlBar = new AMap.ControlBar({
                    showZoomBar: false,
                    showControlButton: true,
                    position: {
                        right: "10px",
                        bottom: "-80px"
                    }
                });
                _this.map.addControl(controlBar);
                _this.map.setDefaultCursor("pointer");
                // 图层添加到地图
                // this.map.add(layer.shop);
                // this.map.add(layer.competingShop);
                _this.map.setFitView(null, false, [100, 150, 10, 10]);
                if (config.cityList && config.cityList.length > 0) {
                    var city = config.cityList[0];
                    _this.map.setZoomAndCenter(14, [city.lng, city.lat]);
                    _this.getAddress(city.value);
                    _this.onControlChange(city.value, "cityId");
                }
            }).catch(function (e) {
                console.log(e);
            });
        };
        _this.getMarkersIcon = function (item) {
            return {
                type: "image",
                image: getRelativeImgUrl("audienceMap/" + (item.type || "ledBoard") + ".png"),
                // 图片尺寸
                size: [28, 28],
            };
            // return new this.AMap.Icon({
            //   image,
            //   size: new this.AMap.Size(16, 16),
            //   imageSize: new this.AMap.Size(16, 16),
            // });
        };
        _this.drawCircle = function (lng, lat, r, bgColor, lineColor, zIndex) {
            return new _this.AMap.Circle({
                center: new _this.AMap.LngLat(lng, lat),
                zIndex: zIndex,
                radius: r,
                strokeColor: lineColor,
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: bgColor,
                fillOpacity: 0.8 // 填充透明度
            });
        };
        _this.getR = function (r) {
            var rList = _this.props.config.rList;
            var minDiff = 1000000;
            var result = 50;
            map(rList, function (item) {
                var diff = Math.abs(r - item.value);
                if (diff < minDiff) {
                    minDiff = diff;
                    result = item.value;
                }
            });
            return result;
        };
        _this.selectedCircleArea = function (e) {
            var lng = e.lng, lat = e.lat, _a = e.r, r = _a === void 0 ? 50 : _a, value = e.value;
            var controlData = _this.state.controlData;
            var newData = merge({}, controlData);
            _this.map.setZoomAndCenter(14, [lng, lat]);
            if (circle) {
                _this.map.remove([
                    circle,
                ]);
            }
            _this.setState({ loadingR: true });
            getPointMarkersData(e)
                .then(function (res) {
                _this.setState({ boardSize: res.data.boardSize, loadingR: false });
                _this.renderBoardMarker(res.data.boardList);
                _this.renderHeatMap(res.data.heaterMap);
            })
                .catch(msgError);
            set(newData, "r", _this.getR(r));
            set(newData, "shopId", value);
            _this.setState({ controlData: newData, shopData: e });
            circle = _this.drawCircle(lng, lat, r, "#B3D8FF", "#B3D8FF", 10);
            // text = this.drawCircleText(lng, lat, r)
            setTimeout(function () {
                _this.map.setZoom(15);
                _this.setState({ pointData: e });
                setTimeout(function () {
                    _this.map.add([
                        circle,
                    ]);
                }, 300);
            }, 300);
        };
        _this.initMarkerText = function (type) {
            var config = _this.props.config;
            var markerList = config.markerList;
            var obj = find(markerList, function (item) { return item.value === type; });
            return obj.label;
        };
        _this.renderMarker = function (data) {
            map(shopMarkers, function (value, key) {
                if (value) {
                    layer[key].remove(shopMarkers[key]);
                    _this.map.remove(layer[key]);
                    shopMarkers[key] = [];
                }
            });
            map(data, function (item) {
                var curData = {
                    position: [item.lng, item.lat],
                    zooms: [10, 20],
                    offset: [0, -10],
                    opacity: 1,
                    zIndex: 4,
                    icon: _this.getMarkersIcon(item),
                    extData: item,
                    text: {
                        content: _this.initMarkerText(item.type),
                        direction: "top",
                        offset: [0, -4],
                        style: {
                            fontSize: 10,
                            fillColor: "#333",
                            // strokeColor: "rgba(255,0,0,0.5)",
                            // strokeWidth: 2,
                            padding: [2, 4],
                            backgroundColor: "#fff",
                            borderWidth: 2,
                        }
                    },
                };
                var labelMarker = new _this.AMap.LabelMarker(curData);
                labelMarker.on("click", function (e) { return _this.selectedCircleArea(e.target.w.extData); });
                shopMarkers[item.type].push(labelMarker);
            });
            map(layer, function (value, key) {
                layer[key].add(shopMarkers[key]);
                _this.map.add(layer[key]);
            });
        };
        _this.renderBoardMarker = function (data) {
            var controlData = _this.state.controlData;
            _this.clearBoardAndHeater();
            map(data, function (item) {
                var curData = {
                    position: [item.lng, item.lat],
                    zooms: [12, 20],
                    opacity: 1,
                    zIndex: 5,
                    icon: _this.getMarkersIcon(item),
                    extData: item,
                    text: {
                        content: _this.initMarkerText(item.type),
                        direction: "top",
                        offset: [0, -4],
                        style: {
                            fontSize: 10,
                            fillColor: "#333",
                            // strokeColor: "rgba(255,0,0,0.5)",
                            // strokeWidth: 2,
                            padding: [2, 4],
                            backgroundColor: "#fff",
                            borderWidth: 2,
                        }
                    },
                };
                var labelMarker = new _this.AMap.LabelMarker(curData);
                labelMarker.on("mouseover", function (e) { return _this.showBoardInfo(e); });
                // labelMarker.on("mouseout", () => this.hideBoardInfo())
                boardMarkers[item.type].push(labelMarker);
            });
            map(boardLayer, function (value, key) {
                boardLayer[key].add(boardMarkers[key]);
                if (controlData[key]) {
                    _this.map.add(boardLayer[key]);
                }
            });
        };
        _this.renderHeatMap = function (data) {
            _this.heaterMapLayer.setData(data, {
                lnglat: function (data) {
                    return [data.value.lng, data.value.lat];
                },
                value: "heat"
            }).render();
        };
        _this.showBoardInfo = function (e) {
            _this.setState({
                boardData: e.target.w.extData
            });
        };
        _this.onControlChange = function (value, pathKey) {
            var controlData = _this.state.controlData;
            var newData = merge({}, controlData);
            set(newData, pathKey, value);
            _this.setState({ controlData: newData });
        };
        _this.onRChange = function (value) {
            var config = _this.props.config;
            var rList = config.rList;
            var _a = _this.state, controlData = _a.controlData, pointData = _a.pointData;
            var r = rList[value].value;
            var newData = merge({}, controlData);
            set(newData, "r", r);
            _this.setState({ controlData: newData });
            if (circle) {
                circle.setRadius(r);
                if (slideTimer) {
                    clearTimeout(slideTimer);
                }
                slideTimer = setTimeout(function () {
                    var data = __assign(__assign({ id: config.id }, pointData), { r: r });
                    _this.setState({ loadingR: true });
                    getPointMarkersData(data)
                        .then(function (res) {
                        _this.setState({ boardSize: res.data.boardSize, loadingR: false });
                        _this.renderBoardMarker(res.data.boardList);
                        _this.renderHeatMap(res.data.heaterMap);
                    })
                        .catch(msgError);
                }, 800);
            }
        };
        _this.onCityChange = function (v, e) {
            var controlData = _this.state.controlData;
            var _a = e.params, lng = _a.lng, lat = _a.lat;
            var newData = merge({}, controlData);
            set(newData, "cityId", v);
            set(newData, "shopId", null);
            _this.setState({ controlData: newData, shopData: null, boardData: {} });
            _this.map.setZoomAndCenter(14, [lng, lat]);
            _this.getAddress(v);
            _this.clearBoardAndHeater();
            if (circle) {
                _this.map.remove([
                    circle,
                ]);
                circle = null;
            }
        };
        _this.onShopChange = function (v, e) {
            _this.onControlChange(v, "shopId");
            if (!v) {
                if (circle) {
                    _this.map.remove([
                        circle,
                    ]);
                    circle = null;
                }
                _this.map.setZoom(14);
                _this.clearBoardAndHeater();
                _this.setState({ shopData: null, boardData: {} });
                return;
            }
            _this.selectedCircleArea(e.params);
        };
        _this.onCheckCompetingShop = function (v, pathKey) {
            _this.onControlChange(v.target.checked, pathKey);
            if (pathKey < 0) {
                if (!v.target.checked) {
                    _this.map.remove(layer[pathKey]);
                }
                else {
                    _this.map.add(layer[pathKey]);
                }
            }
            else {
                if (!v.target.checked) {
                    _this.map.remove(boardLayer[pathKey]);
                }
                else {
                    _this.map.add(boardLayer[pathKey]);
                }
            }
        };
        _this.onCheckMarker = function (v, pathKey) {
            _this.onControlChange(v.target.checked, pathKey);
        };
        _this.clearBoardAndHeater = function () {
            map(boardMarkers, function (value, key) {
                if (value) {
                    boardLayer[key].remove(boardMarkers[key]);
                    _this.map.remove(boardLayer[key]);
                    boardMarkers[key] = [];
                }
            });
            _this.heaterMapLayer.setData([], {
                lnglat: function (data) {
                    return [data.value.lng, data.value.lat];
                },
                value: "heat"
            }).render();
        };
        _this.initRMarks = function (list) {
            var result = {};
            map(list, function (item, idx) {
                result[idx] = idx === 0 || idx === list.length - 1 ? item.label : "";
            });
            return result;
        };
        _this.initRValue = function (r) {
            var rList = _this.props.config.rList;
            var idx = findIndex(rList, function (item) { return item.value === r; });
            return idx;
        };
        _this.boardContentRender = function () {
            var _a = _this.state, shopData = _a.shopData, _b = _a.boardData, boardData = _b === void 0 ? {} : _b, controlData = _a.controlData;
            var info = boardData.info, finalScore = boardData.finalScore;
            return (<DetailWrapper>
      {shopData.info && shopData.info[controlData.r] ? <ShopInfoWrapper>
        <DetailTitle>{l("dooh_planning_shop_info", "门店信息")}</DetailTitle>
        <ShopInfo>
          {map(shopData.info[controlData.r], function (item) { return (<div>
              {numeral(item.size).format("0,0")}
              <p>{item.title}</p>
            </div>); })}
        </ShopInfo>
      </ShopInfoWrapper> : null}
      {info && info.chartsData ? <ADWrapper>
        <DetailTitle>{l("dooh_planning_ad_info", "广告位总评分")}: {numeral(finalScore).format("0.0")}</DetailTitle>
        <ChartsWrapper>
          <MapRadar data={info.chartsData}/>
        </ChartsWrapper>
        <BoardAddress>{info.address}</BoardAddress>
      </ADWrapper> : null}
    </DetailWrapper>);
        };
        _this.state = {
            controlData: {},
            loadingR: false
        };
        return _this;
    }
    MapComponent.prototype.componentDidMount = function () {
        this.initConfig();
    };
    MapComponent.prototype.render = function () {
        var _this = this;
        var _a = this.state, controlData = _a.controlData, shopData = _a.shopData, addressList = _a.addressList, boardSize = _a.boardSize, loadingR = _a.loadingR;
        var config = this.props.config;
        var _b = config.markerList, markerList = _b === void 0 ? [] : _b;
        return (<>
      <MapDiv id="demoMap" {...this.props}>
        {shopData ?
            <ContentWrapper>
            {this.boardContentRender()}
          </ContentWrapper>
            : null}
        <ContentWrapper>
          <ControllerWrapper>
            <FormItem {...itemLayout} label={l("dooh_planning_city", "城市")}>
              <MySelect allowClear={false} style={{ width: "100%" }} placeholder={l("dooh_planning_city_select_placeholder", "请选择城市")} defaultOptions={config.cityList} value={controlData.cityId} onChange={function (v, e) { return _this.onCityChange(v, e); }}/>
            </FormItem>
            <FormItem {...itemLayout} label={l("dooh_planning_shop", "门店")}>
              <MySelect style={{ width: "100%" }} placeholder={l("dooh_planning_shop_select_placeholder", "请选择门店")} defaultOptions={addressList} value={controlData.shopId} onChange={function (v, e) { return _this.onShopChange(v, e); }}/>
            </FormItem>
            <FormItem {...itemLayout} label={l("dooh_planning_router", "半径")}>
              <SlideWrapper>
                <Slider marks={this.initRMarks(config.rList)} step={1} tipFormatter={function (v) { return config.rList[v].label; }} disabled={!circle || loadingR} style={{ float: "left", width: "100%" }} min={0} max={config.rList && config.rList.length > 1 ? config.rList.length - 1 : null} value={this.initRValue(this.getR(controlData.r))} onChange={function (v) { return _this.onRChange(v); }}/>
              </SlideWrapper>
            </FormItem>
            <FormItem {...itemLayout} label={l("dooh_planning_marker", "标注")}>
              {map(markerList, function (item, idx) { return (<FilterCheckbox style={idx === 0 ? { margin: "6px 0 0 8px" } : null} checked={controlData[item.value]} onChange={function (v) { return _this.onCheckCompetingShop(v, item.value); }}>
                  <img src={getImgUrl("audienceMap/" + item.value + ".png")}/>
                  {item.label}
                </FilterCheckbox>); })}
            </FormItem>
            {boardSize ? <BoardSize>
              {l("dooh_planning_ad_placement_count_text_1", "为您检索到")}
              {numeral(boardSize).format("0,0")}
              {l("dooh_planning_ad_placement_count_text_2", "个广告位")}
            </BoardSize> : null}
          </ControllerWrapper>
        </ContentWrapper>
      </MapDiv>
    </>);
    };
    return MapComponent;
}(React.Component));
export default MapComponent;
var MapDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  .amap-marker-label {\n    cursor: pointer !important;\n  }\n"], ["\n  position: relative;\n  .amap-marker-label {\n    cursor: pointer !important;\n  }\n"])));
var ControllerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 100;\n  top: 6px;\n  left: 6px;\n  padding: 16px;\n  width: 360px;\n  background-color: #fff;\n  box-shadow: 0 0 4px #aaa;\n"], ["\n  position: absolute;\n  z-index: 100;\n  top: 6px;\n  left: 6px;\n  padding: 16px;\n  width: 360px;\n  background-color: #fff;\n  box-shadow: 0 0 4px #aaa;\n"])));
var ContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  z-index: 100;\n  cursor: default;\n"], ["\n  position: relative;\n  z-index: 100;\n  cursor: default;\n"])));
var SlideWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var FilterCheckbox = styled(Checkbox)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  img {\n    width: 18px;\n  }\n"], ["\n  width: 100%;\n  img {\n    width: 18px;\n  }\n"])));
var DetailWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 2;\n  background-color: #fff;\n  box-shadow: 0 0 4px #aaa;\n  right: 10px;\n  top: 10px;\n  width: 380px;\n  border-radius: 4px;\n  cursor: default;\n"], ["\n  position: absolute;\n  z-index: 2;\n  background-color: #fff;\n  box-shadow: 0 0 4px #aaa;\n  right: 10px;\n  top: 10px;\n  width: 380px;\n  border-radius: 4px;\n  cursor: default;\n"])));
var ShopInfoWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 20px;\n"], ["\n  padding: 20px;\n"])));
var DetailTitle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: center;\n  font-size: 14px;\n  font-weight: bold;\n"], ["\n  text-align: center;\n  font-size: 14px;\n  font-weight: bold;\n"])));
var ShopInfo = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 20px;\n  overflow: auto;\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  max-height: 260px;\n  >div {\n    width: 50%;\n    text-align: center;\n    font-size: 14px;\n    font-weight: bold;\n    > p{\n      font-size: 12px;\n      font-weight: normal;\n      color: #8C8C8C;\n    }\n  }\n"], ["\n  margin-top: 20px;\n  overflow: auto;\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  max-height: 260px;\n  >div {\n    width: 50%;\n    text-align: center;\n    font-size: 14px;\n    font-weight: bold;\n    > p{\n      font-size: 12px;\n      font-weight: normal;\n      color: #8C8C8C;\n    }\n  }\n"])));
var ADWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding-top: 20px;\n  border-top: 1px solid #ddd;\n"], ["\n  padding-top: 20px;\n  border-top: 1px solid #ddd;\n"])));
var ChartsWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  height: 280px;\n"], ["\n  height: 280px;\n"])));
var BoardSize = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: 4px;\n  text-align: center;\n  background-color: #F7F7F7;\n  font-size: 12px;\n"], ["\n  padding: 4px;\n  text-align: center;\n  background-color: #F7F7F7;\n  font-size: 12px;\n"])));
var BoardAddress = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin: -40px 0 14px 0;\n  text-align: center;\n"], ["\n  margin: -40px 0 14px 0;\n  text-align: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Divider, Dropdown, Menu, Spin } from "antd";
import { DownOutlined, IconFont, LogoutOutlined, TagsOutlined, UserOutlined } from "@/utils/antdIcon";
import { l, LANGUAGE_CODE } from "@helpers/multiLangaugeHelper";
import { updateLanguage } from "@helpers/async";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { getAccountInfo, getLinkGid, getOpcoMenu, logoutApi } from "@components/contentWrapper/helpers/async";
import { useHistory } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@helpers/msalConfig";
import { find, map } from "lodash";
import { getImgUrl } from "@helpers/const";
import { QRCanvas } from "qrcanvas-react";
import { MyModal } from "@components/baseComponents/myModal";
import { HEADER_HEIGHT, WaterIco } from "@components/contentWrapper/contentWrapper";
var DEFAULT_BAR_HEIGHT = 64;
var MenuItem = Menu.Item;
var SubMenu = Menu.SubMenu;
export var SpecialOpcoWrapper = function (_a) {
    var children = _a.children;
    var history = useHistory();
    var $msalInstance = new PublicClientApplication(msalConfig);
    var _b = useState({}), accountInfo = _b[0], setAccountInfo = _b[1];
    var _c = useState(false), erCodeVisible = _c[0], setErCodeVisible = _c[1];
    var _d = useState(), erCodeImg = _d[0], setErCodeImg = _d[1];
    var _e = useState(), enterUrl = _e[0], setEnterUrl = _e[1];
    var _f = useState(), linkOpco = _f[0], setLinkOpco = _f[1];
    var _g = useState(), selectedHeadBarKey = _g[0], setSelectedHeadBarKey = _g[1];
    var _h = useState(), selectedSideBarKey = _h[0], setSelectedSideBarKey = _h[1];
    var _j = useState(false), showHide = _j[0], setShowHide = _j[1];
    var _k = useState(), oldHeadBarKey = _k[0], setOldHeadBarKey = _k[1];
    var _l = useState([]), headBar = _l[0], setHeadBar = _l[1];
    var _m = useState([]), sideBar = _m[0], setSideBar = _m[1];
    var _o = useState(), gid = _o[0], setGid = _o[1];
    useEffect(function () {
        var image = new Image();
        // wx二维码 logo 太丑了暂时没用
        image.src = "/objects/groupm/img/images/erLogo.png";
        image.onload = function () {
            setErCodeImg(image);
        };
        getAccountInfo()
            .then(function (res) {
            setAccountInfo(res.data);
            setEnterUrl(res.data.enterUrl);
            window.user = res.data;
            document.title = res.data.opcoTitle || res.data.opco;
        })
            .catch(msgError);
    }, []);
    useEffect(function () {
        if (JSON.stringify(accountInfo) !== "{}") {
            getMenu(accountInfo);
        }
    }, [location.pathname, JSON.stringify(accountInfo)]);
    var getMenu = function (accountInfo) {
        var urlKey = location.href.substring(0, location.href.lastIndexOf("/"));
        var urlKey2 = location.href.substring(0, urlKey.lastIndexOf("/"));
        var opco = accountInfo.opco.length >= 4 && accountInfo.opco.substr(0, 4) === "caas" ?
            (sessionStorage.getItem("opco-link-" + urlKey) || sessionStorage.getItem("opco-link-" + urlKey2)) : null;
        setLinkOpco(opco);
        var data = {
            url: location.pathname,
            opco: opco
        };
        getOpcoMenu(data)
            .then(function (res) {
            var menuList = res.data || [];
            setHeadBar(menuList);
            var selectedHeadItem = find(res.data, function (item) { return item.isActive; });
            var selectedHeadKey = selectedHeadItem ? selectedHeadItem.id : null;
            setSelectedHeadBarKey(selectedHeadKey);
        })
            .catch(msgError);
    };
    var changeLanguageCode = function (code) {
        if (code !== window.LANG) {
            localStorage.setItem("languageCode", code);
            updateLanguage(code)
                .then(function (res) {
                location.reload();
            })
                .catch(msgError);
        }
    };
    var changePassword = function () {
        var opco = accountInfo.opco, account = accountInfo.account;
        history.push(opco && opco !== "groupm" ?
            "/updatePassword/" + opco + "?username=" + account :
            "/updatePassword?username=" + account);
    };
    var logout = function () {
        logoutApi()
            .then(function (res) {
            msgSuccess(res);
            var url = enterUrl ? enterUrl : (accountInfo.opco !== "caas" ? (accountInfo.opco === "choreograph" ? "/enter" : "/enter/" + accountInfo.opco) : "/caasLogin");
            history.push(url);
            if (process.env.NODE_ENV !== "production") {
                $msalInstance.logoutPopup()
                    .then(function () {
                    history.push(url);
                })
                    .catch(function (error) {
                    msgError(error);
                });
            }
        })
            .catch(msgError);
    };
    var linkWeChat = function () {
        if (window.user && window.user.userId) {
            getLinkGid(window.user.userId)
                .then(function (res) {
                setGid(res.data.gid);
                setErCodeVisible(true);
            })
                .catch(msgError);
        }
    };
    var getRedirectUrl = function () {
        var url = location.origin + "/mt/pcLink?" +
            ("userId=" + (window.user ? window.user.userId : "") + "&gid=" + gid);
        return encodeURIComponent(url);
    };
    var onHeadBarClick = function (item, isSetOld, e) {
        if (e) {
            e.stopPropagation();
        }
        setShowHide(true);
        if (isSetOld) {
            setOldHeadBarKey(selectedHeadBarKey);
        }
        setSelectedHeadBarKey(item.id);
        setSideBar(item.children);
    };
    var closeBar = function (e) {
        e.stopPropagation();
        setSelectedHeadBarKey(oldHeadBarKey);
        setShowHide(false);
    };
    var onSideBarClick = function (e, item) {
        e.stopPropagation();
        setSelectedSideBarKey(item.id);
        setShowHide(false);
        history.push(item.url);
        document.title = window.user.opcoTitle + " - " + item.title;
    };
    var jumpHeadLink = function (item) {
        setShowHide(false);
        setSelectedHeadBarKey(item.id);
        history.push(item.url);
        document.title = window.user.opcoTitle + " - " + item.title;
    };
    var renderSideBar = function (sideBar) {
        return map(sideBar, function (item, idx) {
            if (item.children && item.children.length > 0) {
                return (<div className={"itemWrapper"}>
            <div className={"title"}><TagsOutlined style={{ marginRight: "8px" }}/>{item.title}</div>
            <div className={"itemBlock"}>{renderSideBar(item.children)}</div>
            {sideBar.length - 1 === idx ? null : <Divider style={{ margin: "14px 0" }}/>}
          </div>);
            }
            return (<div className={(selectedSideBarKey === item.id ? "active" : "") + " item"} onClick={function (e) { return onSideBarClick(e, item); }}>
            {item.title}
          </div>);
        });
    };
    var userMenu = (<Menu>
      <MenuItem icon={<AccountNameIcon small={true}>
          {accountInfo.account ? accountInfo.account.substring(0, 1) : ""}
        </AccountNameIcon>}>
        <span style={{ marginLeft: "10px" }}>{accountInfo.account}</span>
      </MenuItem>
      <MenuItem icon={<UserOutlined />} onClick={linkWeChat}>
        {l("link_to_wechat", "关联微信")}
      </MenuItem>
      {accountInfo.isExternal ? <MenuItem icon={<UserOutlined />} onClick={changePassword}>
        {l("common_update_password", "修改密码")}
      </MenuItem> : null}
      <MenuItem icon={<LogoutOutlined />} onClick={logout}>{l("common_log_out", "退出登录")}</MenuItem>
    </Menu>);
    var languageMenu = (<Menu>
      <MenuItem onClick={function () { return changeLanguageCode(LANGUAGE_CODE.CN); }}>🇨🇳 简体中文</MenuItem>
      <MenuItem onClick={function () { return changeLanguageCode(LANGUAGE_CODE.EN); }}>🇬🇧 English</MenuItem>
    </Menu>);
    var menuClick = function (item) {
        history.push(item.url);
    };
    var renderMenu = function (menuList) {
        if (!menuList)
            return null;
        return map(menuList, function (item) {
            if (item.children && item.children.length > 0) {
                return (<SubMenu key={item.id} icon={item.icon && item.icon !== "" ? <MenuIcon src={item.icon}/> : null} title={<MenuText>{item.title} <SubIcon /></MenuText>}>
          {renderMenu(item.children)}
        </SubMenu>);
            }
            else {
                return (<MenuItem key={item.id} icon={item.icon && item.icon !== "" ? <MenuIcon src={item.icon}/> : null} onClick={function () { return menuClick(item); }}>
          <MenuText>{item.title}</MenuText>
        </MenuItem>);
            }
        });
    };
    var isCaas = accountInfo.opco && accountInfo.opco.length >= 4 && accountInfo.opco.substr(0, 4) === "caas";
    return (<Wrapper>
    <HeaderWrapper className={"header"}>
      <div>
        {accountInfo.opco ? <LogoWrapper>
          {isCaas ? <ChoreographLogo onClick={function () { return history.push("/caas/welcome/index"); }}><IconFont type={"icon-a-logoyuandianyuansu"}/></ChoreographLogo> : null}
          <Logo style={isCaas ? null : { margin: "auto" }} src={getImgUrl("logo/top/" + (isCaas ?
        (linkOpco || accountInfo.opco) : accountInfo.opco) + ".png")} onClick={function () { return accountInfo.homepage ? history.push(accountInfo.homepage) : null; }}/>
        </LogoWrapper> : null}
        <Rl />
        {location.href.indexOf("demo") > -1 || process.env.NODE_ENV !== "development" ? null :
        <WaterIco>{l("test_env", "Test Env.")}</WaterIco>}
        
        
        
        
        
        
        {headBar.map(function (item) { return (<MainMenu key={item.id} active={selectedHeadBarKey === item.id} onClick={function (e) {
        var _a;
        return ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) > 0 ?
            (showHide ? null : onHeadBarClick(item, true, e)) :
            jumpHeadLink(item);
    }} onMouseOver={function () { var _a; return ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) > 0 && showHide ? onHeadBarClick(item) : null; }}>
            {item.title}
            <ChildMenuWrapper active={showHide && selectedHeadBarKey === item.id}>
              <SideChildBar>
                {renderSideBar(sideBar)}
              </SideChildBar>
            </ChildMenuWrapper>
          </MainMenu>); })}
      </div>
      <InfoWrapper>
        <Dropdown overlay={userMenu}>
          <InfoItem>
            <AccountNameIcon className={"accountIcon"}>{accountInfo.account ? accountInfo.account.substring(0, 1) : ""}</AccountNameIcon>
          </InfoItem>
        </Dropdown>
        <Dropdown overlay={languageMenu}>
          <InfoItem>
            <MultiLanguageIcon type="icon-language"/>
          </InfoItem>
        </Dropdown>
      </InfoWrapper>
    </HeaderWrapper>
    <BottomWrapper>
      <SideBarBlock>
        <SideBarMask active={showHide} onClick={closeBar}/>
      </SideBarBlock>
      <Content id={"content"}>
        {children}
      </Content>
    </BottomWrapper>
    <MyModal width={180} visible={erCodeVisible} title={l("link_to_wechat", "关联微信账号")} onCancel={function () { return setErCodeVisible(false); }} footer={null}>
      <Spin spinning={!gid}>
        {erCodeImg ? <ErCodeWrapper>
          <QRCanvas options={{
        cellSize: 2.2,
        // logo: {image: erCodeImg},
        data: "https://open.weixin.qq.com/connect/oauth2/authorize?" +
            ("appid=" + (process.env.NODE_ENV !== "production" ? "wx921f3753e6f5d563" : "wx6e7b3c82b5a15d6f") + "&") +
            ("redirect_uri=" + getRedirectUrl()) +
            "&response_type=code&scope=snsapi_userinfo#wechat_redirect"
    }}/>
        </ErCodeWrapper> : null}
      </Spin>
    </MyModal>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  height: 100vh;\n  width: 100vw;\n  min-width: 1320px;\n"], ["\n  position: relative;\n  overflow: hidden;\n  height: 100vh;\n  width: 100vw;\n  min-width: 1320px;\n"])));
var HeaderWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  z-index: 10;\n  display: flex;\n  flex-wrap: nowrap;\n  width: 100%;\n  height: ", "px;\n  align-items: center;\n  justify-content: space-between;\n  box-shadow: 0 2px 4px #777;\n  background: linear-gradient(194deg, #195CC7 0%, #25B4BA 24%, #185AC7 96%);\n  > div {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n  }\n"], ["\n  position: relative;\n  z-index: 10;\n  display: flex;\n  flex-wrap: nowrap;\n  width: 100%;\n  height: ", "px;\n  align-items: center;\n  justify-content: space-between;\n  box-shadow: 0 2px 4px #777;\n  background: linear-gradient(194deg, #195CC7 0%, #25B4BA 24%, #185AC7 96%);\n  > div {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n  }\n"])), DEFAULT_BAR_HEIGHT);
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: auto;\n  width: 100%;\n  height: calc(100vh - ", "px);\n  background: #F0F0F0;\n"], ["\n  overflow: auto;\n  width: 100%;\n  height: calc(100vh - ", "px);\n  background: #F0F0F0;\n"])), DEFAULT_BAR_HEIGHT);
var InfoWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  height: 100%;\n  width: 100px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  height: 100%;\n  width: 100px;\n"])));
var InfoItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 40px;\n  height: 100%;\n  color: #fff;\n  cursor: pointer;\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.2);\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 40px;\n  height: 100%;\n  color: #fff;\n  cursor: pointer;\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.2);\n  }\n"])));
var AccountNameIcon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  width: ", ";\n  height: ", ";\n  border-radius: 100%;\n  background-color: #0D46A4;\n  color: #fff;\n  line-height: ", ";\n  text-align: center;\n"], ["\n  display: inline-block;\n  width: ", ";\n  height: ", ";\n  border-radius: 100%;\n  background-color: #0D46A4;\n  color: #fff;\n  line-height: ", ";\n  text-align: center;\n"])), function (props) { return props.small ? "20px" : "24px"; }, function (props) { return props.small ? "20px" : "24px"; }, function (props) { return props.small ? "20px" : "24px"; });
var MultiLanguageIcon = styled(IconFont)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #fff;\n"], ["\n  font-size: 14px;\n  color: #fff;\n"])));
var MenuIcon = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: 4px;\n  width: 28px;\n  height: 28px;\n"], ["\n  margin-right: 4px;\n  width: 28px;\n  height: 28px;\n"])));
var LogoWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 240px;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 240px;\n  height: 100%;\n"])));
var Rl = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 0 20px;\n  width: 1px;\n  height: 32px;\n  background: #fff;\n"], ["\n  margin: 0 20px;\n  width: 1px;\n  height: 32px;\n  background: #fff;\n"])));
var ChoreographLogo = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-left: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 48px;\n  height: 48px;\n  font-size: 24px;\n  color: #fff;\n  transition: all .5s;\n  cursor: pointer;\n  border-radius: 6px;\n  &:hover {\n    background: #2B78EA;\n  }\n"], ["\n  margin-left: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 48px;\n  height: 48px;\n  font-size: 24px;\n  color: #fff;\n  transition: all .5s;\n  cursor: pointer;\n  border-radius: 6px;\n  &:hover {\n    background: #2B78EA;\n  }\n"])));
var Logo = styled.img(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 140px;\n  cursor: pointer;\n"], ["\n  width: 140px;\n  cursor: pointer;\n"])));
var MenuText = styled.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n"], ["\n"])));
var SubIcon = styled(DownOutlined)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  margin: 0 4px;\n  font-size: 12px !important;\n"], ["\n  margin: 0 4px;\n  font-size: 12px !important;\n"])));
var ErCodeWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  margin: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var MainMenu = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 18px;\n  color: #fff;\n  background: ", ";\n  cursor: pointer;\n  font-size: 15px;\n  height: ", "px;\n  &:hover {\n    background: rgba(255, 255, 255, 0.3);\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 18px;\n  color: #fff;\n  background: ", ";\n  cursor: pointer;\n  font-size: 15px;\n  height: ", "px;\n  &:hover {\n    background: rgba(255, 255, 255, 0.3);\n  }\n"])), function (props) { return props.active ? "rgba(255,255,255,0.3)" : "none"; }, HEADER_HEIGHT);
var ChildMenuWrapper = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  position: absolute;\n  margin-left: 32px;\n  padding: 10px;\n  overflow: auto;\n  max-height: calc(100vh - ", "px);\n  border-radius: 10px;\n  top: ", "px;\n  background: #fff;\n  display: ", ";\n  transform: translateX(-50%);\n  box-shadow: 0 0 6px #777;\n"], ["\n  position: absolute;\n  margin-left: 32px;\n  padding: 10px;\n  overflow: auto;\n  max-height: calc(100vh - ", "px);\n  border-radius: 10px;\n  top: ", "px;\n  background: #fff;\n  display: ", ";\n  transform: translateX(-50%);\n  box-shadow: 0 0 6px #777;\n"])), HEADER_HEIGHT + 20, HEADER_HEIGHT, function (props) { return props.active ? "block" : "none"; });
var SideChildBar = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  float: left;\n  width: 400px;\n  display: flex;\n  flex-wrap: wrap;\n  .itemWrapper {\n    box-sizing: border-box;\n    padding: 4px 10px;\n    width: 100%;\n    .title {\n      margin-bottom: 6px;\n      font-size: 14px;\n      color: #777;\n    }\n    .itemBlock {\n      display: flex;\n      flex-wrap: wrap;\n    }\n    .item {\n      padding: 0;\n      width: 50%;\n    }\n  }\n  .item {\n    display: inline-block;\n    margin-top: 8px;\n    padding: 0 14px;\n    width: 50%;\n    cursor: pointer;\n    color: #555;\n    &.active {\n      color: #0D46A4;\n    }\n    &:hover {\n      color: #0D46A4;\n    }\n  }\n"], ["\n  float: left;\n  width: 400px;\n  display: flex;\n  flex-wrap: wrap;\n  .itemWrapper {\n    box-sizing: border-box;\n    padding: 4px 10px;\n    width: 100%;\n    .title {\n      margin-bottom: 6px;\n      font-size: 14px;\n      color: #777;\n    }\n    .itemBlock {\n      display: flex;\n      flex-wrap: wrap;\n    }\n    .item {\n      padding: 0;\n      width: 50%;\n    }\n  }\n  .item {\n    display: inline-block;\n    margin-top: 8px;\n    padding: 0 14px;\n    width: 50%;\n    cursor: pointer;\n    color: #555;\n    &.active {\n      color: #0D46A4;\n    }\n    &:hover {\n      color: #0D46A4;\n    }\n  }\n"])));
var BottomWrapper = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  z-index: 1;\n  display: flex;\n  position: relative;\n  float: right;\n  overflow: hidden;\n  height: calc(100vh - ", "px);\n  width: 100%;\n"], ["\n  z-index: 1;\n  display: flex;\n  position: relative;\n  float: right;\n  overflow: hidden;\n  height: calc(100vh - ", "px);\n  width: 100%;\n"])), HEADER_HEIGHT);
var SideBarBlock = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  z-index: 100;\n  position: fixed;\n  top: ", "px;\n  left: 0;\n  bottom: 0;\n"], ["\n  z-index: 100;\n  position: fixed;\n  top: ", "px;\n  left: 0;\n  bottom: 0;\n"])), HEADER_HEIGHT);
var SideBarMask = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: ", ";\n  z-index: 6;\n  position: fixed;\n  top: ", "px;\n  bottom: 0;\n  left: 0;\n  right: 0;\n"], ["\n  display: ", ";\n  z-index: 6;\n  position: fixed;\n  top: ", "px;\n  bottom: 0;\n  left: 0;\n  right: 0;\n"])), function (props) { return props.active ? "block" : "none"; }, HEADER_HEIGHT);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;

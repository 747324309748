var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form } from "antd";
import { StoreContext } from "../../store/storeContext";
import { l } from "@helpers/multiLangaugeHelper";
import { filterOption, getItemLayout } from "@helpers/const";
import { CONDITION_ENUM, getConditionTypeList } from "../../helpers/initData";
import { MySelect } from "@components/baseComponents/mySelect";
import { MyTableTransfer } from "@components/myTransfer/myTableTransfer";
import { merge, set } from "lodash";
import { DownloadOutlined } from "@/utils/antdIcon";
import styled from "styled-components";
import { getShanHaiJinOptions } from "../../helpers/async";
import { msgError } from "@helpers/axiosHelper";
import { MySpin } from "@components/baseComponents/mySpin";
import { BatchSyncExportByUrl, EXPORT_URL_TYPE_LIST } from "@components/batchExportByUrl/batchSyncExportByUrl";
import { MyModal } from "@components/baseComponents/myModal";
var FormItem = Form.Item;
var itemLayout = getItemLayout(4, 10);
export var ShanHaiJinCondition = function (_a) {
    var readonly = _a.readonly;
    var _b = useContext(StoreContext), data = _b.data, modalOption = _b.modalOption, setModalOption = _b.setModalOption, modalData = _b.modalData, setModalData = _b.setModalData, saveModalData = _b.saveModalData;
    var _c = useState([]), transferSelectedKeys = _c[0], setTransferSelectedKeys = _c[1];
    var _d = useState(), excelUrl = _d[0], setExcelUrl = _d[1];
    var _e = useState([]), transferDataSource = _e[0], setTransferDataSource = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    useEffect(function () {
        setTransferSelectedKeys([]);
    }, [modalOption.type]);
    useEffect(function () {
        if (modalOption.type !== CONDITION_ENUM.SHAN_HAI_JIN)
            return;
        setTransferDataSource([]);
        if (modalData.category && modalData.year) {
            getSHJDataSource();
        }
    }, [JSON.stringify(modalOption.type === CONDITION_ENUM.SHAN_HAI_JIN)]);
    var getSHJDataSource = function () {
        setLoading(true);
        var data = {
            year: modalData.year,
            category: modalData.category
        };
        getShanHaiJinOptions(data)
            .then(function (res) {
            setTransferDataSource(res.data);
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var onFormDataChange = function (value, pathKey, e) {
        var newData = merge({}, modalData);
        set(newData, pathKey, value);
        if (pathKey === "year") {
            set(newData, "category", null);
            set(newData, "itemList", []);
            setTransferDataSource([]);
            setExcelUrl(e.params.downloadUrl);
        }
        if (pathKey === "category") {
            set(newData, "itemList", []);
            setTransferDataSource([]);
            var data_1 = {
                year: newData.year,
                category: value
            };
            setLoading(true);
            getShanHaiJinOptions(data_1)
                .then(function (res) {
                setTransferDataSource(res.data);
                setLoading(false);
            })
                .catch(function (err) {
                setLoading(false);
                msgError(err);
            });
        }
        setModalData(newData);
    };
    var saveCanCheck = function () {
        return !(!modalData.year || !modalData.category || !(modalData.itemList && modalData.itemList.length > 0));
    };
    var closeModal = function () {
        setLoading(false);
        setExcelUrl(null);
        setModalOption({});
    };
    var save = function () {
        setLoading(false);
        setExcelUrl(null);
        saveModalData(modalData, modalOption);
        setModalOption({});
    };
    return (<MyModal width={1000} title={getConditionTypeList()[CONDITION_ENUM.SHAN_HAI_JIN].text} visible={modalOption.type === CONDITION_ENUM.SHAN_HAI_JIN} onCancel={closeModal} maskClosable={false} onOk={save} okButtonProps={{ disabled: !saveCanCheck() }}>
      {modalOption.type === CONDITION_ENUM.SHAN_HAI_JIN ?
        <StoreContext.Consumer>
          {function (_a) {
            var modalOption = _a.modalOption;
            return modalOption.type === CONDITION_ENUM.SHAN_HAI_JIN ? (<MySpin spinning={loading}>
            <FormItem required {...itemLayout} label={l("common_year", "Year")}>
              <MySelect disabled={readonly} allowClear={false} style={{ width: "260px" }} placeholder={l("common_select_year_placeholder", "Select Year")} url={"/groupme/audex/audience/standardAudience_v2/shanhaijin/getYearOptions"} params={{ advertiserId: data.advertiserId }} value={modalData.year} onChange={function (v, e) { return onFormDataChange(v, "year", e); }}/>
              {modalData.year ?
                <BatchSyncExportByUrl style={{ marginLeft: "10px" }} url={EXPORT_URL_TYPE_LIST.AUDIENCE_SHANHAIJIN_EXPORT} params={{ year: modalData.year }} btnType={"href"}/>
                : null}
            </FormItem>
            <FormItem required {...itemLayout} label={l("common_category", "Category")}>
              <MySelect disabled={readonly} allowClear={false} style={{ width: "260px" }} placeholder={l("common_select_category_placeholder", "Select Category")} url={"/groupme/audex/audience/standardAudience_v2/shanhaijin/getCategoryByYear"} params={{ year: modalData.year, advertiserId: data.advertiserId }} value={modalData.category} onChange={function (v) { return onFormDataChange(v, "category"); }}/>
            </FormItem>
            <MyTableTransfer rowKey={function (item) { return item.value; }} showSearch={true} disabled={readonly} dataSource={transferDataSource} columns={[
                {
                    key: "label",
                    title: l("common_name", "Name"),
                },
            ]} listStyle={{
                width: 460
            }} titles={[l("common_wait_for_select_list", "待选列表"),
                l("common_selected_list", "已选列表")]} targetKeys={modalData.itemList} selectedKeys={transferSelectedKeys} filterOption={filterOption} onSelectChange={function (sourceSelectedKeys, targetSelectedKeys) {
                return setTransferSelectedKeys(__spreadArrays(sourceSelectedKeys, targetSelectedKeys));
            }} onChange={function (v) { return onFormDataChange(v, "itemList"); }}/></MySpin>) : null;
        }}
        </StoreContext.Consumer> : null}
    </MyModal>);
};
var DownloadExcel = styled(DownloadOutlined)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: 10px;\n  display: inline-block;\n  padding: 6px;\n  height: 100%;\n  border: 1px solid #ddd;\n  border-radius: 100%;\n  cursor: pointer;\n"], ["\n  margin-left: 10px;\n  display: inline-block;\n  padding: 6px;\n  height: 100%;\n  border: 1px solid #ddd;\n  border-radius: 100%;\n  cursor: pointer;\n"])));
var templateObject_1;

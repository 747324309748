var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { map } from "lodash";
import { getOptions } from "@helpers/async";
import { msgError } from "@helpers/axiosHelper";
import styled from "styled-components";
var Option = Select.Option;
export var MySelect = function (_a) {
    var url = _a.url, params = _a.params, allowClear = _a.allowClear, style = _a.style, value = _a.value, defaultOptions = _a.defaultOptions, disabled = _a.disabled, mode = _a.mode, descFormatter = _a.descFormatter, _b = _a.showSearch, showSearch = _b === void 0 ? true : _b, props = __rest(_a, ["url", "params", "allowClear", "style", "value", "defaultOptions", "disabled", "mode", "descFormatter", "showSearch"]);
    var _c = useState(defaultOptions ? defaultOptions : []), options = _c[0], setOptions = _c[1];
    var _d = useState(), oldValue = _d[0], setOldValue = _d[1];
    useEffect(function () {
        if (!url)
            return;
        if (!oldValue && value) {
            setOldValue(value);
            getOptions(url, __assign(__assign({}, params), { value: value }))
                .then(function (res) {
                setOptions(res.data);
            })
                .catch(msgError);
        }
    }, [JSON.stringify(value)]);
    useEffect(function () {
        if (defaultOptions) {
            setOptions(defaultOptions);
            return;
        }
        if (url) {
            getOptions(url, __assign(__assign({}, params), { value: value }))
                .then(function (res) {
                setOptions(res.data);
            })
                .catch(msgError);
        }
    }, [url, JSON.stringify(params), JSON.stringify(defaultOptions)]);
    return (<SelectWrapper disabled={disabled} mode={mode} showSearch={showSearch} style={__assign({ width: "120px" }, style)} allowClear={typeof allowClear === "boolean" ? allowClear : true} optionFilterProp="text" value={value || value === 0 ? value : undefined} getPopupContainer={function (triggerNode) { return triggerNode.parentNode; }} {...props}>
      {map(options, function (option) { return (<Option key={option.value} value={option.value} disabled={option.disabled} params={option} text={option.label}>
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: "<pre style=\"margin-bottom: 0\">" + (option.tooltip ? option.tooltip : option.label) + "</pre>" }}/>} placement="left">
            <OptionText flex={!option.desc}>
              <div>{option.label}</div>
              {option.desc ?
        <DescWrapper>{descFormatter ? descFormatter(option.desc) : option.desc}</DescWrapper> : null}
            </OptionText>
          </Tooltip>
        </Option>); })}
    </SelectWrapper>);
};
var SelectWrapper = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.ant-select .ant-select-selector {\n    overflow: auto;\n    max-height: 200px;\n    width: 100%;\n  }\n"], ["\n  &.ant-select .ant-select-selector {\n    overflow: auto;\n    max-height: 200px;\n    width: 100%;\n  }\n"])));
var OptionText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: ", ";\n  align-items: center;\n  width: 100%;\n  height: ", ";\n  >div:nth-child(1) {\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  display: ", ";\n  align-items: center;\n  width: 100%;\n  height: ", ";\n  >div:nth-child(1) {\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n"])), function (props) { return props.flex ? "flex" : "block"; }, function (props) { return props.flex ? "100%" : "auto"; });
var DescWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n  font-size: 12px;\n  color: #aaa;\n  .rc-virtual-list-holder &{\n    display: block;\n  }\n"], ["\n  display: none;\n  font-size: 12px;\n  color: #aaa;\n  .rc-virtual-list-holder &{\n    display: block;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;

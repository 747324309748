var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled from "styled-components";
import numeral from "numeral";
var COL_WIDTH_UNIT = Number(numeral(100 / 12).format("0.00"));
export var ChartItemWrapper = function (_a) {
    var _b = _a.col, col = _b === void 0 ? 6 : _b, row = _a.row, children = _a.children, props = __rest(_a, ["col", "row", "children"]);
    return (<ChartItemCol col={col} row={row}>{children}</ChartItemCol>);
};
var ChartItemCol = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  position: relative;\n  float: left;\n  padding: 20px;\n  height: ", ";\n  width: ", ";\n"], ["\n  overflow: hidden;\n  position: relative;\n  float: left;\n  padding: 20px;\n  height: ", ";\n  width: ", ";\n"])), function (props) { return props.row ? props.row * 500 + "px" : "500px"; }, function (props) { return props.col * COL_WIDTH_UNIT + "%"; });
var templateObject_1;

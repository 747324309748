export var getAreaDataFilter = function (data, level, hasParent, hasChild) {
    if (data && data.length > 0) {
        return data.filter(function (item) {
            if (hasChild) {
                return Number(item.level) >= level;
            }
            if (hasParent) {
                return Number(item.level) <= level;
            }
            return Number(item.level) === level;
        });
    }
    return data;
};
export var mapRateData = [
    {
        from: "重庆市",
        fromPercent: 0.54,
        to: "遵义市",
        toPercent: 0.22,
    },
    {
        from: "重庆市",
        fromPercent: 0.54,
        to: "成都市",
        toPercent: 0.55,
    },
    {
        from: "重庆市",
        fromPercent: 0.54,
        to: "北京市",
        toPercent: 0.01,
    },
    {
        from: "广州市",
        fromPercent: 0.54,
        to: "东莞市",
        toPercent: 0.12,
    },
    {
        from: "广州市",
        fromPercent: 0.44,
        to: "佛山市",
        toPercent: 0.08,
    },
    {
        from: "广州市",
        fromPercent: 0.44,
        to: "揭阳市",
        toPercent: 0.09,
    },
    {
        from: "成都市",
        fromPercent: 0.44,
        to: "雅安市",
        toPercent: 0.11,
    },
    {
        from: "成都市",
        fromPercent: 0.44,
        to: "重庆市",
        toPercent: 0.02,
    },
    {
        from: "成都市",
        fromPercent: 0.84,
        to: "德阳市",
        toPercent: 0.55,
    },
    {
        from: "上海市",
        fromPercent: 0.84,
        to: "北京市",
        toPercent: 0.11,
    },
    {
        from: "上海市",
        fromPercent: 0.84,
        to: "苏州市",
        toPercent: 0.11,
    },
    {
        from: "上海市",
        fromPercent: 0.14,
        to: "合肥市",
        toPercent: 0.11,
    },
    {
        from: "北京市",
        fromPercent: 0.14,
        to: "廊坊市",
        toPercent: 0.11,
    },
    {
        from: "北京市",
        fromPercent: 0.14,
        to: "保定市",
        toPercent: 0.11,
    },
    {
        from: "北京市",
        fromPercent: 0.14,
        to: "邯郸市",
        toPercent: 0.11,
    },
];
export var diffMapData = [
    {
        xAxis: "北京市",
        yAxis: 0.05,
        yAxis2: 98,
    },
    // {
    //   xAxis: "北京市",
    //   yAxis: 0.9,
    //   yAxis2: 120,
    //   type:"b"
    // },
    // {
    //   xAxis: "上海市",
    //   yAxis: 0.16,
    //   yAxis2: 128,
    //   type:"a"
    // },
    {
        xAxis: "上海市",
        yAxis: 0.55,
        yAxis2: 88,
    },
];

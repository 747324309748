var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { AxisChart } from "@components/charts/axisChart/axisChart";
import { ANOTHER_DEFAULT_COLOR_LIST, CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
export var BarLineChars = function (_a) {
    var height = _a.height, padding = _a.padding, appendPadding = _a.appendPadding, hasType = _a.hasType, data = _a.data, bar = _a.bar, line = _a.line, point = _a.point, yScale = _a.yScale, yAxis = _a.yAxis, xAxis = _a.xAxis, anotherAxis = _a.anotherAxis, tooltip = _a.tooltip;
    var legendMap = {};
    var chartIns;
    var initLegend = function () {
        var result = [];
        if (bar) {
            result[0] = {
                value: bar.yAxis,
                name: yAxis.yAxisTitle ? yAxis.yAxisTitle : bar.yAxis,
                marker: {
                    symbol: "square",
                    style: { fill: hasType ? "#aaa" : CHARTS_DEFAULT_COLOR_LIST[0], r: 5 },
                }
            };
        }
        if (point || line) {
            result[1] = {
                value: point && point.yAxis ? point.yAxis : line && line.yAxis ? line.yAxis : "none",
                name: anotherAxis && anotherAxis.yAxisTitle ? anotherAxis.yAxisTitle :
                    point && point.yAxis ? point.yAxis : line && line.yAxis ? line.yAxis : "未设置",
                marker: {
                    symbol: "hyphen",
                    style: { stroke: hasType ? "#aaa" : CHARTS_DEFAULT_COLOR_LIST[1], r: 8, lineWidth: 2 },
                }
            };
        }
        return result;
    };
    var getCharts = function () {
        var result = {};
        if (bar) {
            result.bar = __assign(__assign({}, bar), { color: bar && bar.color ? [bar.color, CHARTS_DEFAULT_COLOR_LIST] : CHARTS_DEFAULT_COLOR_LIST[0] });
        }
        if (line) {
            result.line = __assign(__assign({}, line), { color: line && line.color ? [line.color, ANOTHER_DEFAULT_COLOR_LIST] : CHARTS_DEFAULT_COLOR_LIST[1] });
        }
        if (point) {
            result.point = __assign(__assign({}, point), { color: point && point.color ?
                    typeof point.color === "string" ? [point.color, ANOTHER_DEFAULT_COLOR_LIST] : point.color
                    : CHARTS_DEFAULT_COLOR_LIST[1] });
        }
        return result;
    };
    return (<AxisChart padding={padding} appendPadding={appendPadding} height={height} onGetG2Instance={function (c) { return chartIns = c; }} data={data} xAxis={xAxis} yAxis={yAxis} {...getCharts()} yScale={yScale} anotherAxis={anotherAxis} legend={{
        position: "top",
        custom: true,
        items: initLegend(),
        onChange: function (ev) {
            var item = ev.item;
            var value = item.value;
            var checked = !item.unchecked;
            var geoms = chartIns.geometries;
            // 设置图例项状态
            legendMap[value] = checked;
            for (var _i = 0, geoms_1 = geoms; _i < geoms_1.length; _i++) {
                var geom = geoms_1[_i];
                if (geom.getYScale().field === value) {
                    if (checked) {
                        geom.show();
                    }
                    else {
                        geom.hide();
                    }
                }
            }
            chartIns.render(true);
        }
    }} tooltip={tooltip}/>);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Annotation, Axis, Chart, Coordinate, Point, registerAnimation, registerShape, } from "bizcharts";
import styled from "styled-components";
import { MAIN_COLOR } from "@/common/styled";
// 自定义Shape 部分
registerShape("point", "pointer", {
    draw: function (cfg, container) {
        var group = container.addGroup();
        var center = this.parsePoint({ x: 0, y: 0 }); // 获取极坐标系下画布中心点
        var start = this.parsePoint({ x: 0, y: 0.5 }); // 获取极坐标系下起始点
        // 绘制指针
        var line = group.addShape("line", {
            attrs: {
                x1: center.x,
                y1: center.y,
                x2: start.x,
                y2: start.y,
                stroke: "l (270) 0:rgba(255, 146, 255, 1) .5:rgba(100, 268, 255, 1) 1:rgba(215, 0, 255, 1)",
                // stroke: cfg.color,
                lineWidth: 5,
                lineCap: "round",
            },
        });
        group.addShape("circle", {
            attrs: {
                x: center.x,
                y: center.y,
                r: 9.75,
                stroke: "#f669ef",
                // stroke: cfg.color,
                lineWidth: 4.5,
                fill: "#fff",
            },
        });
        var preAngle = this.preAngle || 0;
        var angle1 = Math.atan((start.y - center.y) / (start.x - center.x));
        var angle = (Math.PI - 2 * (angle1)) * cfg.points[0].x;
        if (group.cfg.animable) {
            group.animate(function (ratio) {
                group.resetMatrix();
                group.rotateAtPoint(center.x, center.y, preAngle + (angle - preAngle) * ratio);
            }, 300);
        }
        else {
            group.rotateAtPoint(center.x, center.y, angle);
        }
        this.preAngle = angle;
        return group;
    },
});
registerAnimation("cust-animation", function (shape) {
    // console.log("cust-animation", shape)
});
export var PanelChart = function (_a) {
    var value = _a.value, _b = _a.height, height = _b === void 0 ? 360 : _b, min = _a.min, max = _a.max, tickInterval = _a.tickInterval, format = _a.format;
    var scale = {
        value: {
            min: min ? min : 0,
            max: max ? max : 100,
            tickInterval: tickInterval ? tickInterval : max ? max / 10 : 10,
            formatter: format ? format : function (v) { return v; }
        }
    };
    return (<Wrapper>
    <Chart height={height} data={[{ value: value }]} scale={scale} autoFit>
      <Coordinate type="polar" radius={0.85} startAngle={(-12 / 10) * Math.PI} endAngle={(2 / 10) * Math.PI}/>
      <Axis name="1"/>
      <Axis name="value" line={null} label={{
        offset: -36,
        style: {
            fontSize: 14,
            textAlign: "center",
            textBaseline: "middle",
        },
    }} subTickLine={{
        count: 4,
        length: -15,
    }} tickLine={{
        length: -24,
    }} grid={null}/>
      <Point position="value*1" color="#1890FF" shape="pointer"/>
      
      
      
      
      
      
      
      
      
      <Annotation.Arc start={[0, 1]} end={[value, 1]} style={{
        stroke: "l (270) 0:rgba(255, 146, 255, 1) .5:rgba(100, 268, 255, 1) 1:rgba(115, 100, 255, 1)",
        lineWidth: 18,
        lineDash: null,
    }}/>
    </Chart>
    <ValueText>{format ? format(value) : value}</ValueText>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n"])));
var ValueText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  bottom: 20%;\n  transform: translate(-50%);\n  font-size: 14px;\n  color: ", ";\n"], ["\n  position: absolute;\n  left: 50%;\n  bottom: 20%;\n  transform: translate(-50%);\n  font-size: 14px;\n  color: ", ";\n"])), MAIN_COLOR);
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import DataSet from "@antv/data-set";
import { Axis, Chart, Coordinate, G2, Geom, Legend, registerShape, Tooltip } from "bizcharts";
import { assign, map } from "lodash";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
import numeral from "numeral";
function getTextAttrs(cfg) {
    return assign({}, cfg.style, {
        fontSize: cfg.data.size,
        text: cfg.data.text,
        textAlign: "center",
        fontFamily: cfg.data.font,
        fill: cfg.color,
        textBaseline: "Alphabetic"
    });
}
registerShape("point", "cloud", {
    draw: function (cfg, container) {
        // console.log("cloud cfg", cfg);
        var attrs = getTextAttrs(cfg);
        var textShape = container.addShape("text", {
            attrs: assign(attrs, {
                x: cfg.x,
                y: cfg.y
            })
        });
        if (cfg.data.rotate) {
            G2.Util.rotate(textShape, cfg.data.rotate * Math.PI / 180);
        }
        return textShape;
    }
});
export var TgiCloudChart = function (_a) {
    var _b = _a.height, height = _b === void 0 ? 360 : _b, data = _a.data;
    var renderTooltip = function (items, title) {
        return <TooltipWrapper>
      <Title>{items[0].data.xAxis}</Title>
      {map(items, function (item, idx) {
            return (<TooltipContent key={item.title + "-" + item.name + "-" + item.value}>
              <div>
                <ItemTooltipsCircle color={item.color}/>
                {idx === 0 ? l("dashboard_percent", "Percentage") : "TGI"}:
                {idx === 0 ? numeral(item.value).format("0.0%") : numeral(item.value).format("0,0")}
              </div>
            </TooltipContent>);
        })}
    </TooltipWrapper>;
    };
    var dv = new DataSet.View().source(data);
    var range = dv.range("yAxis");
    var min = range[0];
    var max = range[1];
    dv.transform({
        type: "tag-cloud",
        fields: ["xAxis", "yAxis"],
        size: [600, 500],
        font: "Verdana",
        padding: 0,
        timeInterval: 5000,
        rotate: function () {
            // tslint:disable-next-line:no-bitwise
            var random = ~~(Math.random() * 4) % 4;
            if (random === 2) {
                random = 0;
            }
            return random * 90; // 0, 90, 270
        },
        fontSize: function (d) {
            if (d.value) {
                return ((d.value - min) / (max - min)) * (40 - 12) + 12;
            }
            return 0;
        }
    });
    var scale = {
        xAxis: {
            nice: false
        },
        yAxis: {
            nice: false
        }
    };
    return (<Wrapper>
      <Chart height={height} data={dv.rows} scale={scale} autoFit={true} padding={"auto"} appendPadding={[10, 10, -6, 10]}>
        <Tooltip showTitle={false}>
          {function (title, items) {
        return renderTooltip(items, title);
    }}
        </Tooltip>
        <Coordinate reflect="y"/>
        <Axis name="x" visible={false}/>
        <Axis name="y" visible={false}/>
        <Legend visible={false}/>
        <Geom type="point" position="x*y" color={["yAxis2", "#0081C6-#857edb-#FFB32C-#fff07f"]} 
    // color={["yAxis2", (yAxis2: any) => {
    //   if(!yAxis2) return "#ffffff"
    //   return yAxis2 >= 100 ? CHARTS_DEFAULT_COLOR_LIST[1] :
    //     (["#ddecff", "#c9f7ff", "#a7cfff", "#6696f6",
    //       "#3c66ff"][(Math.ceil(yAxis2 / 20) - 1)]);
    // }]}
    shape="cloud" tooltip="yAxis*yAxis2"/>
      </Chart>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  width: 100%;\n"], ["\n  overflow: hidden;\n  width: 100%;\n"])));
var TooltipWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px 0;\n"], ["\n  padding: 10px 0;\n"])));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var ItemTooltipsCircle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"], ["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"])), function (props) { return props.color; });
var TooltipContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"], ["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

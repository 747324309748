var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useEffect, useRef } from "react";
export var MyEcharts = function (_a) {
    var option = _a.option, style = _a.style, onClick = _a.onClick, props = __rest(_a, ["option", "style", "onClick"]);
    var echartsRef = useRef(null);
    var myCharts;
    useEffect(function () {
        if (echartsRef.current && option) {
            myCharts = echarts.getInstanceByDom(echartsRef.current);
            if (myCharts) {
                myCharts.setOption(option);
            }
            else {
                echarts.init(echartsRef.current);
                myCharts = echarts.getInstanceByDom(echartsRef.current);
                myCharts.setOption(option);
            }
            window.addEventListener("resize", function () {
                if (myCharts) {
                    myCharts.resize();
                }
            });
        }
    }, [JSON.stringify(option), echartsRef.current]);
    useEffect(function () {
        if (myCharts && onClick) {
            myCharts.off("click");
            myCharts.on("click", onClick);
        }
    }, [myCharts]);
    return <div style={__assign({ width: "100%", height: "100%" }, style)} ref={echartsRef} {...props}/>;
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
var RangePicker = DatePicker.RangePicker;
export var MyRangePicker = function (_a) {
    var value = _a.value, onChange = _a.onChange, disabled = _a.disabled, style = _a.style, err = _a.err, errorMsg = _a.errorMsg, noMsg = _a.noMsg, _b = _a.defaultChange, defaultChange = _b === void 0 ? false : _b, props = __rest(_a, ["value", "onChange", "disabled", "style", "err", "errorMsg", "noMsg", "defaultChange"]);
    var _c = useState(null), hackValue = _c[0], setHackValue = _c[1];
    var _d = useState(null), dates = _d[0], setDates = _d[1];
    var onOpenChange = function (open) {
        if (open) {
            setHackValue([null, null]);
            setDates([null, null]);
        }
        else {
            setHackValue(null);
        }
    };
    var initValue = function (value) {
        if (value && value.length > 0 && value[0] && value[0] !== "" && value[1] && value[1] !== "") {
            return [moment(value[0]), moment(value[1])];
        }
        return undefined;
    };
    var initProps = function () {
        var result = __assign(__assign({ disabled: disabled,
            err: err, onCalendarChange: defaultChange ? undefined : (function (val) { return setDates(val); }), onOpenChange: defaultChange ? undefined : onOpenChange }, props), { getPopupContainer: function (triggerNode) { return triggerNode.parentNode; } });
        if (value)
            result.value = initValue(hackValue || value);
        if (onChange)
            result.onChange = function (dates, dateString) {
                onChange(dateString[0] === "" ? undefined : dateString);
            };
        return result;
    };
    return (<Wrapper>
    <MRangePicker style={style} {...initProps()}/>
    {err && !noMsg ? <Msg>{errorMsg || l("common_required_tips", "*必填")}</Msg> : null}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n"], ["\n  position: relative;\n  display: inline-block;\n"])));
var MRangePicker = styled(RangePicker)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.ant-picker {\n    border-color: ", ";\n  }\n"], ["\n  &.ant-picker {\n    border-color: ", ";\n  }\n"])), function (props) { return props.err ? "#f20" : "#d9d9d9"; });
var Msg = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  bottom: -18px;\n  left: 6px;\n  color: #f20;\n  font-size: 12px;\n  white-space: nowrap;\n"], ["\n  position: absolute;\n  bottom: -18px;\n  left: 6px;\n  color: #f20;\n  font-size: 12px;\n  white-space: nowrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

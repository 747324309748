var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import styled from "styled-components";
import { merge } from "lodash";
export var MAIN_COLOR = ((_a = window.skin) === null || _a === void 0 ? void 0 : _a.mainColor) || "#1B5EC8"; // 举例暂无主色调
export var WARING_COLOR = "#646464"; // 提醒色
export var COLOR_LIST = [
    "#0263FF", "#33E7B1", "#40A9FF", "#36CF86",
    "#4081FF", "#36CFC9", "#3733F9", "#73D13D",
    "#71D1FB", "#51DA5E", "#FC532D", "#FC756E",
    "#FA6C1C", "#FD7394", "#FF5732", "#F5222D",
    "#FAAD14", "#F03443", "#FADB14", "#FF4975"
];
export var CHARTS_DEFAULT_COLOR_LIST = merge([], COLOR_LIST);
export var ANOTHER_DEFAULT_COLOR_LIST = [
    "#5697FF", "#7DFFD8", "#7BC4FF", "#78F6BA",
    "#7EAAFF", "#8BEDE9", "#7572FD", "#8FCCFE",
    "#A8E2FB", "#A3FDAC"
];
export var MainWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), MAIN_COLOR);
export var TRANSFORM_COLOR_LIST = [
    "#ddd", "#fff", "#f6d3ce", "#d99797",
    "#ba3333", "#790606"
];
export var ANTD_TAG_COLOR_LIST = [
    "blue", "magenta", "green", "orange", "lime", "gold", "red", "cyan", "volcano", "purple"
];
export var TASK_NAME_WIDTH = 220;
export var SEGMENT_NAME_WIDTH = 220;
export var ID_WIDTH = 120;
var templateObject_1;

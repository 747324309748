var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useState } from "react";
import { Input } from "antd";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
export var MyInput = function (_a) {
    var value = _a.value, errorMsg = _a.errorMsg, validateReg = _a.validateReg, onChange = _a.onChange, errorCallBack = _a.errorCallBack, style = _a.style, disabled = _a.disabled, err = _a.err, noMsg = _a.noMsg, props = __rest(_a, ["value", "errorMsg", "validateReg", "onChange", "errorCallBack", "style", "disabled", "err", "noMsg"]);
    var _b = useState(), hasMsg = _b[0], setHasMsg = _b[1];
    var onInputChange = function (e) {
        onChange ? onChange(e) : null;
        if (validateReg) {
            var hasMsgFlag = getHasError(e.target.value);
            setHasMsg(hasMsgFlag);
            errorCallBack ? errorCallBack(hasMsgFlag) : null;
        }
    };
    var getHasError = function (value) {
        if (!value || value === -"")
            return false;
        if (typeof validateReg === "boolean") {
            return validateReg;
        }
        if (typeof validateReg === "function") {
            return validateReg(value);
        }
        // tslint:disable-next-line:no-eval
        if (eval("/" + validateReg + "/") instanceof RegExp) {
            var reg = new RegExp(validateReg);
            return !reg.test(value);
        }
        return false;
    };
    return (<Wrapper style={style} {...props}>
    <MInput disabled={disabled} value={value} hasMsg={hasMsg} err={err} onChange={onInputChange} {...props}/>
    {hasMsg && !err ? <Msg>{errorMsg}</Msg> : null}
    {err && !noMsg ? <Msg>{errorMsg || l("common_required_tips", "*必填")}</Msg> : null}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n  min-width: 140px;\n"], ["\n  position: relative;\n  display: inline-block;\n  min-width: 140px;\n"])));
var MInput = styled(Input)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.ant-input {\n    width: 100%;\n    border-color: ", ";\n  }\n  &.ant-input:focus, &.ant-input:hover, &.ant-input.ant-input-focused {\n    border-color: ", ";\n  }\n"], ["\n  &.ant-input {\n    width: 100%;\n    border-color: ", ";\n  }\n  &.ant-input:focus, &.ant-input:hover, &.ant-input.ant-input-focused {\n    border-color: ", ";\n  }\n"])), function (props) { return props.hasMsg || props.err ? "#f20" : "#d9d9d9"; }, function (props) { return props.hasMsg ? "#f20" : "#d9d9d9"; });
var Msg = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  bottom: -18px;\n  left: 6px;\n  color: #f20;\n  font-size: 12px;\n  white-space: nowrap;\n"], ["\n  position: absolute;\n  bottom: -18px;\n  left: 6px;\n  color: #f20;\n  font-size: 12px;\n  white-space: nowrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

export var CHART_TYPE;
(function (CHART_TYPE) {
    CHART_TYPE["PIE"] = "pie";
    CHART_TYPE["BAR"] = "bar";
    CHART_TYPE["POINT"] = "point";
    CHART_TYPE["Line"] = "line";
    CHART_TYPE["BAR_LINE"] = "barLine";
    CHART_TYPE["PANEL"] = "panel";
    CHART_TYPE["CLOUD"] = "cloud";
    CHART_TYPE["RADAR"] = "radar";
    CHART_TYPE["ROUTE_MAP"] = "routeMap";
    CHART_TYPE["AREA"] = "area";
    CHART_TYPE["MIGRATION_BAR"] = "migrationBar";
    CHART_TYPE["DIFF_MAP"] = "diffMap";
    CHART_TYPE["COVERAGE_RATE"] = "coverageRateBar";
    CHART_TYPE["TABLE"] = "table";
    CHART_TYPE["RING"] = "ring";
    CHART_TYPE["NIGHTINGALE"] = "nightingale";
})(CHART_TYPE || (CHART_TYPE = {}));

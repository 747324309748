var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useState } from "react";
import { getHasPagePermission, getUpdating } from "./helpers/async";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import { msgError } from "@helpers/axiosHelper";
import { MySpin } from "@components/baseComponents/mySpin";
import { Updating } from "@pages/defaultPage/updating";
import { ForbiddenPage } from "@pages/defaultPage/403";
import { AIModal } from "@components/contentWrapper/components/aiModal";
import moment from "moment";
import { getImgUrl } from "@helpers/const";
export var AI_MODAL_WIDTH = 400;
export var AI_MODAL_HEIGHT = 600;
export var RouterInterceptor = function (_a) {
    var children = _a.children;
    var history = useHistory();
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), updating = _c[0], setUpdating = _c[1];
    var _d = useState({}), updatingData = _d[0], setUpdatingData = _d[1];
    var _e = useState(), noPermission = _e[0], setNoPermission = _e[1];
    var _f = useState(false), AIVisible = _f[0], setAIVisible = _f[1];
    var _g = useState(0), timmer = _g[0], setTimmer = _g[1];
    useEffect(function () {
        getHasPagePermissionApi();
        getUpdating(location.pathname)
            .then(function (res) {
            setUpdating(res.data.isUpdating);
            setUpdatingData(res.data);
        })
            .catch(function (err) {
            setUpdating(false);
        });
    }, [location.pathname]);
    var getHasPagePermissionApi = function () {
        if (location.pathname.indexOf("403") > -1)
            return;
        var pathname = location.pathname;
        var isOnlyPage = false;
        if (pathname.indexOf("/page/") > -1) {
            isOnlyPage = true;
            pathname = pathname.replace("/page", "/techHub");
        }
        getHasPagePermission(pathname + location.search)
            .then(function (res) {
            if (res.data.redirect) {
                history.push("/reEnter?directPath=" + (isOnlyPage ? res.data.url.replace("/techHub", "/page") : res.data.url));
            }
            else {
                setLoading(false);
            }
        })
            .catch(function (err) {
            setLoading(false);
            if (err.response.data.code === 10400 && err.response.data.data.redirect) {
                setNoPermission(true);
            }
            msgError(err);
        });
    };
    var handleMouseDown = function (event, id) {
        var dragElement = document.getElementById(id);
        setTimmer(moment());
        event.preventDefault(); // 阻止默认的浏览器行为
        var offsetX = event.clientX - dragElement.offsetLeft; // 计算鼠标点击位置到元素左侧的水平偏移量
        var offsetY = event.clientY - dragElement.offsetTop; // 计算鼠标点击位置到元素上部的垂直偏移量
        var isDragging = false;
        var handleDragging = function (event) {
            isDragging = true;
            var aiLeft = event.clientX - offsetX > 0 ?
                Math.min(event.clientX - offsetX, window.innerWidth - dragElement.clientWidth) : 0;
            var aiTop = event.clientY - offsetY > 0 ?
                Math.min(event.clientY - offsetY, window.innerHeight - dragElement.clientHeight) : 0;
            dragElement.style.left = aiLeft + "px"; // 设置元素新的水平位置
            dragElement.style.top = aiTop + "px"; // 设置元素新的垂直位置
            event.stopPropagation(); // 防止事件传播到其他元素
        };
        var stopDragging = function () {
            document.removeEventListener("mousemove", handleDragging); // 从documnt中删除mousemove事件的监听器
            document.removeEventListener("mouseup", stopDragging); // 从document中删除mouseup事件的监听器
        };
        document.addEventListener("mousemove", handleDragging); // 向document中添加mousemove事件的监听器
        document.addEventListener("mouseup", stopDragging); // 向document中添加mouseup事件的监听器
    };
    var onAIClick = function () {
        var sTimmer = moment().diff(timmer, "milliseconds");
        if (sTimmer < 200) {
            var aiBtn = document.getElementById("ai");
            var aiModal = document.getElementById("aiModal");
            var aiLeft = aiBtn.offsetLeft - AI_MODAL_WIDTH;
            aiLeft = aiLeft > 0 ? Math.min(aiLeft, window.innerWidth - AI_MODAL_WIDTH) : 0;
            var aiTop = aiBtn.offsetTop - AI_MODAL_HEIGHT;
            aiTop = aiTop > 0 ? Math.min(aiTop, window.innerHeight - AI_MODAL_HEIGHT) : 0;
            setAIVisible(true);
            aiModal.style.left = aiLeft + "px"; // 设置元素新的水平位置
            aiModal.style.top = aiTop + "px"; // 设置元素新的垂直位置
        }
    };
    var showAIBtn = function () {
        return location.pathname.indexOf("/caas/") > -1;
    };
    if (updating) {
        return <Updating data={updatingData}/>;
    }
    return loading ? <MySpin spinning={loading}><Loading /></MySpin> : (<Wrapper>
    {noPermission ? <ForbiddenPage /> : children}
    {noPermission ? <Redirect to={"/techHub/403"}/> : null}
    {showAIBtn() ? <AIBlock id={"ai"} onClick={onAIClick} 
    // onMouseDown={handleMouseDown}
    onMouseDown={function (e) { return handleMouseDown(e, "ai"); }}/> : null}
    <AIModal width={AI_MODAL_WIDTH} height={AI_MODAL_HEIGHT} onMouseDown={function (e) { return handleMouseDown(e, "aiModal"); }} AIVisible={AIVisible} setAIVisible={setAIVisible}/>
  </Wrapper>);
};
var Loading = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  width: 100vw;\n"], ["\n  height: 100vh;\n  width: 100vw;\n"])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100vh;\n  width: 100vw;\n"], ["\n  height: 100vh;\n  width: 100vw;\n"])));
var AIBlock = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  z-index: 9999;\n  position: fixed;\n  bottom: 10px;\n  right: 10px;\n  background: url(", ") center no-repeat;\n  background-size: 100%;\n  height: 84px;\n  width: 84px;\n  border-radius: 100%;\n  box-shadow: 0 0 6px #aaa;\n  cursor: pointer;\n"], ["\n  z-index: 9999;\n  position: fixed;\n  bottom: 10px;\n  right: 10px;\n  background: url(", ") center no-repeat;\n  background-size: 100%;\n  height: 84px;\n  width: 84px;\n  border-radius: 100%;\n  box-shadow: 0 0 6px #aaa;\n  cursor: pointer;\n"])), getImgUrl("sage/ai_logo.png"));
var templateObject_1, templateObject_2, templateObject_3;

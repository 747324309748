import numeral from "numeral";
// format table item聚合函数
export var formatItemData = function (value, type, format) {
    if (type) {
        switch (type) {
            case "number" /* NUMBER */:
                if (typeof value === "string") {
                    return value;
                }
                return value && value !== 0 && value !== "" && value !== "-" ? numeral(value).format(format ? format : "0,0") : "-";
            default:
                return value;
        }
    }
    return value;
};

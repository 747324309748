var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Progress } from "antd";
import { l } from "@helpers/multiLangaugeHelper";
import { asyncExport } from "@components/asyncExport/async";
import queryString from "query-string";
import { msgError } from "@helpers/axiosHelper";
import { MyModal } from "@components/baseComponents/myModal";
export var AsyncExportLink = function () {
    var params = queryString.parse(location.search);
    var uid = params.uid, type = params.type;
    var _a = useState(0), progress = _a[0], setProgress = _a[1];
    useEffect(function () {
        if (uid && type) {
            getProgress();
        }
    }, []);
    var getProgress = function () {
        var data = __assign({}, params);
        asyncExport(data)
            .then(function (res) {
            if (res.data.url) {
                setProgress(res.data.percent * 100);
                location.href = res.data.url;
            }
            else {
                setProgress(res.data.percent * 100);
                setTimeout(function () { return getProgress(); }, 5000);
            }
        })
            .catch(function (err) {
            msgError(err);
        });
    };
    return (<MyModal visible={true} closable={false} maskClosable={false} footer={null}>
    {uid && type ? <>
      <p>{progress === 100 ? l("async_export_finished", "完成下载，请自行关闭当前页面") : l("async_export_data_downloading", "数据下载中。。。")}</p>
      <Progress percent={progress}/>
    </> : <div>{l("async_export_data_building_error", "请求信息有误")}</div>}
  </MyModal>);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import { l } from "@helpers/multiLangaugeHelper";
export var MyTextArea = function (_a) {
    var style = _a.style, err = _a.err, errorMsg = _a.errorMsg, noMsg = _a.noMsg, props = __rest(_a, ["style", "err", "errorMsg", "noMsg"]);
    return (<Wrapper style={style} {...props}>
      <MTextArea err={err} style={style} {...props}/>
      {err && !noMsg ? <Msg>{errorMsg || l("common_required_tips", "*必填")}</Msg> : null}
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n  min-width: 140px;\n"], ["\n  position: relative;\n  display: inline-block;\n  min-width: 140px;\n"])));
var MTextArea = styled(TextArea)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.ant-input {\n    border-color: ", ";\n  }\n"], ["\n  &.ant-input {\n    border-color: ", ";\n  }\n"])), function (props) { return props.hasMsg || props.err ? "#f20" : "#d9d9d9"; });
var Msg = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  bottom: -18px;\n  left: 6px;\n  color: #f20;\n  font-size: 12px;\n  white-space: nowrap;\n"], ["\n  position: absolute;\n  bottom: -18px;\n  left: 6px;\n  color: #f20;\n  font-size: 12px;\n  white-space: nowrap;\n"])));
var templateObject_1, templateObject_2, templateObject_3;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Tag } from "antd";
import { MyTable } from "@components/myTable/myTable";
import { comparisonColumns, segmentListColumns } from "../helpers/initData";
import { find, map, merge, set } from "lodash";
import { msgError } from "@helpers/axiosHelper";
import { l } from "@helpers/multiLangaugeHelper";
import { getColorListApi, getComparisonDetailList, getComparisonList } from "../helpers/async";
import { useParams } from "react-router-dom";
import { BatchSyncExport, EXPORT_TYPE_LIST } from "@components/batchExport/batchSyncExport";
import { STATUS_ID_ENUM } from "@/common/const";
import { TableWrapper } from "@components/common/tableWrapper";
import { MyTableSearch } from "@components/baseComponents/myTableSearch";
import { MyModal } from "@components/baseComponents/myModal";
export var ComparisonList = function () {
    var profileType = useParams().profileType;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState({}), conditions = _b[0], setConditions = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState({
        pageSize: 10,
        current: 1,
    }), page = _d[0], setPage = _d[1];
    var _e = useState({}), record = _e[0], setRecord = _e[1];
    var _f = useState(false), segmentListModalVisible = _f[0], setSegmentListModalVisible = _f[1];
    var _g = useState([]), segmentList = _g[0], setSegmentList = _g[1];
    var _h = useState([]), colorList = _h[0], setColorList = _h[1];
    useEffect(function () {
        getColorList(function () { return onSearch(conditions, page); });
    }, [location.pathname]);
    var getColorList = function (callback) {
        getColorListApi(profileType)
            .then(function (res) {
            setColorList(res.data);
            callback();
        })
            .catch(msgError);
    };
    var onSearch = function (conditions, page) {
        var data = __assign(__assign({ conditions: conditions }, page), { profileType: profileType });
        setConditions(conditions);
        setLoading(true);
        getComparisonList(data)
            .then(function (res) {
            setData(res.data.list);
            setPage(merge({}, page, { total: res.data.total }));
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var onConditionsChange = function (v, pathKey, noSearch) {
        var newConditions = merge({}, conditions);
        set(newConditions, pathKey, v);
        setConditions(newConditions);
        if (!noSearch) {
            onSearch(newConditions, merge({}, page, { current: 1 }));
        }
    };
    var openSegmentListModal = function (record) {
        setRecord(record);
        getComparisonDetailList(record.comparisonId)
            .then(function (res) {
            setSegmentList(res.data.list);
            setSegmentListModalVisible(true);
        })
            .catch(msgError);
    };
    var renderDownload = function (record) { return (<BatchSyncExport url={EXPORT_TYPE_LIST.PROFILING_COMPARISON_EXPORT} btnType={"href"} disabled={record.statusId !== STATUS_ID_ENUM.COMPLETED} params={__assign(__assign({}, record), { profileType: profileType })}/>); };
    var renderDimension = function (record) {
        return map(record.dimension, function (item) {
            var colorObj = find(colorList, function (colorItem) { return colorItem.label === item; });
            var color = colorObj ? colorObj.value : null;
            return (<Tag style={{ margin: "0 8px 6px 0" }} color={color}>{item}</Tag>);
        });
    };
    var applyObject = {
        comparisonName: openSegmentListModal,
        dimension: renderDimension,
        downloadItem: renderDownload,
    };
    var tableProps = {
        data: data,
        columns: comparisonColumns(profileType),
        page: page,
        loading: loading,
        applyObject: applyObject,
        onChange: function (page) { return onSearch(conditions, page); }
    };
    var segmentListTableProps = {
        data: segmentList,
        columns: segmentListColumns(),
        page: false,
        loading: false,
    };
    return (<>
    <TableWrapper>
      <MyTableSearch placeholder={l("common_search", "Search") + "..."} onChange={function (v) { return onConditionsChange(v.target.value, "keyword", true); }} onSearch={function (keyword) { return onSearch(merge({}, conditions, { keyword: keyword }), merge({}, page, { current: 1 })); }}/>
      <MyTable {...tableProps}/>
    </TableWrapper>
    <MyModal title={record.comparisonName} visible={segmentListModalVisible} maskClosable={false} onCancel={function () { return setSegmentListModalVisible(false); }} footer={""}>
      <MyTable {...segmentListTableProps}/>
    </MyModal>
  </>);
};

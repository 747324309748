var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled from "styled-components";
export var MyFormWrapper = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (<FormWrapper {...props}>{children}</FormWrapper>);
};
export var MyItemWrapper = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (<ItemWrapper {...props}>{children}</ItemWrapper>);
};
export var MyModalFormWrapper = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (<ModalFormWrapper {...props}>{children}</ModalFormWrapper>);
};
var FormWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n"])));
var ModalFormWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n"])));
var ItemWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0 8px;\n  display: inline-flex;\n  > div {\n    min-width: 340px;\n  }\n  @media (min-width: 1379px) and (max-width: 1720px) {\n    width: 33.33%;\n  }\n  @media (min-width: 1720px) {\n    width: 25%;\n  }\n  @media (max-width: 1380px) {\n    width: 50%;\n  }\n"], ["\n  padding: 0 8px;\n  display: inline-flex;\n  > div {\n    min-width: 340px;\n  }\n  @media (min-width: 1379px) and (max-width: 1720px) {\n    width: 33.33%;\n  }\n  @media (min-width: 1720px) {\n    width: 25%;\n  }\n  @media (max-width: 1380px) {\n    width: 50%;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;

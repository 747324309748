var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useState } from "react";
import { Badge, Button, Tag, Tooltip } from "antd";
import { MyTable } from "@components/myTable/myTable";
import { createComparisonColumns, segmentColumns } from "../helpers/initData";
import { find, findIndex, map, merge, remove, set } from "lodash";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { l } from "@helpers/multiLangaugeHelper";
import { createComparison, getColorListApi, getSegmentList } from "../helpers/async";
import { useHistory, useParams } from "react-router-dom";
import { BatchSyncExport, EXPORT_TYPE_LIST } from "@components/batchExport/batchSyncExport";
import { STATUS_ID_ENUM, STATUS_OPTIONS_TYPE } from "@/common/const";
import { getActionApplyObject } from "@/common/commonAction";
import { PROFILE_TYPE } from "@pages/profiling/profile/helpers/const";
import { TableWrapper } from "@components/common/tableWrapper";
import { MyTableSearch } from "@components/baseComponents/myTableSearch";
import { MyInput } from "@components/baseComponents/myInput";
import { getPageDomPermission } from "@components/routerInterceptor/helpers/async";
import { checkPIPLEnabled } from "@/helpers/async";
import { MyModal } from "@components/baseComponents/myModal";
export var SegmentList = function () {
    var profileType = useParams().profileType;
    var history = useHistory();
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState({}), conditions = _b[0], setConditions = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState({
        pageSize: 10,
        current: 1,
    }), page = _d[0], setPage = _d[1];
    var _e = useState([]), selectedRowKeys = _e[0], setSelectedRowKeys = _e[1];
    var _f = useState([]), selectedRows = _f[0], setSelectedRows = _f[1];
    var _g = useState(false), createModalVisible = _g[0], setCreateModalVisible = _g[1];
    var _h = useState(), comparisonName = _h[0], setComparisonName = _h[1];
    var _j = useState([]), colorList = _j[0], setColorList = _j[1];
    var _k = useState({}), permission = _k[0], setPermission = _k[1];
    var _l = useState(), PIPLDisabledBtn = _l[0], setPIPLDisabledBtn = _l[1];
    useEffect(function () {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setComparisonName([]);
        setConditions({});
        setPage({
            pageSize: 10,
            current: 1,
        });
        getColorList(function () { return onSearch(conditions, page); });
        getPageDomPermission(location.pathname)
            .then(function (res) {
            setPermission(res.data || {});
        });
        checkPIPLEnabled()
            .then(function (res) {
            setPIPLDisabledBtn(res.data.btnDisabled);
        });
    }, [location.pathname]);
    var getColorList = function (callback) {
        getColorListApi(profileType)
            .then(function (res) {
            setColorList(res.data);
            callback();
        })
            .catch(msgError);
    };
    var onSearch = function (conditions, page) {
        var data = __assign(__assign({ conditions: conditions }, page), { profileType: profileType });
        setConditions(conditions);
        setLoading(true);
        getSegmentList(data)
            .then(function (res) {
            setData(res.data.list);
            setPage(merge({}, page, { total: res.data.total }));
            setLoading(false);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var onConditionsChange = function (v, pathKey, noSearch) {
        var newConditions = merge({}, conditions);
        set(newConditions, pathKey, v);
        setConditions(newConditions);
        if (!noSearch) {
            onSearch(newConditions, merge({}, page, { current: 1 }));
        }
    };
    var onRowSelect = function (currentSelectedRowKeys, currentSelectedRows) {
        var newSelectedRowKeys = merge([], selectedRowKeys);
        var newSelectedRows = merge([], selectedRows);
        remove(newSelectedRowKeys, function (segmentId) {
            return (findIndex(data, function (item) { return segmentId === item.segmentId; }) > -1);
        });
        remove(newSelectedRows, function (objItem) {
            return (findIndex(data, function (item) { return objItem.segmentId === item.segmentId; }) > -1);
        });
        newSelectedRowKeys.push.apply(newSelectedRowKeys, currentSelectedRowKeys);
        newSelectedRows.push.apply(newSelectedRows, currentSelectedRows);
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(newSelectedRows);
    };
    var openCreateModal = function () {
        setComparisonName(null);
        setCreateModalVisible(true);
    };
    var createTask = function () {
        history.push("/techHub/profiling/" + profileType + "/edit");
    };
    var create = function () {
        if (!(comparisonName && comparisonName !== "")) {
            msgError(l("profiling_profile_create_comparison_err_tips", "please input comparison name"));
            return;
        }
        if (!comparisonName.match(/^[0-9a-zA-Z_]+$/)) {
            msgError(l("profiling_profile_create_comparison_letter_err_tips", "Comparison name only contain alphanumeric characters and underline"));
            return;
        }
        var data = {
            taskIds: selectedRows.map(function (item) { return item.taskId; }),
            comparisonName: comparisonName,
            profileType: profileType
        };
        createComparison(data)
            .then(function (res) {
            msgSuccess(res);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setCreateModalVisible(false);
            onSearch(conditions, page);
        })
            .catch(msgError);
    };
    var deleteSelected = function (record) {
        var newSelectedRowKeys = merge([], selectedRowKeys);
        var newSelectedRows = merge([], selectedRows);
        remove(newSelectedRowKeys, function (key) { return key === record.segmentId; });
        remove(newSelectedRows, function (item) { return item.segmentId === record.segmentId; });
        setSelectedRows(newSelectedRows);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    var renderDownload = function (record) { return (<BatchSyncExport style={{ float: "left", marginRight: "10px", display: record.actionDownload ? "inline-block" : "none" }} url={EXPORT_TYPE_LIST.PROFILING_SEGMENT_EXPORT} btnType={"href"} 
    // disabled={record.statusId !== STATUS_ID_ENUM.COMPLETED}
    params={__assign(__assign({}, record), { profileType: profileType })}/>); };
    var renderDimension = function (record) {
        return map(record.dimension, function (item) {
            var colorObj = find(colorList, function (colorItem) { return colorItem.label === item; });
            var color = colorObj ? colorObj.value : null;
            return (<Tag style={{ margin: "0 8px 0 0" }} color={color}>{item}</Tag>);
        });
    };
    var actionType = function () {
        switch (profileType) {
            case PROFILE_TYPE.M_PROFILE:
                return STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE;
            case PROFILE_TYPE.MH_PROFILE:
                return STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE;
            case PROFILE_TYPE.S_PROFILE:
                return STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE;
        }
    };
    var applyObject = __assign(__assign({ delItem: deleteSelected, downloadItem: renderDownload, dimension: renderDimension }, getActionApplyObject(actionType(), "taskId", function () { return onSearch(conditions, page); })), { segmentName: function (record) { return history.push("/techHub/profiling/" + profileType + "/view?id=" + record.taskId); } });
    var tableProps = {
        rowKey: "segmentId",
        data: data,
        columns: segmentColumns(profileType),
        page: page,
        loading: loading,
        applyObject: applyObject,
        onChange: function (page) { return onSearch(conditions, page); },
        rowSelection: {
            hideSelectAll: true,
            selectedRowKeys: selectedRowKeys,
            onChange: onRowSelect,
            getCheckboxProps: function (record) { return ({
                disabled: record.statusId !== STATUS_ID_ENUM.COMPLETED ||
                    (selectedRows.length > 0 &&
                        (record.masterAdvertiserId ?
                            (record.masterAdvertiserId !== selectedRows[0].masterAdvertiserId) :
                            (record.advertiserId !== selectedRows[0].advertiserId))) ||
                    (selectedRows.length > 0 &&
                        (record.profilingTypeId !== selectedRows[0].profilingTypeId))
            }); }
        }
    };
    var createModalTableProps = {
        data: selectedRows,
        columns: createComparisonColumns(),
        page: false,
        loading: loading,
        applyObject: applyObject,
        onChange: function (page) { return onSearch(conditions, page); },
    };
    return (<>
    <TableWrapper>
      <MyTableSearch placeholder={l("common_search", "Search") + "..."} onChange={function (v) { return onConditionsChange(v.target.value, "keyword", true); }} onSearch={function (keyword) { return onSearch(merge({}, conditions, { keyword: keyword }), merge({}, page, { current: 1 })); }} suffix={<>
          {permission.hideCreate ? null :
        <Tooltip title={l("function_unavailable_by_pipl", "Sorry,impact by PIPL, this function currently unavailable")} placement={"bottom"} visible={PIPLDisabledBtn ? undefined : false}>
              <Button type="primary" onClick={createTask} style={{ marginRight: "10px" }} disabled={permission.disableCreate || PIPLDisabledBtn}>
                {l("common_create_task", "新建任务")}
              </Button>
            </Tooltip>}
          {permission.hideCreateCompare ? null :
        <Badge count={selectedRowKeys.length}>
              <Tooltip title={l("function_unavailable_by_pipl", "Sorry,impact by PIPL, this function currently unavailable")} placement={"bottom"} visible={PIPLDisabledBtn ? undefined : false}>
                <Button type="primary" disabled={selectedRowKeys.length < 2 || permission.disableCreate || PIPLDisabledBtn} onClick={openCreateModal}>
                  {l("profiling_profile_create_comparison", "Create Comparison")}
                </Button>
              </Tooltip>
            </Badge>}
        </>}/>
      <MyTable {...tableProps}/>
    </TableWrapper>
    <MyModal title={l("profiling_profile_create_comparison", "Create Comparison")} visible={createModalVisible} maskClosable={false} onCancel={function () { return setCreateModalVisible(false); }} okButtonProps={{ disabled: selectedRowKeys.length < 2 }} onOk={create}>
      <MyInput style={{ marginBottom: "10px" }} placeholder={l("profiling_profile_comparison_name", "Comparison Name")} value={comparisonName} onChange={function (v) { return setComparisonName(v.target.value); }}/>
      <MyTable {...createModalTableProps}/>
    </MyModal>
  </>);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { l } from "@/helpers/multiLangaugeHelper";
import * as React from "react";
import styled from "styled-components";
export var ChatRecommend = function () {
    return (<Wrapper>
    <div className={"welcome"}>{l("sage_welcome_title_in_os_title_1", "Hi, I am Sage, I can answer your questions")}</div>
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 14px;\n  margin: 10px auto;\n  background: #999;\n  color: #fff;\n  border-radius: 6px;\n  .welcome {\n    font-size: 18px;\n  }\n"], ["\n  padding: 14px;\n  margin: 10px auto;\n  background: #999;\n  color: #fff;\n  border-radius: 6px;\n  .welcome {\n    font-size: 18px;\n  }\n"])));
var templateObject_1;

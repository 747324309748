var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { Input } from "antd";
var Search = Input.Search;
var timmer;
export var MySearch = function (_a) {
    var onChange = _a.onChange, onSearch = _a.onSearch, _b = _a.allowClear, allowClear = _b === void 0 ? true : _b, GAActionKey = _a.GAActionKey, props = __rest(_a, ["onChange", "onSearch", "allowClear", "GAActionKey"]);
    var onMySearch = function (v, event) {
        if (!event.hasOwnProperty("nativeEvent")) {
            return;
        }
        onSearch ? onSearch(v) : null;
    };
    var onSearchChange = function (v) {
        onChange ? onChange(v) : null;
        var value = v.target.value;
        if (timmer) {
            clearTimeout(timmer);
            timmer = null;
        }
        timmer = setTimeout(function () {
            onSearch(value);
        }, 500);
    };
    return (<Search {...props} allowClear={allowClear} onChange={onSearchChange} onSearch={onMySearch} onPressEnter={null}/>);
};

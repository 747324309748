var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { l } from "@helpers/multiLangaugeHelper";
import styled, { keyframes } from "styled-components";
import { MAIN_COLOR } from "@/common/styled";
export var RefList = function (_a) {
    var item = _a.item, isLast = _a.isLast, getting = _a.getting, txt = _a.txt;
    return (<TxtWrapper>
    {item.knowledgeName ?
        <div className={"knowledgeName"}>{l("sage_knowledge_name", "知识库")}：{item.knowledgeName}</div>
        : null}
    <div className={"txtPop"}>
      <pre style={{ margin: 0, whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: item.msg }}/>
      {isLast && (getting || (txt === null || txt === void 0 ? void 0 : txt.length) > 0) ? <HighlightPoint /> : null}
    </div>
  </TxtWrapper>);
};
var TxtWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  flex: 1;\n  color: #fff;\n  .txtPop {\n    position: relative;\n    margin-bottom: 24px;\n    display: inline-block;\n    padding: 8px 10px;\n    border-radius: 4px;\n    background: #434343;\n    min-width: 60px;\n  }\n  .knowledgeName {\n    position: absolute;\n    top: -20px;\n    margin-bottom: 6px;\n    font-size: 12px;\n    color: #ccc;\n  }\n  .likeWrapper {\n    bottom: -30px;\n    right: 0;\n    position: absolute;\n    padding: 8px;\n    font-size: 12px;\n    .iconClass {\n      margin: 0 4px;\n      cursor: pointer;\n      &.active:hover {\n        color: ", ";\n      }\n      &.tb {\n        transform: rotate(180deg);\n      }\n    }\n  }\n  .ref {\n    font-size: 12px;\n    .item {\n      margin-bottom: 20px;\n    }\n    a {\n      display: inline-block;\n      margin-bottom: 4px;\n      cursor: pointer;\n      text-decoration: underline;\n      color: #fff;\n    }\n  }\n"], ["\n  position: relative;\n  flex: 1;\n  color: #fff;\n  .txtPop {\n    position: relative;\n    margin-bottom: 24px;\n    display: inline-block;\n    padding: 8px 10px;\n    border-radius: 4px;\n    background: #434343;\n    min-width: 60px;\n  }\n  .knowledgeName {\n    position: absolute;\n    top: -20px;\n    margin-bottom: 6px;\n    font-size: 12px;\n    color: #ccc;\n  }\n  .likeWrapper {\n    bottom: -30px;\n    right: 0;\n    position: absolute;\n    padding: 8px;\n    font-size: 12px;\n    .iconClass {\n      margin: 0 4px;\n      cursor: pointer;\n      &.active:hover {\n        color: ", ";\n      }\n      &.tb {\n        transform: rotate(180deg);\n      }\n    }\n  }\n  .ref {\n    font-size: 12px;\n    .item {\n      margin-bottom: 20px;\n    }\n    a {\n      display: inline-block;\n      margin-bottom: 4px;\n      cursor: pointer;\n      text-decoration: underline;\n      color: #fff;\n    }\n  }\n"])), MAIN_COLOR);
var FlashPoint = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    background: none;\n  }\n  100% {\n    background: #fff;\n  }\n"], ["\n  0% {\n    background: none;\n  }\n  100% {\n    background: #fff;\n  }\n"])));
var HighlightPoint = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: -2px;\n  margin-left: 1px;\n  display: inline-block;\n  height: 14px;\n  width: 2px;\n  background: #fff;\n  animation: ", " .6s infinite;\n"], ["\n  margin-bottom: -2px;\n  margin-left: 1px;\n  display: inline-block;\n  height: 14px;\n  width: 2px;\n  background: #fff;\n  animation: ", " .6s infinite;\n"])), FlashPoint);
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useState } from "react";
import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@/utils/antdIcon";
import { l } from "@helpers/multiLangaugeHelper";
import { initPostUrl } from "@helpers/axiosHelper";
import styled from "styled-components";
import { MAIN_COLOR } from "@/common/styled";
export var UPLOAD_TYPE_LIST;
(function (UPLOAD_TYPE_LIST) {
    UPLOAD_TYPE_LIST["MPROFILE_ADMIN"] = "/groupme/audex/profiling/adminManage/getList";
})(UPLOAD_TYPE_LIST || (UPLOAD_TYPE_LIST = {}));
export var BatchUpload = function (_a) {
    var _b = _a.url, url = _b === void 0 ? "/groupme/common/upload" : _b, btnText = _a.btnText, _c = _a.btnType, btnType = _c === void 0 ? "button" : _c, onError = _a.onError, onResponse = _a.onResponse, onUploading = _a.onUploading, errorText = _a.errorText, style = _a.style, accept = _a.accept, type = _a.type, params = _a.params, maxCount = _a.maxCount, listType = _a.listType, icon = _a.icon, _d = _a.showUploadList, showUploadList = _d === void 0 ? false : _d, disabled = _a.disabled, size = _a.size;
    var _e = useState(true), uploadFlag = _e[0], setUploadFlag = _e[1];
    var data = __assign({ type: type }, params);
    var uploadProps = {
        name: "file",
        accept: accept,
        maxCount: maxCount,
        listType: listType,
        disabled: disabled,
        showUploadList: showUploadList,
        action: initPostUrl(url),
        // withCredentials: needCookie,
        data: data ? data : null,
        onChange: function (info) {
            if (info.file.status === "uploading" && uploadFlag) {
                setUploadFlag(false);
                onUploading ? onUploading() : null;
            }
            if (info.file.status === "done") {
                setUploadFlag(true);
                onResponse ? onResponse(info.file) :
                    message.success(info.file && info.file.response && info.file.response.msg ? info.file.response.msg
                        : l("common_upload_success", "上传成功"));
            }
            else if (info.file.status === "error") {
                setUploadFlag(true);
                onError ? onError(info.file.response) :
                    message.error(info.file.response && info.file.response.msg && info.file.response.msg !== "" ?
                        info.file.response.msg :
                        (errorText ?
                            (typeof errorText === "function" ? errorText(info.file) : errorText)
                            : l("common_upload_failed", "上传失败")));
            }
        },
    };
    if (btnType !== "button") {
        return (<>
        <Upload {...uploadProps}>
          <HrefA disabled={disabled} style={style} loading={!uploadFlag} icon={icon ? <UploadOutlined /> : null}>
            {btnText || l("common_upload", "上传")}
          </HrefA>
        </Upload>
      </>);
    }
    return (<>
      <Upload {...uploadProps}>
        <Button disabled={disabled} style={style} type="primary" loading={!uploadFlag} icon={icon ? <UploadOutlined /> : null} size={size}>
          {btnText || l("common_upload", "上传")}
        </Button>
      </Upload>
    </>);
};
var HrefA = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  color: ", ";\n"], ["\n  display: inline-block;\n  color: ", ";\n"])), function (props) { return props.disabled ? "#ddd" : MAIN_COLOR; });
var templateObject_1;

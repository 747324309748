import * as React from "react";
import { MyEcharts } from "@components/echarts/myEcharts";
import { map } from "lodash";
import { l } from "@helpers/multiLangaugeHelper";
import numeral from "numeral";
export var MapRadar = function (_a) {
    var data = _a.data;
    var indicator = map(data, function (item) {
        return { name: item.label, max: 10 };
    });
    var option = {
        tooltip: {
            show: true,
        },
        radar: {
            indicator: indicator,
            radius: "50%",
        },
        series: [{
                type: "radar",
                symbol: "circle",
                symbolSize: 4,
                name: l("dooh_planning_radar_score", "Score"),
                data: [
                    {
                        value: map(data, function (item) { return numeral(item.value).format("0.0"); }),
                        label: {
                            show: true,
                            formatter: function (params) {
                                return numeral(params.value).format("0.0");
                            },
                            // color: "2390FF",
                            fontSize: 12,
                        },
                        lineStyle: {
                            color: "#2390FF"
                        },
                        areaStyle: {
                            color: "rgba(122, 188, 255, 0.3)"
                        },
                    },
                ]
            }]
    };
    return (<MyEcharts option={option}/>);
};

import * as React from "react";
import { Axis, Chart, Coordinate, Interaction, Interval, Legend, Tooltip } from "bizcharts";
import { CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
export var PieChart = function (_a) {
    var _b = _a.height, height = _b === void 0 ? 360 : _b, padding = _a.padding, appendPadding = _a.appendPadding, data = _a.data, percent = _a.percent, color = _a.color, tooltip = _a.tooltip, colorList = _a.colorList, legend = _a.legend, labelContent = _a.labelContent, count = _a.count, countTitle = _a.countTitle, maxItemWidth = _a.maxItemWidth;
    var defaultColorList = colorList ? colorList : CHARTS_DEFAULT_COLOR_LIST;
    var initCols = function () {
        var percent = {
            formatter: function (val) {
                val = val * 100 + "%";
                return val;
            }
        };
        return { percent: percent };
    };
    var scale = initCols();
    return (<Chart height={height} padding={padding} appendPadding={appendPadding} width={"100%"} autoFit data={data} scale={scale}>
      <Coordinate type="theta" radius={0.75}/>
      <Axis visible={false}/>
      <Tooltip showTitle={false}>
        {function (title, items) {
        return tooltip(items, title);
    }}
      </Tooltip>
      <Interval position={percent} adjust="stack" color={color ? [color, defaultColorList] : defaultColorList[1]} style={{
        lineWidth: 1,
        stroke: "#fff",
    }} 
    // label={[percent, {
    //   content: labelContent ? labelContent : (static) => {
    //     return `${static[color]}:
    //      ${static[percent] * 100}%${countTitle ? " - " + countTitle + ":" : ""}${count ? static[count] : ""}`;
    //   },
    // }]}
    state={{
    // selected: {
    //   style: (t) => {
    //     const res = getTheme().geometries.interval.rect.selected.style(t);
    //     return {...res, fill: defaultColorList[defaultColorList.length - 1]}
    //   }
    // }
    }}/>
      <Legend maxItemWidth={300} {...legend}/>
      <Interaction type="element-single-selected"/>
    </Chart>);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { AxisChart } from "@components/charts/axisChart/axisChart";
import styled from "styled-components";
import { map } from "lodash";
export var PointChart = function (_a) {
    var height = _a.height, data = _a.data, padding = _a.padding, appendPadding = _a.appendPadding, point = _a.point, tooltip = _a.tooltip, xScale = _a.xScale, yScale = _a.yScale, colorList = _a.colorList, xAxis = _a.xAxis, yAxis = _a.yAxis, props = __rest(_a, ["height", "data", "padding", "appendPadding", "point", "tooltip", "xScale", "yScale", "colorList", "xAxis", "yAxis"]);
    var pointTooltip = function (items, title) {
        if (tooltip && typeof tooltip === "function") {
            return tooltip(items, title);
        }
        return <Tooltip>
      <Title>{title}</Title>
      {map(items, function (item) {
            var itemData = item.data;
            var sizeValue = itemData[point.size.key];
            if (item.mappingData.shape === "circle") {
                return null;
            }
            return (<TooltipContent>
            <div>
              <ItemTooltipsCircle color={item.color}/>
              {point.yAxisTitle || point.yAxis}: {point.format ?
                point.format(item.data[point.yAxis]) : item.data[point.yAxis]}
            </div>
            <div>
              {point.size.title || point.size.key}: {point.size.format ? point.size.format(sizeValue) : sizeValue}
            </div>
          </TooltipContent>);
        })}
    </Tooltip>;
    };
    return (<AxisChart {...props} padding={padding} appendPadding={appendPadding} height={height} data={data} point={point} line={__assign(__assign({}, point), { size: 0 })} colorList={colorList} xScale={xScale} yScale={yScale} xAxis={xAxis} yAxis={yAxis} tooltip={pointTooltip}/>);
};
var Tooltip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px 0;\n"], ["\n  padding: 10px 0;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var ItemTooltipsCircle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"], ["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"])), function (props) { return props.color; });
var TooltipContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 10px 0;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"], ["\n  margin: 10px 0;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

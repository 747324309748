var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useDrag } from "react-dnd";
import React from "react";
import styled from "styled-components";
export var SourceItem = function (_a) {
    var name = _a.name, type = _a.type, onEnd = _a.onEnd, children = _a.children, style = _a.style, disabled = _a.disabled, params = _a.params, props = __rest(_a, ["name", "type", "onEnd", "children", "style", "disabled", "params"]);
    var item = { name: name, type: type, params: params };
    var _b = useDrag({
        item: item,
        canDrag: function (monitor) { return !disabled; },
        end: function (item, monitor) {
            var dropResult = monitor.getDropResult();
            onEnd ? onEnd(item, dropResult) : null;
        },
        collect: function (monitor) { return ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }); },
    }), opacity = _b[0].opacity, drag = _b[1];
    return (<Wrapper ref={drag} style={__assign({ opacity: opacity }, style)} {...props}>
      {children}
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 80px;\n  width: 80px;\n  border: 1px solid #ddd;\n"], ["\n  height: 80px;\n  width: 80px;\n  border: 1px solid #ddd;\n"])));
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { l } from "@helpers/multiLangaugeHelper";
import styled from "styled-components";
import { CloseOutlined, UpOutlined } from "@/utils/antdIcon";
import { MyTextArea } from "@components/baseComponents/myTextArea";
import { ChatType_Enum, WS_MAP, WS_TYPE_ENUM } from "@components/contentWrapper/helpers/initData";
import { find, merge } from "lodash";
import { getImgUrl } from "@helpers/const";
import { msgError } from "@helpers/axiosHelper";
import { v4 as uuidv4 } from "uuid";
import { getKnowledgeOptions } from "../helpers/async";
import { Radio, Space } from "antd";
import { MAIN_COLOR } from "@/common/styled";
import { ChatRecommend } from "@components/contentWrapper/components/chatRecommend";
import { AI_MODAL_HEIGHT, AI_MODAL_WIDTH } from "@/components/routerInterceptor/interceptor";
import { RefList } from "@components/contentWrapper/components/refList";
var msgTxt;
var resultTxt;
var saveTxt;
var timmer;
var timmer2;
var refList;
var wsType;
export var AIModal = function (_a) {
    var _b;
    var AIVisible = _a.AIVisible, setAIVisible = _a.setAIVisible, onMouseDown = _a.onMouseDown;
    var _c = useState(false), infoCard = _c[0], setInfoCard = _c[1];
    var _d = useState(ChatType_Enum.CHAT), modelName = _d[0], setModelName = _d[1];
    var _e = useState(), chatTypeVisible = _e[0], setChatTypeVisible = _e[1];
    var _f = useState([]), knowledgeOptions = _f[0], setKnowledgeOptions = _f[1];
    var _g = useState([]), chatData = _g[0], setChatData = _g[1];
    var _h = useState(), value = _h[0], setValue = _h[1];
    var _j = useState(false), getting = _j[0], setGetting = _j[1];
    var _k = useState(), txt = _k[0], setTxt = _k[1];
    var _l = useState(), ws = _l[0], setWs = _l[1];
    var _m = useState(), sendData = _m[0], setSendData = _m[1];
    var msgContent = useRef(null);
    useEffect(function () {
        initKnowledgeOptions();
        clearTxt();
        setGetting(false);
    }, []);
    useEffect(function () {
        if (ws) {
            ws.onmessage = function (event) {
                switch (wsType) {
                    case WS_TYPE_ENUM.CONNECT:
                        onConnectSuccess(event);
                        break;
                    case WS_TYPE_ENUM.CHAT:
                    default:
                        onChatMessage(event);
                        break;
                }
            };
            ws.onerror = function () {
                clearTxt();
                setGetting(false);
                var newData = merge([], chatData);
                newData[newData.length - 1].msg = l("sage_not_start", "Sage 正在休息，请稍后重试");
                setChatData(newData);
            };
        }
    }, [JSON.stringify(ws)]);
    useEffect(function () {
        if (getting) {
            if (timmer) {
                clearInterval(timmer);
                timmer = null;
            }
            timmer = setInterval(function () {
                if (msgTxt && msgTxt.length > 0) {
                    resultTxt = (resultTxt || "") + msgTxt[0];
                    setTxt(resultTxt);
                    msgTxt = msgTxt.substring(1);
                }
            }, 25);
        }
        else {
            timmer2 = setInterval(function () {
                if (!msgTxt || msgTxt === "") {
                    clearTxt();
                    clearInterval(timmer);
                    clearInterval(timmer2);
                    timmer = null;
                    timmer2 = null;
                }
            }, 100);
        }
    }, [getting]);
    useEffect(function () {
        if (txt) {
            var newData = merge([], chatData);
            newData[newData.length - 1].msg = txt;
            setChatData(newData);
            msgContent.current.scrollTop = msgContent.current.scrollHeight - msgContent.current.offsetHeight;
        }
    }, [txt]);
    var initKnowledgeOptions = function () {
        getKnowledgeOptions()
            .then(function (res) {
            setKnowledgeOptions(res.data);
        })
            .catch(msgError);
    };
    var onConnectSuccess = function (event) {
        if (checkIsJsonString(event.data)) {
            var data = JSON.parse(event.data);
            if (data.type === WS_TYPE_ENUM.CHAT) {
                wsType = WS_TYPE_ENUM.CHAT;
                ws.send(JSON.stringify(sendData));
            }
        }
    };
    var onChatMessage = function (event) {
        if (getting) {
            var newChatData = merge([], chatData);
            if (checkIsJsonString(event.data)) {
                if (event.data.indexOf("error") > -1) {
                    msgError(JSON.parse(event.data).error);
                    stopWriting();
                    return;
                }
                if (event.data.indexOf("isEnd") <= -1) {
                    refList = JSON.parse(event.data);
                    newChatData[newChatData.length - 1].refList = refList;
                    setChatData(newChatData);
                }
                else {
                    ws.close();
                    setGetting(false);
                    setWs(null);
                    newChatData[newChatData.length - 1].msg = saveTxt;
                    return;
                }
            }
            else {
                msgTxt = (msgTxt || "") + event.data;
                saveTxt = (saveTxt || "") + event.data;
            }
            newChatData[newChatData.length - 1].msg = saveTxt;
        }
    };
    var checkIsJsonString = function (str) {
        if (str.indexOf("{") <= -1)
            return false;
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    var onChatTypeChange = function (v) {
        setModelName(v);
        setChatTypeVisible(false);
    };
    var openKnowledge = function () {
        setChatTypeVisible(true);
    };
    var knowledgeName = knowledgeOptions && (modelName !== ChatType_Enum.CHAT && modelName !== ChatType_Enum.SEARCH) ? (_b = find(knowledgeOptions, function (item) { return item.value === modelName; })) === null || _b === void 0 ? void 0 : _b.label :
        l("sage_knowledge_base", "Knowledge Base");
    var sendMsg = function (value) {
        if (!(value && value !== ""))
            return;
        clearTxt();
        var uid = uuidv4();
        var data = {
            msg: value,
            modelName: (modelName === ChatType_Enum.CHAT || modelName === ChatType_Enum.SEARCH) ? null : modelName,
            chatType: modelName !== ChatType_Enum.CHAT && modelName !== ChatType_Enum.SEARCH ? ChatType_Enum.KNOWLEDGE : modelName,
            userId: window.user.userId,
            knowledgeName: (modelName === ChatType_Enum.CHAT || modelName === ChatType_Enum.SEARCH) ? null : knowledgeName,
            knowledgeId: (modelName === ChatType_Enum.CHAT || modelName === ChatType_Enum.SEARCH) ? null : modelName,
            uid: uid,
            history: initChatHistory(),
        };
        var newData = merge([], chatData);
        wsType = WS_TYPE_ENUM.CONNECT;
        // const ws = new WebSocket("ws://8.219.191.95:7861/sage_chat")
        setValue(null);
        setGetting(true);
        setSendData(data);
        var ws = new WebSocket("wss://" + (WS_MAP[process.env.NODE_ENV] || "sageapi") + ".os.choreograph.cn/sage_chat");
        setWs(ws);
        newData.push(data);
        newData.push({
            msg: "",
            modelName: (modelName === ChatType_Enum.CHAT || modelName === ChatType_Enum.SEARCH) ? null : modelName,
            chatType: modelName === ChatType_Enum.CHAT || modelName === ChatType_Enum.SEARCH ? modelName : ChatType_Enum.KNOWLEDGE,
            question: value,
            knowledgeName: (modelName === ChatType_Enum.CHAT || modelName === ChatType_Enum.SEARCH) ? null : knowledgeName,
            knowledgeId: (modelName === ChatType_Enum.CHAT || modelName === ChatType_Enum.SEARCH) ? null : modelName,
            uid: uid,
        });
        setChatData(newData);
        setTimeout(function () {
            msgContent.current.scrollTop = msgContent.current.scrollHeight - msgContent.current.offsetHeight;
        }, 100);
    };
    var stopWriting = function () {
        clearTxt();
        setGetting(false);
    };
    var onTextChange = function (v) {
        var value = v.replace(/[\r\n]/g, "");
        setValue(value);
    };
    var initChatHistory = function () {
        var result = [];
        if (!chatData[chatData.length - 1] || (chatData[chatData.length - 1] && !((chatData[chatData.length - 1].knowledgeId && chatData[chatData.length - 1].knowledgeId === modelName) ||
            (!chatData[chatData.length - 1].knowledgeId && modelName === ChatType_Enum.CHAT)))) {
            return result;
        }
        var newData = merge([], chatData).reverse();
        for (var _i = 0, newData_1 = newData; _i < newData_1.length; _i++) {
            var item = newData_1[_i];
            if (item.question && item.question !== "" && item.msg && item.msg !== l("sage_not_start", "Sage 正在休息，请稍后重试")) {
                if ((item.knowledgeId && item.knowledgeId !== modelName) || (!item.knowledgeId && modelName !== ChatType_Enum.CHAT)) {
                    break;
                }
                if ((item.knowledgeId || item.knowledgeId === 0) && item.knowledgeId === modelName) {
                    result.push([item.question, item.msg]);
                }
                if (!(item.knowledgeId || item.knowledgeId === 0) && modelName === ChatType_Enum.CHAT) {
                    result.push([item.question, item.msg]);
                }
            }
        }
        return result;
    };
    var clearTxt = function () {
        msgTxt = null;
        refList = null;
        resultTxt = null;
        saveTxt = null;
        setTxt(null);
        ws === null || ws === void 0 ? void 0 : ws.close();
        setWs(null);
    };
    var renderAIMsg = function (item, idx) {
        return (<MsgWrapper>
      <div className={"header"}>
        <img src={getImgUrl("sage/ai_logo.png")}/>
      </div>
      <RefList txt={txt} getting={getting} item={item} isLast={idx === chatData.length - 1}/>
    </MsgWrapper>);
    };
    var renderSelfMsg = function (item) {
        return (<MsgWrapper className={"self"}>
      <div className={"txtWrapper"}>
        <div className={"txt"}>
          {item.msg}
        </div>
      </div>
      <div className={"header"}>
        {window.user.account ? window.user.account.substring(0, 1) : ""}
      </div>
    </MsgWrapper>);
    };
    var renderChatMsg = function () {
        if (!chatData || chatData.length === 0) {
            return (<ChatRecommend />);
        }
        return chatData === null || chatData === void 0 ? void 0 : chatData.map(function (item, idx) { return item.question && item.question !== "" ?
            (renderAIMsg(item, idx)) :
            (renderSelfMsg(item)); });
    };
    return (<ModalWrapper width={AI_MODAL_WIDTH} height={AI_MODAL_HEIGHT} visible={AIVisible} id={"aiModal"}>
    <div className={"title"} onMouseDown={onMouseDown}>
      <span>
        <img className={"headerIcon"} src={getImgUrl("sage/ai_logo.png")} onClick={function () { return setInfoCard(!infoCard); }}/>
        {l("ai_ai_title", "AI Assistant")}
      </span>
      <CloseOutlined onClick={function () {
        setInfoCard(false);
        setAIVisible(false);
    }}/>
      <InfoCard visible={infoCard}>
        <img src={getImgUrl("sage/infoCard.png")}/>
      </InfoCard>
    </div>
    <ChatContent ref={msgContent}>
      {renderChatMsg()}
    </ChatContent>
    <ControllerWrapper>
      <TypeCheckWrapper>
        <div className={modelName === ChatType_Enum.CHAT ? "item active" : (getting ? "item disabled" : "item")} onClick={function () { return getting ? null : onChatTypeChange(ChatType_Enum.CHAT); }}>
          {l("sage_chat", "Chat")}
        </div>
        <div className={modelName !== ChatType_Enum.CHAT ? "item active" : (getting ? "item disabled" : "item")} onClick={getting ? null : openKnowledge}>
          {knowledgeName}<UpOutlined style={{ marginLeft: "4px" }}/>
        </div>
        {chatTypeVisible ? <PopWrapper onMouseLeave={function () { return setChatTypeVisible(false); }}>
          <Radio.Group size={"small"} onChange={function (v) { return onChatTypeChange(v.target.value); }} value={modelName}>
            <Space direction="vertical">
              {knowledgeOptions.map(function (item) { return (<Radio value={item.value}>{item.label}</Radio>); })}
            </Space>
          </Radio.Group>
        </PopWrapper> : null}
      </TypeCheckWrapper>
      <TextInputWrapper>
        {getting || (msgTxt && msgTxt.length > 0) ?
        <div className={"stopBtn"} onClick={stopWriting}>
            {l("sage_stop_generating", "Stop Generating")}
          </div>
        : null}
        <TextInput value={value} autoSize={{ minRows: 2, maxRows: 3 }} maxLength={4000} readOnly={getting || (msgTxt && msgTxt.length > 0)} onChange={function (v) { return onTextChange(v.target.value); }} onPressEnter={function () { return sendMsg(value); }}/>
        <TextController>
          <div>{(value === null || value === void 0 ? void 0 : value.length) || 0}/4000</div>
          <img src={getImgUrl("sage/textSend.png")} onClick={function () { return getting ? null : sendMsg(value); }}/>
        </TextController>
      </TextInputWrapper>
    </ControllerWrapper>
  </ModalWrapper>);
};
var ModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  position: fixed;\n  z-index: 9998;\n  padding: 14px;\n  display: ", ";\n  flex-direction: column;\n  width: ", "px;\n  height: ", "px;\n  bottom:70px;\n  right: 60px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0 0 6px #aaa;\n  .title {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 18px;\n    cursor: move;\n    svg {\n      font-size: 14px;\n    }\n    .headerIcon {\n      margin-right: 8px;\n      width: 32px;\n      height: 32px;\n      cursor: pointer;\n    }\n  }\n"], ["\n  box-sizing: border-box;\n  position: fixed;\n  z-index: 9998;\n  padding: 14px;\n  display: ", ";\n  flex-direction: column;\n  width: ", "px;\n  height: ", "px;\n  bottom:70px;\n  right: 60px;\n  background: #fff;\n  border-radius: 10px;\n  box-shadow: 0 0 6px #aaa;\n  .title {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 18px;\n    cursor: move;\n    svg {\n      font-size: 14px;\n    }\n    .headerIcon {\n      margin-right: 8px;\n      width: 32px;\n      height: 32px;\n      cursor: pointer;\n    }\n  }\n"])), function (props) { return props.visible ? "flex" : "none"; }, function (props) { return props.width; }, function (props) { return props.height; });
var ChatContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 14px 0;\n  overflow: auto;\n  flex: 1;\n"], ["\n  margin: 14px 0;\n  overflow: auto;\n  flex: 1;\n"])));
var ControllerWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var TextInputWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  flex: 1;\n  .stopBtn {\n    position: absolute;\n    display: inline-block;\n    padding: 2px 8px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    top: -32px;\n    right: 0;\n    z-index: 10;\n    font-size: 12px;\n    color: #aaa;\n    cursor: pointer;\n    &:hover {\n      color: #777;\n      border-color: #777;\n    }\n  }\n"], ["\n  position: relative;\n  flex: 1;\n  .stopBtn {\n    position: absolute;\n    display: inline-block;\n    padding: 2px 8px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    top: -32px;\n    right: 0;\n    z-index: 10;\n    font-size: 12px;\n    color: #aaa;\n    cursor: pointer;\n    &:hover {\n      color: #777;\n      border-color: #777;\n    }\n  }\n"])));
var TextInput = styled(MyTextArea)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  background: #fff;\n  border-radius: 0 6px 6px 6px;\n  border-color: #ddd;\n  box-shadow: none;\n  > textarea {\n    padding-right: 70px;\n    color: #777;\n    border-radius: 0 6px 6px 6px;\n    &:focus {\n      box-shadow: none;\n    }\n  }\n"], ["\n  width: 100%;\n  background: #fff;\n  border-radius: 0 6px 6px 6px;\n  border-color: #ddd;\n  box-shadow: none;\n  > textarea {\n    padding-right: 70px;\n    color: #777;\n    border-radius: 0 6px 6px 6px;\n    &:focus {\n      box-shadow: none;\n    }\n  }\n"])));
var TextController = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  right: 10px;\n  bottom: 6px;\n  text-align: right;\n  font-size: 12px;\n  color: #777;\n  > img {\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n  }\n"], ["\n  position: absolute;\n  right: 10px;\n  bottom: 6px;\n  text-align: right;\n  font-size: 12px;\n  color: #777;\n  > img {\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n  }\n"])));
var TypeCheckWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: relative;\n  margin-right: 14px;\n  margin-bottom: -1px;\n  display: flex;\n  align-items: center;\n  .item {\n    margin-right: -1px;\n    padding: 6px 10px;\n    background: #f3f3f3;\n    border: 1px solid #ddd;\n    cursor: pointer;\n    user-select: none;\n    color: #777;\n    font-size: 13px;\n    &.active {\n      z-index: 1;\n      background: #fff;\n      border-bottom-color: #fff;\n    }\n    &:nth-child(1) {\n      border-radius: 6px 0 0 0;\n    }\n    &:nth-child(2) {\n      border-radius: 0 6px 0 0;\n    }\n    &.disabled {\n      cursor: not-allowed;\n      opacity: .5;\n    }\n  }\n"], ["\n  position: relative;\n  margin-right: 14px;\n  margin-bottom: -1px;\n  display: flex;\n  align-items: center;\n  .item {\n    margin-right: -1px;\n    padding: 6px 10px;\n    background: #f3f3f3;\n    border: 1px solid #ddd;\n    cursor: pointer;\n    user-select: none;\n    color: #777;\n    font-size: 13px;\n    &.active {\n      z-index: 1;\n      background: #fff;\n      border-bottom-color: #fff;\n    }\n    &:nth-child(1) {\n      border-radius: 6px 0 0 0;\n    }\n    &:nth-child(2) {\n      border-radius: 0 6px 0 0;\n    }\n    &.disabled {\n      cursor: not-allowed;\n      opacity: .5;\n    }\n  }\n"])));
var PopWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 40px;\n  padding:10px 14px;\n  border-radius: 6px;\n  background: rgba(67, 67, 67, 0.9);\n  .ant-radio-wrapper {\n    > span {\n      color: #fff;\n    }\n  }\n"], ["\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 40px;\n  padding:10px 14px;\n  border-radius: 6px;\n  background: rgba(67, 67, 67, 0.9);\n  .ant-radio-wrapper {\n    > span {\n      color: #fff;\n    }\n  }\n"])));
var MsgWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 18px 10px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  &.self {\n    justify-content: flex-end;\n    .header {\n      margin-right: 0;\n      margin-left: 6px;\n      background: ", ";\n      color:#fff;\n    }\n  }\n  .header {\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-right: 6px;\n    height: 36px;\n    width: 36px;\n    border-radius: 100%;\n    font-size: 20px;\n    border: 1px solid #ddd;\n    background: #ddd;\n    >img {\n      width: 100%;\n    }\n  }\n  .txtWrapper {\n    flex: 1;\n    text-align: right;\n    &.ai {\n      text-align: left;\n    }\n  }\n  .txt {\n    min-height: 36px;\n    display: inline-block;\n    color: #777;\n    padding: 8px 10px;\n    border-radius: 4px;\n    background: #f3f3f3;\n    text-align: justify;\n    font-size: 13px;\n  }\n"], ["\n  margin: 18px 10px;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  &.self {\n    justify-content: flex-end;\n    .header {\n      margin-right: 0;\n      margin-left: 6px;\n      background: ", ";\n      color:#fff;\n    }\n  }\n  .header {\n    overflow: hidden;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-right: 6px;\n    height: 36px;\n    width: 36px;\n    border-radius: 100%;\n    font-size: 20px;\n    border: 1px solid #ddd;\n    background: #ddd;\n    >img {\n      width: 100%;\n    }\n  }\n  .txtWrapper {\n    flex: 1;\n    text-align: right;\n    &.ai {\n      text-align: left;\n    }\n  }\n  .txt {\n    min-height: 36px;\n    display: inline-block;\n    color: #777;\n    padding: 8px 10px;\n    border-radius: 4px;\n    background: #f3f3f3;\n    text-align: justify;\n    font-size: 13px;\n  }\n"])), MAIN_COLOR);
var InfoCard = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: ", ";\n  position: absolute;\n  left: -378px;\n  top: -14px;\n  box-shadow: 0 0 4px #aaa;\n  border-radius: 8px;\n  > img {\n    width: 360px;\n  }\n"], ["\n  display: ", ";\n  position: absolute;\n  left: -378px;\n  top: -14px;\n  box-shadow: 0 0 4px #aaa;\n  border-radius: 8px;\n  > img {\n    width: 360px;\n  }\n"])), function (props) { return props.visible ? "block" : "none"; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

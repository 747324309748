var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { map } from "lodash";
export var FooterBtnWrapper = function (_a) {
    var buttonList = _a.buttonList, style = _a.style;
    var btnClick = function (btn) {
        btn.onClick();
    };
    return (<Wrapper style={style}>
    {map(buttonList, function (btn, idx) { return (<FooterBtn key={"footerBtn-" + idx} {...btn} onClick={function () { return btnClick(btn); }}>
        {btn.text}
      </FooterBtn>); })}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  z-index: 1;\n  margin-top: 36px;\n  display: flex;\n  justify-content: center;\n"], ["\n  position: relative;\n  z-index: 1;\n  margin-top: 36px;\n  display: flex;\n  justify-content: center;\n"])));
var FooterBtn = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin:0 14px;\n  height: 40px;\n  min-width: 80px;\n  &.ant-btn-primary[disabled] {\n    border: none !important;\n  }\n"], ["\n  margin:0 14px;\n  height: 40px;\n  min-width: 80px;\n  &.ant-btn-primary[disabled] {\n    border: none !important;\n  }\n"])));
var templateObject_1, templateObject_2;

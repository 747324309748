var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TABLE_RENDER_TYPE_ENUM } from "@components/myTable/enum";
import { l } from "@helpers/multiLangaugeHelper";
import { ACTION_APPLY_ENUM, ACTION_CODE_ENUM, STATUS_OPTIONS_TYPE } from "@/common/const";
import { msgError, msgSuccess, post } from "@helpers/axiosHelper";
import { updateStatusApi } from "@/helpers/async";
import { remove } from "lodash";
export var ACTION_COLUMNS = function (needPopConfirm) {
    var type = needPopConfirm ? TABLE_RENDER_TYPE_ENUM.POP_CONFIRM : TABLE_RENDER_TYPE_ENUM.MODAL;
    return [
        {
            key: "submitItem",
            type: type,
            text: l("common_submit", "Submit"),
            hide: function (record) { return !record.actionSubmit; },
            options: {
                text: l("common_submit_confirm", "Confirm Submit") + "?"
            }
        },
        {
            key: "approveItem",
            type: type,
            text: l("common_approve", "Approve"),
            hide: function (record) { return !record.actionApprove; },
            options: {
                text: l("common_approve_confirm", "Confirm Approve") + "?"
            }
        },
        {
            key: "rejectItem",
            type: type,
            text: l("common_reject", "Reject"),
            hide: function (record) { return !record.actionReject; },
            options: {
                text: l("common_reject_confirm", "Confirm Reject") + "?"
            }
        },
        {
            key: "receiveItem",
            type: type,
            text: l("common_receive", "Receive"),
            hide: function (record) { return !record.actionReceive; },
            options: {
                text: l("common_receive_confirm", "Confirm Receive") + "?"
            }
        },
        {
            key: "receiveApproveItem",
            type: type,
            text: l("common_receive_and_approve", "Receive & Approve"),
            hide: function (record) { return !record.actionReceiveApprove; },
            options: {
                text: l("common_receive_and_approve_confirm", "Confirm Receive & Approve") + "?"
            }
        },
        {
            key: "uploadResultItem",
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            text: l("common_upload_result", "Upload Result"),
            hide: function (record) { return !record.actionUploadResult; },
        },
        {
            key: "finishItem",
            type: type,
            text: l("common_finish", "Finish"),
            hide: function (record) { return !record.actionFinish; },
            options: {
                text: l("common_finish_confirm", "Confirm Finish") + "?"
            }
        },
        {
            key: "withdrawItem",
            type: type,
            text: l("common_withdraw", "Withdraw"),
            hide: function (record) { return !record.actionWithdraw; },
            options: {
                text: l("common_withdraw_confirm", "Confirm Withdraw") + "?"
            }
        },
        {
            key: "rerunItem",
            text: l("common_rerun", "重跑"),
            type: type,
            hide: function (record) { return !record.actionRerun; },
            options: {
                text: l("common_rerun_confirm", "确认重跑") + "?"
            }
        },
    ];
};
export var updateStatus = function (record, actionCode, type, idKey, callback) {
    updateStatusApi({ id: record[idKey], actionCode: actionCode }, type)
        .then(function (res) {
        callback ? callback() : null;
        msgSuccess(res);
    })
        .catch(function (err) {
        msgError(err);
    });
};
export var getActionApplyObject = function (type, idKey, callback) {
    return {
        submitItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.SUBMIT, type, idKey, callback); },
        approveItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.APPROVE, type, idKey, callback); },
        rejectItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.REJECT, type, idKey, callback); },
        receiveItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.RECEIVE, type, idKey, callback); },
        receiveApproveItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.RECEIVE_APPROVE, type, idKey, callback); },
        uploadResultItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.UPLOAD_RESULT, type, idKey, callback); },
        finishItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.FINISH, type, idKey, callback); },
        withdrawItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.WITHDRAW, type, idKey, callback); },
        rerunItem: function (record) { return updateStatus(record, ACTION_CODE_ENUM.RERUN, type, idKey, callback); },
    };
};
export var getAudienceApplyObject = function () {
    var simpleCreate = function (record, url) {
        window.open(url + "?advertiserId=" + record.advertiserId + "&segmentId=" + record.segmentId + "&sourceId=" + record.sourceId);
    };
    return {
        mprofile: function (record) { return simpleCreate(record, "/techHub/profiling/mprofile/edit"); },
        mhprofile: function (record) { return simpleCreate(record, "/techHub/profiling/mhprofile/edit"); },
        sprofile: function (record) { return simpleCreate(record, "/techHub/profiling/sprofile/edit"); },
        activation: function (record) { return simpleCreate(record, "/techhub/audex/activation/activateManage/edit"); },
        clustering: function (record) { return simpleCreate(record, "/techHub/audex/modeling/clustering/edit"); },
        lookalike: function (record) { return simpleCreate(record, "/techHub/audex/modeling/lookalike/edit"); },
        correlation: function (record) { return simpleCreate(record, "/techHub/audex/modeling/correlationAnalysis/edit"); },
    };
};
export var AUDIENCE_APPLY_OBJECT = function (params) {
    var getHide = function (record, actionKey, type) {
        if (type === STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE && record.taskType === STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE) {
            return true;
        }
        if (record.taskType && record.taskType !== STATUS_OPTIONS_TYPE.AUDIENCE_OTV_AUDIENCE
            && record.taskType !== STATUS_OPTIONS_TYPE.AUDIENCE_LOCATION_AUDIENCE
            && record.taskType !== STATUS_OPTIONS_TYPE.AUDIENCE_STANDARD_AUDIENCE
            && record.taskType !== STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE) {
            return true;
        }
        return !record[actionKey];
    };
    var result = [
        {
            key: "mprofile",
            text: l("profiling_profile_title_m", "人群画像"),
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            hide: function (record) { return getHide(record, ACTION_APPLY_ENUM.MPROFILE); },
        },
        {
            key: "mhprofile",
            text: l("profiling_profile_title_mh", "家庭画像"),
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            hide: function (record) { return getHide(record, ACTION_APPLY_ENUM.MHPROFILE); },
        },
        {
            key: "sprofile",
            text: l("profiling_profile_title_s", "社交画像"),
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            hide: function (record) { return getHide(record, ACTION_APPLY_ENUM.SPROFILE); },
        },
        {
            key: "activation",
            text: l("menu_activate", "投放"),
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            hide: function (record) { return getHide(record, ACTION_APPLY_ENUM.ACTIVATION); },
            disabled: function (record) { return record.audienceSize > 1500000000; },
            disabledTips: function (record) { return l("activation_audience_limit_tips", "当前人群量级超过15亿，无法进行推送。"); }
        },
        {
            key: "clustering",
            text: l("menu_modeling_cluster", "聚类分析"),
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            hide: function (record) { return getHide(record, ACTION_APPLY_ENUM.CLUSTERING); },
        },
        {
            key: "lookalike",
            text: l("menu_modeling_lookalike", "相似人群扩展"),
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            hide: function (record) { return getHide(record, ACTION_APPLY_ENUM.LOOKALIKE, STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE); },
            disabled: function (record) { return record.disabledLal; },
            disabledTips: function (record) { return record.sourceId === 2 ?
                l("lookalike_simple_create_disabled_tips_2", "人群规模量级不符合需求，种子人群的大小必须在1k-10m")
                : l("lookalike_simple_create_disabled_tips", "人群规模量级不符合需求，种子人群的大小必须在1k-20m"); }
        },
        {
            key: "correlation",
            text: l("menu_correlation", "标签推荐"),
            type: TABLE_RENDER_TYPE_ENUM.MODAL,
            hide: function (record) { return getHide(record, ACTION_APPLY_ENUM.CORRELATION); },
        },
    ];
    if (params && params.hideLookalike) {
        remove(result, function (item) { return item.key === "lookalike"; });
    }
    return result;
};
export var getTaskDetailOrEditUrlMap = function (type, record) {
    var _a;
    return _a = {},
        _a[STATUS_OPTIONS_TYPE.PROFILING_M_PROFILE] = "/techHub/profiling/mprofile/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.PROFILING_MH_PROFILE] = "/techHub/profiling/mhprofile/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.PROFILING_S_PROFILE] = "/techHub/profiling/sprofile/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.MODELING_CLUSTER] = "/techHub/modeling/clustering/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.MODELING_CUSTOM_MODELING] = "/techHub/audex/modeling/customModeling/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.MODELING_LOOKALIKE] = "/techHub/audex/modeling/lookalike/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.ACTIVATION] = "/techhub/audex/activation/activateManage/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.AUDIENCE_OTV_AUDIENCE] = "/techHub/audience/OTVdAudience/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.AUDIENCE_LOCATION_AUDIENCE] = "/techHub/audience/LocationAudience/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.AUDIENCE_STANDARD_AUDIENCE] = "/techHub/audience/standardAudience/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.TRACKING_ADVERTISING_TRACKING] = "/techHub/tracking/advertisingTracking/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.CORRELATION_ANALYSIS] = "/techHub/audex.modeling/correlationAnalysis/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.DOOH_PLANNING] = "/techHub/doohPlanning/" + type + "?id=" + record.taskId,
        _a[STATUS_OPTIONS_TYPE.COPILOT_PRE_CAMPAIGN_TASK] = "/techHub/copilot/preCampaignTask/" + type + "?id=" + record.taskId,
        _a;
};
export var commonDeleteApi = function (data, type) {
    return post("/groupme/common/updateStatus", __assign(__assign({}, data), { id: data.taskId, type: type, actionCode: ACTION_CODE_ENUM.CANCEL }));
};

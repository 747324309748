var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { SkinContext } from "@components/contentWrapper/store/storeContext";
import { MAIN_COLOR } from "@/common/styled";
export var CommonTitle = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var skin = useContext(SkinContext).skin;
    return (<Wrapper className={"commonTitle"} {...props}>
    <LineBlock color={skin.mainColor}/>{children}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 14px 10px;\n  position: relative;\n  font-size: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 14px 10px;\n  position: relative;\n  font-size: 16px;\n"])));
var LineBlock = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  top:50%;\n  height: 12px;\n  width: 2px;\n  transform: translateY(-50%);\n  background: ", ";\n"], ["\n  position: absolute;\n  left: 0;\n  top:50%;\n  height: 12px;\n  width: 2px;\n  transform: translateY(-50%);\n  background: ", ";\n"])), function (props) { return props.color || MAIN_COLOR; });
var templateObject_1, templateObject_2;

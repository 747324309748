var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import numeral from "numeral";
import { BarLineChars } from "@components/charts/barLineChart/barLineChart";
import styled from "styled-components";
import { find, findIndex, map, uniqBy } from "lodash";
import { ANOTHER_DEFAULT_COLOR_LIST, CHARTS_DEFAULT_COLOR_LIST } from "@/common/styled";
import { l } from "@helpers/multiLangaugeHelper";
var DEFAULT_BAR_WIDTH = 30;
export var SPECIAL_TYPE_ENUM;
(function (SPECIAL_TYPE_ENUM) {
    SPECIAL_TYPE_ENUM[SPECIAL_TYPE_ENUM["COVERAGE_RATE"] = 0] = "COVERAGE_RATE";
})(SPECIAL_TYPE_ENUM || (SPECIAL_TYPE_ENUM = {}));
export var TgiBarLineChart = function (_a) {
    var data = _a.data, height = _a.height, bar = _a.bar, line = _a.line, yScale = _a.yScale, tooltip = _a.tooltip, specialType = _a.specialType;
    var charRef = useRef(null);
    var _b = useState(10), barItemWidth = _b[0], setBarItemWidth = _b[1];
    useEffect(function () {
        var width = getBarItemWidth(charRef);
        setBarItemWidth(width);
    }, [charRef.current]);
    var getItemHasType = function () {
        var typeLength = uniqBy(data, "type").length;
        return typeLength > 1;
    };
    var hasType = getItemHasType();
    var getHasTgi = function () {
        var y2item = find(data, function (item) { return item.yAxis2; });
        return !!y2item;
    };
    var hasTgi = getHasTgi();
    var PointStyle = function () {
        return [line.yAxis + "*" + line.color, function (tgi, color) {
                var lineColor = CHARTS_DEFAULT_COLOR_LIST[1];
                return {
                    stroke: tgi > 100 ? "#ff5100" : lineColor,
                    lineWidth: 2,
                    r: barItemWidth / 2
                };
            }];
    };
    var HasTypePointStyle = function () {
        return [line.yAxis + "*" + line.color, function (tgi, color) {
                var typeList = uniqBy(data, "type");
                var idx = findIndex(typeList, function (item) { return item.type === color; });
                var lineColor = ANOTHER_DEFAULT_COLOR_LIST[idx];
                return {
                    stroke: tgi > 100 ? "#ff5100" : lineColor,
                    lineWidth: 2,
                    r: barItemWidth / 2
                };
            }];
    };
    var initPoint = function () {
        var result = __assign(__assign({}, line), { shape: "hyphen" });
        if (hasType) {
            result.adjust = [{ type: "dodge", dodgeBy: "type", marginRatio: 0 }];
            // result.style = {
            //   lineWidth: 2,
            //   r: barItemWidth / 2,
            // }
            result.color = "type";
            result.style = HasTypePointStyle();
        }
        else {
            result.style = PointStyle();
        }
        return result;
    };
    var getBarItemWidth = function (charRef) {
        var xAxisCount = uniqBy(data, "xAxis").length;
        if (hasType) {
            var typeCount = uniqBy(data, "type").length;
            return Math.min((charRef.current.offsetWidth - 220) / xAxisCount / typeCount / 1.75, DEFAULT_BAR_WIDTH);
        }
        return Math.min((charRef.current.offsetWidth - 220) / xAxisCount / 2, DEFAULT_BAR_WIDTH);
    };
    var initBar = function () {
        var result = __assign(__assign({}, bar), { label: { hideLabel: true } });
        result.size = barItemWidth;
        if (hasType) {
            result.color = "type";
        }
        return result;
    };
    var initLine = function () {
        var result = __assign(__assign({}, line), { size: 0 });
        if (hasType) {
            result.color = "type";
        }
        return result;
    };
    var getCoverageRateText = function (type) {
        if (type === "覆盖率" || type === "Coverage") {
            return l("common_installation_rate", "安装率");
        }
        else if (type === "活跃率" || type === "Active") {
            return l("common_active_rate", "活跃率");
        }
        return l("dashboard_percent", "Percentage");
    };
    var renderTooltip = function (items, title) {
        if (tooltip && typeof tooltip === "function") {
            return tooltip(items, title);
        }
        return <Tooltip>
      <Title>{title}</Title>
      {map(items, function (item) {
            if (item.mappingData.shape) {
                return null;
            }
            return (<TooltipContent key={item.title + "-" + item.name + "-" + item.value}>
            <div>
              <ItemTooltipsCircle color={item.color}/>
              <TooltipItem>
                {specialType === SPECIAL_TYPE_ENUM.COVERAGE_RATE ? getCoverageRateText(item.data.type)
                : l("dashboard_percent", "Percentage")}:
                {numeral(item.data.yAxis).format("0.0%")}
              </TooltipItem>
              {item.data.yAxis2 ? <TooltipItem>TGI: {numeral(item.data.yAxis2).format("0,0")}</TooltipItem> : null}
            </div>
          </TooltipContent>);
        })}
    </Tooltip>;
    };
    var getLabelOptions = function () {
        var result = {
            style: {
            // fontSize: 12
            },
            autoRotate: data.length <= 6,
            autoEllipsis: false,
            autoHide: false
        };
        if (data.length > 6) {
            result.rotate = 0.38;
            result.offset = 14;
            result.style.textAlign = "left";
            result.textStyle = {
                textAlign: "start"
            };
        }
        return result;
    };
    return (<Wrapper ref={charRef}>
      <BarLineChars height={height} data={data} xAxis={{ name: bar.xAxis, label: getLabelOptions() }} yAxis={{ name: bar.yAxis, yAxisTitle: l("dashboard_percent", "Percentage") }} bar={initBar()} line={hasTgi ? initLine() : null} point={hasTgi ? initPoint() : null} yScale={yScale ? yScale : { formatter: function (v) { return numeral(v * 100).format("0.0") + "%"; } }} anotherAxis={{ name: line.yAxis, yAxisTitle: "TGI" }} tooltip={renderTooltip} hasType={hasType}/>
    </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width:100%;\n"], ["\n  width:100%;\n"])));
var Tooltip = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px 0;\n"], ["\n  padding: 10px 0;\n"])));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var ItemTooltipsCircle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"], ["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"])), function (props) { return props.color; });
var TooltipContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    overflow: hidden;\n    margin: 0 10px;\n  }\n"], ["\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    overflow: hidden;\n    margin: 0 10px;\n  }\n"])));
var TooltipItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  &:nth-child(2){\n    width: 100px;\n  }\n"], ["\n  display: inline-block;\n  &:nth-child(2){\n    width: 100px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

import axios from "axios";
import { initPostUrl } from "@helpers/axiosHelper";
export var batchExportApi = function (url, data) {
    return axios({
        responseType: "blob",
        method: "post",
        headers: {
            "Content-Type": "application/json;charset=utf-8"
        },
        url: initPostUrl(url),
        data: data
    });
};

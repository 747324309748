var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Button, ConfigProvider, Divider, Dropdown, Menu, Spin } from "antd";
import styled from "styled-components";
import { msgError, msgSuccess } from "@helpers/axiosHelper";
import { getAccountInfo, getHasTips, getLinkGid, getMenuList, getReleaseData, logoutApi, setAllReleaseRead, setHasTips, setReleaseRead } from "@components/contentWrapper/helpers/async";
import { find, map, merge } from "lodash";
import { useHistory } from "react-router-dom";
import { IconFont, LogoutOutlined, TagsOutlined, UserOutlined, VerticalLeftOutlined } from "@/utils/antdIcon";
import enGB from "antd/lib/locale/en_GB";
import zhCN from "antd/lib/locale/zh_CN";
import { l, LANGUAGE_CODE } from "@helpers/multiLangaugeHelper";
import { updateLanguage } from "@helpers/async";
import { MySpin } from "@components/baseComponents/mySpin";
import { getImgUrl } from "@helpers/const";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@/helpers/msalConfig";
import queryString from "query-string";
import { QRCanvas } from "qrcanvas-react";
import { SkinContext } from "@components/contentWrapper/store/storeContext";
import { MyModal } from "@components/baseComponents/myModal";
import { MAIN_COLOR } from "@/common/styled";
var MenuItem = Menu.Item;
export var HEADER_HEIGHT = 64;
export var SIDEBAR_WIDTH = 214;
var ContentWrapper = function (_a) {
    var children = _a.children;
    var updateMsgLink = queryString.parse(location.search).updateMsgLink;
    var _b = useContext(SkinContext), skin = _b.skin, setSkin = _b.setSkin;
    var history = useHistory();
    var $msalInstance = new PublicClientApplication(msalConfig);
    var _c = useState([]), headBar = _c[0], setHeadBar = _c[1];
    var _d = useState([]), sideBar = _d[0], setSideBar = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState(), selectedHeadBarKey = _f[0], setSelectedHeadBarKey = _f[1];
    var _g = useState(), selectedSideBarKey = _g[0], setSelectedSideBarKey = _g[1];
    var _h = useState({}), accountInfo = _h[0], setAccountInfo = _h[1];
    var _j = useState([]), releaseData = _j[0], setReleaseData = _j[1];
    var _k = useState(false), tipsVisible = _k[0], setTipsVisible = _k[1];
    var _l = useState(), tipsData = _l[0], setTipsData = _l[1];
    var _m = useState(false), erCodeVisible = _m[0], setErCodeVisible = _m[1];
    var _o = useState(), erCodeImg = _o[0], setErCodeImg = _o[1];
    var _p = useState(), gid = _p[0], setGid = _p[1];
    var _q = useState(), enterUrl = _q[0], setEnterUrl = _q[1];
    var _r = useState(false), showHide = _r[0], setShowHide = _r[1];
    var _s = useState(), oldHeadBarKey = _s[0], setOldHeadBarKey = _s[1];
    useEffect(function () {
        getInit();
    }, []);
    useEffect(function () {
        if (headBar.length > 0) {
            getMenuList(location.pathname)
                .then(function (_a) {
                var headBar = _a[0], sideBar = _a[1];
                var headMenu = headBar.list;
                var sideMenu = sideBar.list;
                setHeadBar(headMenu);
                setSideBar(sideMenu);
                var selectedHeadItem = find(headMenu, function (item) { return item.isActive; });
                var selectedHeadKey = selectedHeadItem ? selectedHeadItem.id : null;
                setSelectedHeadBarKey(selectedHeadKey);
                // sidebar 高亮设置
                var selectedSideKey = getActiveSideKey(sideMenu);
                setSelectedSideBarKey(selectedSideKey);
                setLoading(false);
            })
                .catch(function (err) {
                setLoading(false);
                msgError(err);
            });
        }
        getHasTips(location.pathname)
            .then(function (res) {
            if (res.data && res.data.length > 0) {
                setTipsVisible(true);
                setTipsData(res.data);
            }
        })
            .catch(msgError);
    }, [location.pathname]);
    var getInit = function () {
        var image = new Image();
        // wx二维码 logo 太丑了暂时没用
        image.src = "/objects/groupm/img/images/erLogo.png";
        image.onload = function () {
            setErCodeImg(image);
        };
        getAccountInfo()
            .then(function (res) {
            setAccountInfo(res.data);
            setEnterUrl(res.data.enterUrl);
            window.user = res.data;
            document.title = res.data.opcoTitle || res.data.opco;
        })
            .catch(msgError);
        getMenuList(location.pathname)
            .then(function (_a) {
            var headBar = _a[0], sideBar = _a[1];
            var headMenu = headBar.list;
            var sideMenu = sideBar.list;
            setHeadBar(headMenu);
            setSideBar(sideMenu);
            var selectedHeadItem = find(headMenu, function (item) { return item.isActive; });
            var selectedHeadKey = selectedHeadItem ? selectedHeadItem.id : null;
            setSelectedHeadBarKey(selectedHeadKey);
            // sidebar 高亮设置
            var selectedSideKey = getActiveSideKey(sideMenu);
            setSelectedSideBarKey(selectedSideKey);
            setLoading(false);
            return getReleaseData();
        })
            .then(function (res) {
            setReleaseData(res.data);
        })
            .catch(function (err) {
            setLoading(false);
            msgError(err);
        });
    };
    var getActiveSideKey = function (list) {
        var result;
        var loopActiveSideKey = function (list) {
            for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
                var item = list_1[_i];
                if (item.isActive) {
                    result = item.id;
                    break;
                }
                else if (item.children && item.children.length > 0) {
                    loopActiveSideKey(item.children);
                }
            }
        };
        loopActiveSideKey(list);
        return result;
    };
    var onHeadBarClick = function (item, isSetOld, e) {
        if (e) {
            e.stopPropagation();
        }
        setShowHide(true);
        if (isSetOld) {
            setOldHeadBarKey(selectedHeadBarKey);
        }
        setSelectedHeadBarKey(item.id);
        setSideBar(item.children);
    };
    var closeBar = function (e) {
        e.stopPropagation();
        setSelectedHeadBarKey(oldHeadBarKey);
        setShowHide(false);
    };
    var onSideBarClick = function (e, item) {
        e.stopPropagation();
        setSelectedSideBarKey(item.id);
        setShowHide(false);
        history.push(item.url);
        document.title = window.user.opcoTitle + " - " + item.title;
    };
    var changeLanguageCode = function (code) {
        if (code !== window.LANG) {
            localStorage.setItem("languageCode", code);
            updateLanguage(code)
                .then(function (res) {
                location.reload();
            })
                .catch(msgError);
        }
    };
    var logout = function () {
        logoutApi()
            .then(function (res) {
            msgSuccess(res);
            var url = enterUrl ? enterUrl : (accountInfo.opco !== "caas" ? (accountInfo.opco === "choreograph" ? "/enter" : "/enter/" + accountInfo.opco) : "/caasLogin");
            history.push(url);
            if (process.env.NODE_ENV !== "production") {
                $msalInstance.logoutPopup()
                    .then(function () {
                    history.push(accountInfo.opco === "groupm" ? "/enter" : "/enter/" + accountInfo.opco);
                })
                    .catch(function (error) {
                    msgError(error);
                });
            }
        })
            .catch(msgError);
    };
    var languageMenu = (<Menu>
      <MenuItem onClick={function () { return changeLanguageCode(LANGUAGE_CODE.CN); }}>🇨🇳 简体中文</MenuItem>
      <MenuItem onClick={function () { return changeLanguageCode(LANGUAGE_CODE.EN); }}>🇬🇧 English</MenuItem>
    </Menu>);
    var changePassword = function () {
        var opco = accountInfo.opco, account = accountInfo.account;
        history.push(opco && opco !== "groupm" ?
            "/updatePassword/" + opco + "?username=" + account :
            "/updatePassword?username=" + account);
    };
    var linkWeChat = function () {
        if (window.user && window.user.userId) {
            getLinkGid(window.user.userId)
                .then(function (res) {
                setGid(res.data.gid);
                setErCodeVisible(true);
            })
                .catch(msgError);
        }
    };
    var userMenu = (<Menu>
      <MenuItem icon={<AccountNameIcon small={true}>
          {accountInfo.account ? accountInfo.account.substring(0, 1) : ""}
        </AccountNameIcon>}>
        <span style={{ marginLeft: "10px" }}>{accountInfo.account}</span>
      </MenuItem>
      <MenuItem icon={<UserOutlined />} onClick={linkWeChat}>
        {l("link_to_wechat", "关联微信")}
      </MenuItem>
      {accountInfo.isExternal ? <MenuItem icon={<UserOutlined />} onClick={changePassword}>
        {l("common_update_password", "修改密码")}
      </MenuItem> : null}
      <MenuItem icon={<UserOutlined />} onClick={function () { return setSkin({
        btnColor: "rgb(193, 0, 22)",
        mainColor: "rgb(193, 0, 22)",
        mainBgColor: "#f8adad",
        mainTxtColor: "#ef4b4b",
        txtColor: "#000",
        btnTxtColor: "#ffd784",
        tableTitleTxtColor: "#ffd784",
        tableTitleBgColor: "rgb(193, 0, 22)",
    }); }}>
        切换Cartier
      </MenuItem>
      
      <MenuItem icon={<LogoutOutlined />} onClick={logout}>{l("common_log_out", "退出登录")}</MenuItem>
    </Menu>);
    var renderSideBar = function (sideBar) {
        return map(sideBar, function (item, idx) {
            if (item.children && item.children.length > 0) {
                return (<div className={"itemWrapper"}>
            <div className={"title"}><TagsOutlined style={{ marginRight: "8px" }}/>{item.title}</div>
            <div className={"itemBlock"}>{renderSideBar(item.children)}</div>
            {sideBar.length - 1 === idx ? null : <Divider style={{ margin: "14px 0" }}/>}
          </div>);
            }
            return (<div className={(selectedSideBarKey === item.id ? "active" : "") + " item"} onClick={function (e) { return onSideBarClick(e, item); }}>
            {item.title}
          </div>);
        });
    };
    var logoClick = function (e) {
        e.stopPropagation();
        history.push(accountInfo.homepage);
        setSelectedHeadBarKey(null);
        setSideBar([]);
    };
    var nextReleaseMsg = function () {
        var newReleaseData = merge([], releaseData);
        setReleaseRead(releaseData[0]);
        newReleaseData.splice(0, 1);
        setReleaseData(newReleaseData);
    };
    var exitReleaseMsg = function () {
        setAllReleaseRead();
        setReleaseData([]);
    };
    var setTipsRead = function () {
        setHasTips({
            url: location.pathname,
            id: tipsData[0].id
        })
            .then(function (res) {
            setTipsVisible(false);
            if (tipsData.length > 1) {
                var newData = merge([], tipsData);
                newData.splice(newData.length - 1, 1);
                setTipsData(newData);
                setTimeout(function () { return setTipsVisible(true); }, 500);
            }
        });
    };
    var getRedirectUrl = function () {
        var url = location.origin + "/mt/pcLink?" +
            ("userId=" + (window.user ? window.user.userId : "") + "&gid=" + gid);
        return encodeURIComponent(url);
    };
    return (<MySpin spinning={loading}>
    <ConfigProvider locale={window.LANG === LANGUAGE_CODE.EN ? enGB : zhCN}>
      <Wrapper>
        <HeaderWrapper className={"header"} onClick={closeBar}>
          <div>
            <LogoWrapper>
              {accountInfo.opco ? <Logo src={getImgUrl("logo/top/" + (accountInfo.opco || "choreograph") + ".png")} onClick={function (e) { return logoClick(e); }}/> : null}
            </LogoWrapper>
            {location.href.indexOf("demo") > -1 || process.env.NODE_ENV !== "development" ? null :
        <WaterIco>{l("test_env", "Test Env.")}</WaterIco>}
            {headBar.map(function (item) { return (<MainMenu key={item.id} active={selectedHeadBarKey === item.id} onClick={function (e) { return showHide ? null : onHeadBarClick(item, true, e); }} onMouseOver={function () { return showHide ? onHeadBarClick(item) : null; }}>
                {item.title}
                <ChildMenuWrapper active={showHide && selectedHeadBarKey === item.id}>
                  <SideChildBar>
                    {renderSideBar(sideBar)}
                  </SideChildBar>
                </ChildMenuWrapper>
              </MainMenu>); })}
          </div>
          <InfoWrapper>
            <Dropdown overlay={userMenu}>
              <InfoItem>
                <AccountNameIcon className={"accountIcon"}>{accountInfo.account ? accountInfo.account.substring(0, 1) : ""}</AccountNameIcon>
              </InfoItem>
            </Dropdown>
            <Dropdown overlay={languageMenu}>
              <InfoItem>
                <MultiLanguageIcon type="icon-language"/>
              </InfoItem>
            </Dropdown>
          </InfoWrapper>
        </HeaderWrapper>
        <BottomWrapper>
          <SideBarBlock>
            <SideBarMask active={showHide} onClick={closeBar}/>
          </SideBarBlock>
          <Content id={"content"}>
            {children}
          </Content>
        </BottomWrapper>
      </Wrapper>
    </ConfigProvider>
    {releaseData && !updateMsgLink && releaseData.length > 0 ? <MaskWrapper>
      <MaskMenuMask />
      <MaskHeader>
        {map(headBar, function (item) {
        return <MainMenu style={{ visibility: item.id === releaseData[0].id ? "initial" : "hidden" }} key={item.id + "mask"} active={selectedHeadBarKey === item.id}>
            {item.title}
            <MaskMenuItemBg />
            <MaskTipsWrapper>
              <MaskImgWrapper>
                <img alt={""} draggable={false} src={getImgUrl("release_top.png")}/>
                <span>{l("release_update_tips", "模块中有功能更新啦！！")}</span>
              </MaskImgWrapper>
              <MaskTipsContent>
                {releaseData[0].content && releaseData[0].content.length > 0 ?
            map(releaseData[0].content, function (item) { return (<div>
                        <span onClick={function () { return window.open(item.link + "&&updateMsgLink=true"); }}>
                          {item.desc}
                        </span>
                    </div>); })
            : null}
              </MaskTipsContent>
              <MaskTipsNext onClick={nextReleaseMsg}>
                {releaseData.length > 1 ? l("release_info_next", "下一个") : l("release_info_close", "Close")}
              </MaskTipsNext>
            </MaskTipsWrapper>
          </MainMenu>;
    })}
      </MaskHeader>
      <MaskExit onClick={exitReleaseMsg}>
        {l("release_info_exit", "退出版本引导")}
        <MaskExitIcon />
      </MaskExit>
    </MaskWrapper> : null}
    <MyModal visible={tipsVisible} title={l("common_notice", "通知")} maskClosable={false} closable={false} footer={<>
        <Button type={"primary"} onClick={setTipsRead}>
          {tipsData && tipsData[0] ? tipsData[0].btnTxt : l("common_know", "知道了")}
        </Button>
      </>}>
      <div dangerouslySetInnerHTML={{ __html: tipsData && tipsData[0] ? tipsData[0].msg : null }}/>
    </MyModal>
    <MyModal width={180} visible={erCodeVisible} title={l("link_to_wechat", "关联微信账号")} onCancel={function () { return setErCodeVisible(false); }} footer={null}>
      <Spin spinning={!gid}>
        {erCodeImg ? <ErCodeWrapper>
          <QRCanvas options={{
        cellSize: 2.2,
        data: "https://open.weixin.qq.com/connect/oauth2/authorize?" +
            ("appid=" + (process.env.NODE_ENV !== "production" ? "wx921f3753e6f5d563" : "wx6e7b3c82b5a15d6f") + "&") +
            ("redirect_uri=" + getRedirectUrl()) +
            "&response_type=code&scope=snsapi_userinfo#wechat_redirect"
    }}/>
        </ErCodeWrapper> : null}
      </Spin>
    </MyModal>
  </MySpin>);
};
export default ContentWrapper;
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  height: 100vh;\n  width: 100vw;\n  min-width: 1380px;\n"], ["\n  position: relative;\n  overflow: hidden;\n  height: 100vh;\n  width: 100vw;\n  min-width: 1380px;\n"])));
var Logo = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin: ", ";\n  float: ", ";\n  width: 190px;\n  cursor: pointer;\n"], ["\n  display: block;\n  margin: ", ";\n  float: ", ";\n  width: 190px;\n  cursor: pointer;\n"])), function (props) { return props.hasPop ? "0 0 0 24px" : "auto"; }, function (props) { return props.hasPop ? "left" : "none"; });
var BottomWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  z-index: 1;\n  display: flex;\n  position: relative;\n  float: right;\n  overflow: hidden;\n  height: calc(100vh - ", "px);\n  width: 100%;\n"], ["\n  z-index: 1;\n  display: flex;\n  position: relative;\n  float: right;\n  overflow: hidden;\n  height: calc(100vh - ", "px);\n  width: 100%;\n"])), HEADER_HEIGHT);
var HeaderWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  z-index: 10;\n  position: relative;\n  display: flex;\n  flex-wrap: nowrap;\n  height: ", "px;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  background: linear-gradient(194deg, #195CC7 0%, #25B4BA 24%, #185AC7 96%);\n  box-shadow: 0 2px 4px #777;\n  > div {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n  }\n  .title {\n    cursor: pointer;\n  }\n"], ["\n  z-index: 10;\n  position: relative;\n  display: flex;\n  flex-wrap: nowrap;\n  height: ", "px;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  background: linear-gradient(194deg, #195CC7 0%, #25B4BA 24%, #185AC7 96%);\n  box-shadow: 0 2px 4px #777;\n  > div {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n  }\n  .title {\n    cursor: pointer;\n  }\n"])), HEADER_HEIGHT);
var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  overflow: auto;\n  height: 100%;\n  background: #F0F0F0;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  overflow: auto;\n  height: 100%;\n  background: #F0F0F0;\n"])));
var InfoWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  float: right;\n  height: 100%;\n  width: 100px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  float: right;\n  height: 100%;\n  width: 100px;\n"])));
var InfoItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 40px;\n  height: 100%;\n  color: #333;\n  cursor: pointer;\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.2);\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-width: 40px;\n  height: 100%;\n  color: #333;\n  cursor: pointer;\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.2);\n  }\n"])));
var AccountNameIcon = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: inline-block;\n  width: ", ";\n  height: ", ";\n  border-radius: 100%;\n  background-color: #0D46A4;\n  color: #fff;\n  line-height: ", ";\n  text-align: center;\n"], ["\n  display: inline-block;\n  width: ", ";\n  height: ", ";\n  border-radius: 100%;\n  background-color: #0D46A4;\n  color: #fff;\n  line-height: ", ";\n  text-align: center;\n"])), function (props) { return props.small ? "20px" : "24px"; }, function (props) { return props.small ? "20px" : "24px"; }, function (props) { return props.small ? "20px" : "24px"; });
var MultiLanguageIcon = styled(IconFont)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #fff;\n"], ["\n  font-size: 14px;\n  color: #fff;\n"])));
var SideBarBlock = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  z-index: 100;\n  position: fixed;\n  top: ", "px;\n  left: 0;\n  bottom: 0;\n"], ["\n  z-index: 100;\n  position: fixed;\n  top: ", "px;\n  left: 0;\n  bottom: 0;\n"])), HEADER_HEIGHT);
var SideBarMask = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: ", ";\n  z-index: 6;\n  position: fixed;\n  top: ", "px;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  //background: rgba(0,0,0,0.3);\n"], ["\n  display: ", ";\n  z-index: 6;\n  position: fixed;\n  top: ", "px;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  //background: rgba(0,0,0,0.3);\n"])), function (props) { return props.active ? "block" : "none"; }, HEADER_HEIGHT);
var SideChildBar = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  float: left;\n  width: 400px;\n  display: flex;\n  flex-wrap: wrap;\n  .itemWrapper {\n    box-sizing: border-box;\n    padding: 4px 10px;\n    width: 100%;\n    .title {\n      margin-bottom: 6px;\n      font-size: 14px;\n      color: #777;\n    }\n    .itemBlock {\n      display: flex;\n      flex-wrap: wrap;\n    }\n    .item {\n      padding: 0;\n      width: 50%;\n    }\n  }\n  .item {\n    display: inline-block;\n    margin-top: 8px;\n    padding: 14px;\n    width: 50%;\n    cursor: pointer;\n    color: #555;\n    &.active {\n      color: #0D46A4;\n    }\n    &:hover {\n      color: #0D46A4;\n    }\n  }\n"], ["\n  float: left;\n  width: 400px;\n  display: flex;\n  flex-wrap: wrap;\n  .itemWrapper {\n    box-sizing: border-box;\n    padding: 4px 10px;\n    width: 100%;\n    .title {\n      margin-bottom: 6px;\n      font-size: 14px;\n      color: #777;\n    }\n    .itemBlock {\n      display: flex;\n      flex-wrap: wrap;\n    }\n    .item {\n      padding: 0;\n      width: 50%;\n    }\n  }\n  .item {\n    display: inline-block;\n    margin-top: 8px;\n    padding: 14px;\n    width: 50%;\n    cursor: pointer;\n    color: #555;\n    &.active {\n      color: #0D46A4;\n    }\n    &:hover {\n      color: #0D46A4;\n    }\n  }\n"])));
var LogoWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: ", "px;\n  height: ", "px;\n  &:after {\n    content: \"\";\n    position: absolute;\n    right: 0;\n    top: 50%;\n    transform: translateY(-50%);\n    display: block;\n    width: 1px;\n    height: 30px;\n    background: #C2BEBE;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: ", "px;\n  height: ", "px;\n  &:after {\n    content: \"\";\n    position: absolute;\n    right: 0;\n    top: 50%;\n    transform: translateY(-50%);\n    display: block;\n    width: 1px;\n    height: 30px;\n    background: #C2BEBE;\n  }\n"])), SIDEBAR_WIDTH, HEADER_HEIGHT);
var MainMenu = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 18px;\n  color: #fff;\n  background: ", ";\n  cursor: pointer;\n  font-size: 15px;\n  height: ", "px;\n  &:hover {\n    background: rgba(255, 255, 255, 0.3);\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  padding: 18px;\n  color: #fff;\n  background: ", ";\n  cursor: pointer;\n  font-size: 15px;\n  height: ", "px;\n  &:hover {\n    background: rgba(255, 255, 255, 0.3);\n  }\n"])), function (props) { return props.active ? "rgba(255,255,255,0.3)" : "none"; }, HEADER_HEIGHT);
var MaskWrapper = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 99;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.7);\n"], ["\n  position: absolute;\n  z-index: 99;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.7);\n"])));
var MaskHeader = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  position: absolute;\n  left: ", "px;\n  top: 0;\n  height: ", "px;\n  width: calc(100% - ", "px);\n  display: flex;\n  align-items: center;\n  .ant-menu-horizontal {\n    border: none;\n    background-color: transparent;\n  }\n"], ["\n  position: absolute;\n  left: ", "px;\n  top: 0;\n  height: ", "px;\n  width: calc(100% - ", "px);\n  display: flex;\n  align-items: center;\n  .ant-menu-horizontal {\n    border: none;\n    background-color: transparent;\n  }\n"])), SIDEBAR_WIDTH, HEADER_HEIGHT, SIDEBAR_WIDTH);
var MaskMenuItemBg = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  position: absolute;\n  z-index: -1;\n  left: -20px;\n  right: -20px;\n  top: 0;\n  bottom: 0;\n"], ["\n  position: absolute;\n  z-index: -1;\n  left: -20px;\n  right: -20px;\n  top: 0;\n  bottom: 0;\n"])));
var MaskMenuMask = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 1;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  background-color: transparent;\n"], ["\n  position: absolute;\n  z-index: 1;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  background-color: transparent;\n"])));
var MaskExit = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  z-index: 1;\n  position: absolute;\n  height: 46px;\n  display: flex;\n  align-items: center;\n  right: 10px;\n  top: 0;\n  color: #fff;\n  cursor: pointer;\n"], ["\n  z-index: 1;\n  position: absolute;\n  height: 46px;\n  display: flex;\n  align-items: center;\n  right: 10px;\n  top: 0;\n  color: #fff;\n  cursor: pointer;\n"])));
var MaskExitIcon = styled(VerticalLeftOutlined)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin-left: 10px;\n  font-size: 18px;\n"], ["\n  margin-left: 10px;\n  font-size: 18px;\n"])));
var MaskTipsWrapper = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  z-index: 9999;\n  position: absolute;\n  top: 126px;\n  left: -20px;\n  padding: 10px 10px 22px;\n  min-width: 220px;\n  max-width: 360px;\n  background-color: #fff;\n  line-height: 24px;\n"], ["\n  z-index: 9999;\n  position: absolute;\n  top: 126px;\n  left: -20px;\n  padding: 10px 10px 22px;\n  min-width: 220px;\n  max-width: 360px;\n  background-color: #fff;\n  line-height: 24px;\n"])));
var MaskImgWrapper = styled.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  position: absolute;\n  top: -70px;\n  left: 10px;\n  color: #fff;\n  font-size: 14px;\n"], ["\n  position: absolute;\n  top: -70px;\n  left: 10px;\n  color: #fff;\n  font-size: 14px;\n"])));
var MaskTipsNext = styled.a(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  z-index: 2;\n  position: absolute;\n  bottom: 4px;\n  right: 20px;\n  font-size: 12px;\n  cursor: pointer;\n  &:hover {\n    color: ", " !important;\n  }\n"], ["\n  z-index: 2;\n  position: absolute;\n  bottom: 4px;\n  right: 20px;\n  font-size: 12px;\n  cursor: pointer;\n  &:hover {\n    color: ", " !important;\n  }\n"])), MAIN_COLOR);
var MaskTipsContent = styled.div(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  overflow: auto;\n  padding: 10px;\n  max-height: 280px;\n  font-size: 12px;\n  color: #777;\n  > div {\n    height: 24px;\n    > span {\n      cursor: pointer;\n      &:hover {\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  overflow: auto;\n  padding: 10px;\n  max-height: 280px;\n  font-size: 12px;\n  color: #777;\n  > div {\n    height: 24px;\n    > span {\n      cursor: pointer;\n      &:hover {\n        color: ", ";\n      }\n    }\n  }\n"])), MAIN_COLOR);
var ErCodeWrapper = styled.div(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  margin: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  margin: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var WaterIco = styled.div(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  white-space: nowrap;\n  width: 100px;\n  font-size: 16px;\n  color: #fff;\n  font-family: American Typewriter !important;\n  text-align: center;\n"], ["\n  white-space: nowrap;\n  width: 100px;\n  font-size: 16px;\n  color: #fff;\n  font-family: American Typewriter !important;\n  text-align: center;\n"])));
var ChildMenuWrapper = styled.div(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  position: absolute;\n  margin-left: 32px;\n  padding: 10px;\n  overflow: auto;\n  max-height: calc(100vh - ", "px);\n  border-radius: 10px;\n  top: ", "px;\n  background: #fff;\n  display: ", ";\n  transform: translateX(-50%);\n  box-shadow: 0 0 6px #777;\n"], ["\n  position: absolute;\n  margin-left: 32px;\n  padding: 10px;\n  overflow: auto;\n  max-height: calc(100vh - ", "px);\n  border-radius: 10px;\n  top: ", "px;\n  background: #fff;\n  display: ", ";\n  transform: translateX(-50%);\n  box-shadow: 0 0 6px #777;\n"])), HEADER_HEIGHT + 20, HEADER_HEIGHT, function (props) { return props.active ? "block" : "none"; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27;

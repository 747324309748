var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Checkbox, Divider, Form, InputNumber } from "antd";
import { StoreContext } from "../../store/storeContext";
import { l } from "@helpers/multiLangaugeHelper";
import { getItemLayout } from "@helpers/const";
import { CONDITION_ENUM, getConditionTypeList } from "../../helpers/initData";
import { map, merge, set } from "lodash";
import { MySelect } from "@components/baseComponents/mySelect";
import { MyRangePicker } from "@components/baseComponents/myRangePicker";
import moment from "moment";
import { MyCheckBox } from "@components/baseComponents/myCheckBox";
import { getSiteCustomVariableValueOptions } from "../../helpers/async";
import { msgError } from "@helpers/axiosHelper";
import { MyModal } from "@components/baseComponents/myModal";
var FormItem = Form.Item;
var itemLayout = getItemLayout(8, 14);
export var SiteCondition = function (_a) {
    var readonly = _a.readonly;
    var _b = useContext(StoreContext), data = _b.data, modalOption = _b.modalOption, setModalOption = _b.setModalOption, modalData = _b.modalData, setModalData = _b.setModalData, saveModalData = _b.saveModalData;
    var _c = useState(false), indeterminate = _c[0], setIndeterminate = _c[1];
    var _d = useState([]), channelOptions = _d[0], setChannelOptions = _d[1];
    useEffect(function () {
        if (modalOption.type === CONDITION_ENUM.SITE) {
            getSiteCustomVariableValueOptions(__assign(__assign({}, modalData), { advertiserId: data.advertiserId }))
                .then(function (res) {
                setChannelOptions(res.data);
            })
                .catch(msgError);
        }
    }, [modalOption.type]);
    var onFormDataChange = function (value, pathKey) {
        var newData = merge({}, modalData);
        set(newData, pathKey, value);
        if (pathKey === "customVariableId") {
            set(newData, "customVariableValue", []);
            getSiteCustomVariableValueOptions({ customVariableId: newData.customVariableId, advertiserId: data.advertiserId })
                .then(function (res) {
                setChannelOptions(res.data);
            })
                .catch(msgError);
        }
        setModalData(newData);
    };
    var onCheck = function (list) {
        var newData = merge({}, modalData);
        set(newData, "customVariableValue", list);
        setModalData(newData);
        setIndeterminate(!!list.length && list.length < channelOptions.length);
    };
    var onCheckAllChange = function (e) {
        var newData = merge({}, modalData);
        set(newData, "customVariableValue", e.target.checked ? map(channelOptions, function (item) { return item.value; }) : []);
        setModalData(newData);
        setIndeterminate(false);
    };
    var save = function () {
        saveModalData(modalData, modalOption);
        setModalOption({});
    };
    var saveCanCheck = function () {
        if (!(modalData.customVariableId && modalData.customVariableValue && modalData.customVariableValue.length > 0))
            return false;
        if (!modalData.dateRange || !modalData.dateRange[0])
            return false;
        if (!modalData.minFrequency)
            return false;
        return true;
    };
    return (<MyModal width={840} title={getConditionTypeList()[CONDITION_ENUM.SITE].text} visible={modalOption.type === CONDITION_ENUM.SITE} onCancel={function () { return setModalOption({}); }} maskClosable={false} onOk={save} okButtonProps={{ disabled: !saveCanCheck() }}>
      <StoreContext.Consumer>
        {function (_a) {
        var modalOption = _a.modalOption;
        return modalOption.type === CONDITION_ENUM.SITE ? (<>
          <FormItem required {...itemLayout} label={l("ca_custom_variable", "Custom Variable")}>
            <MySelect allowClear={false} disabled={readonly} style={{ width: "260px" }} placeholder={l("ca_custom_variable_select_placeholder", "Select Custom Variable")} url={"/groupme/audex/audience/standardAudience_v2/site/getCustomVariableOptions"} params={{ advertiserId: data.advertiserId }} value={modalData.customVariableId} onChange={function (v) { return onFormDataChange(v, "customVariableId"); }}/>
          </FormItem>
          <FormItem required {...itemLayout} label={l("ca_custom_variable_value", "Custom Variable Value")}>
            <Checkbox style={{ marginTop: "6px" }} disabled={readonly} indeterminate={indeterminate} onChange={onCheckAllChange} checked={modalData.customVariableValue && modalData.customVariableValue.length === channelOptions.length}>
              Check all
            </Checkbox>
            <Divider style={{ margin: "8px 0" }}/>
            <MyCheckBox defaultOptions={channelOptions} disabled={readonly} value={modalData.customVariableValue} onChange={onCheck}/>
          </FormItem>
          <FormItem required {...itemLayout} label={l("common_date_range", "日期范围")}>
            <MyRangePicker disabled={[readonly, readonly]} disabledDate={function (current) { return current && current > moment().endOf("day"); }} value={modalData.dateRange} onChange={function (v) { return onFormDataChange(v, "dateRange"); }}/>
          </FormItem>
          <FormItem required {...itemLayout} label={l("common_frequency", "频次")}>
            <InputNumber disabled={readonly} min={1} style={{ width: "60px" }} value={modalData.minFrequency} onChange={function (v) { return onFormDataChange(v, "minFrequency"); }}/>
            <span style={{ margin: "0 4px" }}>
              ～
              
            </span>
            <InputNumber disabled={readonly} min={modalData.minFrequency || 1} style={{ width: "60px" }} value={modalData.maxFrequency} onChange={function (v) { return onFormDataChange(v, "maxFrequency"); }}/>
            <span style={{ margin: "0 8px" }}>{l("common_time_s", "time(s)")}</span>
          </FormItem>
        </>) : null;
    }}
      </StoreContext.Consumer>
    </MyModal>);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { useState } from "react";
import { Button } from "antd";
import { l } from "@helpers/multiLangaugeHelper";
import styled from "styled-components";
import { MAIN_COLOR } from "@/common/styled";
import { getBatchExportUrl } from "@components/batchExportByUrl/async";
export var EXPORT_URL_TYPE_LIST = {
    MODELING_CUSTOM_MODELING: "/groupme/modAll/modeling/customModeling/download",
    MODELING_LOOKALIKE: "/groupme/audex/modeling/lookalike_v2/download",
    ACTIVATION_MANAGE_EXPORT: "/groupme/audex/activation/activateManage/download",
    OVERVIEW_MTAGS: "overview.mtags_manage",
    OVERVIEW_BROWSER_BEHAVIOR: "overview.browserBehavior_manage",
    AUDIENCE_SHANHAIJIN_EXPORT: "/groupme/audex/audience/standardAudience/campaign/audienceDownload",
};
export var BatchSyncExportByUrl = function (_a) {
    var btnText = _a.btnText, type = _a.type, disabled = _a.disabled, style = _a.style, params = _a.params, _b = _a.btnType, btnType = _b === void 0 ? "button" : _b;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var onExport = function () {
        var data = {
            type: type,
            params: params
        };
        setLoading(true);
        getBatchExportUrl(data)
            .then(function (res) {
            setLoading(false);
            window.open(res.data.url);
        })
            .catch(function (err) {
            setLoading(false);
        });
    };
    if (btnType !== "button") {
        return (<HrefA style={style} disabled={loading || disabled} onClick={loading || disabled ? null : onExport}>
        {btnText ? btnText : l("common_export", "导出")}
      </HrefA>);
    }
    return (<Button type="primary" style={style} loading={loading} disabled={disabled} onClick={onExport}>
      {btnText ? btnText : l("common_export", "导出")}
    </Button>);
};
var HrefA = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  color: ", ";\n"], ["\n  display: inline-block;\n  color: ", ";\n"])), function (props) { return props.disabled ? "#ddd" : MAIN_COLOR; });
var templateObject_1;

import { post } from "@helpers/axiosHelper";
export var creatAsyncExport = function (data) {
    return post("/groupme/common/createAsyncDownload", data);
};
export var asyncExport = function (data) {
    return post("/groupme/common/asyncDownload", data);
};
export var exportToEmail = function (data) {
    return post("/groupme/common/asyncDownloadToEmail", data);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { PieChart } from "@components/charts/pieChart/pieChart";
import { map } from "lodash";
import numeral from "numeral";
import { l } from "@helpers/multiLangaugeHelper";
export var TgiPieChart = function (_a) {
    var data = _a.data, height = _a.height;
    var pieTooltip = function (items, title) {
        var itemData = items[0].data;
        var percent = itemData.yAxis;
        var tgi = itemData.yAxis2;
        return <TooltipWrapper>
      <Title>{title}</Title>
      <TooltipContent>
        <div>
          <ItemTooltipsCircle color={items[0].color}/>{l("dashboard_percent", "Percentage")}: {numeral(percent).format("0.0%")}
        </div>
        {!tgi && tgi !== 0 ? null : <div>TGI: {numeral(tgi).format("0,0")}</div>}
      </TooltipContent>
    </TooltipWrapper>;
    };
    var renderPie = function (data) {
        if (!(data && data.length > 0)) {
            return l("common_no_data", "No Data");
        }
        var typeListMap = {};
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var item = data_1[_i];
            if (item.type && item.type !== "") {
                if (typeListMap[item.type]) {
                    typeListMap[item.type].push(item);
                }
                else {
                    typeListMap[item.type] = [item];
                }
            }
        }
        var typeList = map(typeListMap, function (value) {
            return value;
        });
        if (typeList.length !== 0) {
            return (<>
        <HalfWrapper>
          <PieChart padding={"auto"} appendPadding={[10, 0, 0, 0]} height={height} data={typeList && typeList.length > 0 ? typeList[0] : []} percent={"yAxis"} countTitle={"TGI"} count={"yAxis2"} color={"xAxis"} tooltip={pieTooltip}/>
        </HalfWrapper>
        <HalfWrapper>
          <PieChart padding={"auto"} appendPadding={[10, 0, 0, 0]} height={height} data={typeList && typeList.length > 1 ? typeList[1] : []} percent={"yAxis"} countTitle={"TGI"} count={"yAxis2"} color={"xAxis"} tooltip={pieTooltip}/>
        </HalfWrapper>
      </>);
        }
        return (<PieChart height={height} padding={"auto"} appendPadding={[10, 0, 0, 0]} data={data} percent={"yAxis"} countTitle={"TGI"} count={"yAxis2"} color={"xAxis"} tooltip={pieTooltip} legend={{ position: "top" }}/>);
    };
    return (<Wrapper>
    {renderPie(data)}
  </Wrapper>);
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n"], ["\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n"])));
var HalfWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  float: left;\n  width: 50%;\n"], ["\n  float: left;\n  width: 50%;\n"])));
var TooltipWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 10px 0;\n"], ["\n  padding: 10px 0;\n"])));
var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var ItemTooltipsCircle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"], ["\n  margin-right: 10px;\n  display: inline-block;\n  height: 8px;\n  width: 8px;\n  background-color: ", ";\n  border-radius: 50%;\n"])), function (props) { return props.color; });
var TooltipContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n  > div {\n    margin: 0 10px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
